import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { alpha, Box, CardMedia, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import garden from '../../Static/garden.jpg';
import dumbell from '../../Static/dumbell.jpg';
import axios from '../../axios-modbee';
import { useTranslation } from 'react-i18next';
import Schritt1 from './Schritte/Schritt1';
import Schritt2 from './Schritte/Schritt2';
import Schritt3 from './Schritte/Schritt3';
import Countdown from './Schritte/Countdown';
import Schritt5 from './Schritte/Schritt5';
import Schritt7 from './Schritte/Schritt7';
import { useSelector, useDispatch } from 'react-redux';
import * as actions_scales from "../../store/actions/scales";
import const_vars from "../../Static/const_vars";
import moment from 'moment'


const KVorgang = (props) => {
    const classes = useStyles()
    const { t } = useTranslation();
    const [schritt, setSchritt] = React.useState(1)
    const [id_calibration, setid_calibration] = React.useState(0)
    const [stepid, setstepid] = React.useState(0)
    const [zeit, setZeit] = React.useState(0)
    const [caliData, setCaliData] = React.useState({
        duration: props.typ === 2 ? 120 : 86400,
        count: 5,
        interval: 5
    })
    const scales = useSelector(state => state.scale.scales);
    const calibrations = useSelector(state => state.scale.calibrations); 
    const dispatch = useDispatch()


    useEffect(() => {
        if (props.kalibrierung) {
            setid_calibration(props.kalibrierung.id_kalibrierung)
            if (props.kalibrierung.steps.length === 0) {
              console.log('props.kalibrierung.steps.length === 0: waage = ', props.waage);
              if (props.waage.status === const_vars.GW_STATUS_WAITING_IN_CALIBRATION_MODE) {
                // setSchritt(3);          
                setSchritt(2);      
              } else if (props.waage.status === const_vars.GW_STATUS_SHOULD_START_CALIBRATION) {
                setSchritt(2);
              } else {
                setSchritt(1);
              }
            } else {
                let gefunden = false;
                props.setSperre(true);

                console.log('props.kalibrierung.steps.length: '+props.kalibrierung.steps.length);


                for (let i = 0; i < props.kalibrierung.steps.length; i++) {
                    if (props.kalibrierung.steps[i].status === 0) {
                        gefunden = true;
                        const sekundenSeitStart = Math.abs((new Date().getTime() - (new Date(props.kalibrierung.steps[i].start_time).getTime()- (moment().utcOffset()*60*1000))) / 1000)
                        const sekundenSeitStart2 = Math.abs((moment().valueOf() - moment(props.kalibrierung.steps[i].start_time).valueOf() - (moment().utcOffset()*60*1000)) / 1000)
                        console.log(sekundenSeitStart, sekundenSeitStart2, new Date().getTime() ,moment().valueOf(),moment(props.kalibrierung.steps[i].start_time).valueOf()/1000,new Date(props.kalibrierung.steps[i].start_time).getTime(), moment().utcOffset()*60)
                        
                        setZeit(sekundenSeitStart)
                        setCaliData({
                            duration: props.kalibrierung.steps[i].duration,
                            count: props.kalibrierung.steps[i].count,
                            interval: props.kalibrierung.steps[i].interval
                        })

                        setstepid(props.kalibrierung.steps[i].id_cal_steps)
                        if (i === 0) {
                          if (props.waage.status === const_vars.GW_STATUS_SHOULD_SEND_CALIBRATION_VALUES) {
                            console.log('i = 0 -> set Schritt 3');
                            setSchritt(3);                
                          } else {
                            console.log('i = 0 -> set Schritt 4');
                            setSchritt(4);
                          }
                          
                        } else if (i === 1) {
                          console.log('i = 1 -> set Schritt 6');
                          setSchritt(6);
                        } else {
                          console.log('i > 1 -> set Schritt 8');
                          setSchritt(8);
                        }

                    }
                }
                if (!gefunden) {
                    if (props.kalibrierung.steps.length === 1) setSchritt(5)
                    else if (props.kalibrierung.steps.length === 2) setSchritt(7)
                    else next(true)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.kalibrierung]);






    const abschluss = () => {

        const config = {
            daten: {
                "status": 1,
            }, crossdomain: true
        };

        axios.put('/calibration/' + id_calibration, config)
            .then(res => {
                const tempA = [...calibrations]
                const index = tempA.findIndex(fff => fff.id_kalibrierung === id_calibration)
            
                if (tempA[index] !== undefined) {
                  tempA[index].status = 1;
                  dispatch(actions_scales.fetchCalibrationsSuccess(tempA));
                } else {
                  console.log("tempA[index] is undefined for index = ", index, " tempA = ", tempA, " id_calibration = ", id_calibration);
                }

                const config = {
                    data: {
                        "befehl": const_vars.KALIBRIERUNGS_BEFEHL,
                        "cmd": const_vars.GW_STATUS_SHOULD_STOP_CALIBRATION,
                        "id_scales": props.waage.id_scales,
                    },
                    crossdomain: true
                };
                axios.put('/scale/custom/' + props.waage.id_scales, config)
                    .then(res => {
                        const config = {
                            daten: {
                                "kalibriert": 1,
                            },
                            crossdomain: true
                        };
                        axios.post('/scale/edit/' + props.waage.id_scales, config)
                            .then(res => {
                                const tempA = [...scales]
                                const index = tempA.findIndex(fff => fff.id_scales === props.waage.id_scales)
                                const newScale = { ...tempA[index], ...config.daten }
                                tempA[index] = newScale;
                                dispatch(actions_scales.fetchScalesSuccess(tempA));
                                props.setSperre(false)
                                props.handleNext()
                                console.log("got edit scale answer: ", res);
                            })
                            .catch(err => {
                                console.log("got edit scale error: ", err);
                            });
                        console.log("got sendCustomWriteToGateway answer: ", res);
                    })
                    .catch(err => {
                        console.log("got sendChargerFw error: ", err);
                    });
            })
            .catch(err => {
                console.log("got sendChargerFw error: ", err);
            });
    }


    const next = (closer) => {
        if (closer) abschluss()
        else setSchritt(schritt + 1)
    }


    return (
        <Grid container>
            <Grid item xs={12}>
                <Card sx={{ display: 'flex' }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 151 }}
                        image={garden}
                        alt="Live from space album cover"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h5">
                                {props.typ === 1 ? t('main.Genaue Kalibrierung') : t('main.Schnelle Kalibrierung')}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                Bitte positionieren Sie die Waage an einem schattigen und trockenen Ort, damit Sonneneinstrahlung und Regen die Kalibrierung nicht beeinflussen.
                            </Typography>
                        </CardContent>
                    </Box>

                </Card>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 8 }}>
                <Card sx={{ display: 'flex' }}>

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            {schritt === 1 ? <Schritt1 waage={props.waage} next={next} typ={props.typ} setid_calibration={setid_calibration} setSperre={props.setSperre} /> :
                                schritt === 2 ? <Schritt2 waage={props.waage} next={next} /> :
                                    schritt === 3 ? <Schritt3 waage={props.waage} next={next} caliData={caliData} id_calibration={id_calibration} setstepid={setstepid} /> :
                                        schritt === 4 ? <Countdown waage={props.waage} next={next} caliData={caliData} id_calibration={id_calibration} stepid={stepid} setstepid={setstepid} sekundenSeitStart={zeit}/> :
                                            schritt === 5 ? <Schritt5 waage={props.waage} next={next} caliData={caliData} id_calibration={id_calibration} stepid={stepid} setstepid={setstepid} /> :
                                                schritt === 6 ? <Countdown waage={props.waage} next={next} caliData={caliData} id_calibration={id_calibration} stepid={stepid} setstepid={setstepid} sekundenSeitStart={zeit}/> :
                                                    schritt === 7 ? <Schritt7 waage={props.waage} next={next} caliData={caliData} id_calibration={id_calibration} stepid={stepid} setstepid={setstepid} /> :
                                                        schritt === 8 ? <Countdown waage={props.waage} next={() => next(true)} caliData={caliData} id_calibration={id_calibration} stepid={stepid} setstepid={setstepid} sekundenSeitStart={zeit}/> :
                                                            null}

                        </CardContent>
                    </Box>
                    <CardMedia
                        component="img"
                        sx={{ width: 151 }}
                        image={dumbell}
                        alt="Live from space album cover"
                    />
                </Card>
            </Grid>
        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        '&:hover': {
            backgroundColor: alpha(grey[50], 0.3),
            cursor: "pointer"
        },
    },
}));
export default KVorgang