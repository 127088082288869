import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Grid, Typography } from '@mui/material'
import CardMedia from '@mui/material/CardMedia';
import scaleKit from '../../Static/scale-kit.jpg';
import appImg from '../../Static/do-it-yourself.jpg';
import scaleAssistent from '../../Static/scaleAssistent.png';
import appScreenshot from '../../Static/screenshot1.png';
import appScreenshot2 from '../../Static/screenshot2.png';


const FrontPage = (props) => {
  const classes = useStyles();

    var items = [
    {
        name: "modbee.io scale-kit",
        description: "Waagenelektronik für die Reparatur von defekten Waagen, zum Upgrade von einer alten digitalen Waage zu einer High-End "+
                      "Waage mit moderner App oder zum Bau von eigenen Waagen",
        img: scaleKit
    }
    ,
    {
        name: "Reparatur von defekten Waagen",
        description: "Nicht wegwerfen sondern reparieren!"
    }
    ,
    {
        name: "Upgrade von veralteten Waagen",
        description: "Rüsten sie veraltete Waagen auf anstatt sie auszumustern!"
    }
    ,
    {
        name: "Moderne App",
        description: "Honigeintrag immer im Blick!",
        img: appImg
    }
    ,
    {
        name: "Langlebigkeit im Fokus",
        description: "Modularer Aufbau für einfachen Austausch einzelner Komponenten bei Defekt oder für neue Funktionen in der Zukunft. Robuste Module, integrierte Energiegewinnung, automatische Sicherheits- und Featureupdates für einen mgölichst wartungsfreien Dauerbetrieb.",
        img: appImg
    }
    // ,
    // {
    //     name: "Do-it-yourself Waage",
    //     description: "Bauen Sie selbst eine Waage!",
    //     img: doItYourself
    // }
]
    return (
        <React.Fragment>
            <CssBaseline />
            <Container  className={classes.main_div}>
              <br />
              <Typography variant='h5'>
                Willkomen bei modbee.io
              </Typography>
              <Typography variant='body'>
                Sie haben eine defekte Bienenstockwaage oder veraltete Bienenstockwaage, die Sie gerne wieder verwenden würden? Dann sind Sie hier genau richtig. Mit dem modbee.io scale-kit können Sie ihre alte Waage in wenigen einfachen Schritten wieder reparieren oder aufrüsten und erhalten eine hochmoderne Bienenstockwaage mit neuester Funktechnologie und state-of-the-art Webanwendung.
              </Typography>

              <br />
              <br />
              <Grid 
                spacing={4} 
                container 
                direction="row">

                <Grid item xs={3}>
                  <CardMedia
                      component="img"
                      height="200"
                      image={scaleKit}
                  />
                </Grid>

                <Grid item xs={9}>
                  <Typography variant="h6">
                    modbee.io scale-kit
                  </Typography>
                  <Typography variant="body">
                    Mit diesem Nachrüstset für Bienenstockwaagen verwandeln Sie ungenützte alte oder defekte Waagen im Handumdrehen in eine 
                    funktionstüchtige neue Waage.
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body">
                    Ein Schritt-für-Schritt Assistent hilft Ihnen dabei, die Elektronik ihrer defekten Waage zu entfernen und das modbee.io 
                    scale-kit anzuschließen. In 99% der Fälle müssen hierfür nur 5 Kabel abgeschlossen und in der richtigen Reihenfolge an einer 
                    kleinen Schraubverbindung angeschlossen werden. 
                  </Typography>
                </Grid>

                <Grid item xs={9}>
                  <Typography variant="h6">
                    Assistent
                  </Typography>
                  <Typography variant="body">
                    In der Web-Anwendung zum modbee.io scale-kit gibt es einen Schritt-für-Schritt Assistenten, der Ihnen sowohl beim Umbau, als auch bei der Kalibrierung ihrer neuen Waage hilft. So ist es auch für Laien möglich, die eigene Waage komplett eigenständig und extrem genau zu kalibrieren und damit bares Geld zu sparen.
                  </Typography>
                  <br />
                  <Typography variant="body">
                    Der Assistent bietet die Möglichkeit einer schnellen Kalibrierung, die in 10 Minuten komplett abgeschlossen ist und einer sehr genauen Kalibrierung, die im Idealfall mehrere Tage dauert.
                    Die schnelle Kalibrierung eignet sich vor allem dazu, um nach dem Zusammenbau zu überprüfen, ob die Waage richtig funktioniert.
                    Um die langfristige Genauigkeit und Temperaturunabhängikeit zu optimieren, wird jedoch empfohlen eine längere Kalibrierung der Waage durchzuführen. Im Grunde sind dazu auch nur wenige Handgriffe notwendig, aber die Waage hat so Zeit über einen längeren Zeitraum und bei unterschiedlichen Temperaturen Werte zu sammeln. Das hilft dabei, natürliche Schwankungen durch Temperaturunterschiede intelligent zu erkennen und auszubessern - dadurch werden viel genauere Messergebnisse erzielt.
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <CardMedia
                      component="img"
                      height="200"
                      image={scaleAssistent}
                  />
                </Grid>

                <Grid item xs={2}>
                  <CardMedia
                      component="img"
                      height="300"
                      image={appScreenshot2}
                  />
                </Grid>
                <Grid item xs={2}>
                  <CardMedia
                      component="img"
                      height="300"
                      image={appScreenshot}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Typography variant="h6">
                    Web-Anwendung
                  </Typography>
                  <Typography variant="body">
                    Eine moderne Webanwendung hilft dabei, stets den Überblick zu behalten.
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body">
                    Neben den genauen Gewichtsdaten werden zusätzlich noch Temperatur, Luftfeuchtigkeit und Luftdruck aufgezeichnet und intelligent ausgewertet. Auf Wunsch können Sie Push-Nachrichten direkt auf das Smartphone aktivieren und über verschiedenste Ereignisse sofort informiert werden. Umfangreiche Auswertungsmöglichkeiten, einstellbare Berichte oder der Vergleich mit anderen Imkern lassen keine Imkerwünsche offen.
                  </Typography>
                  <br />
                  <br />
                  <br />
                  <br />
                </Grid>

                {/* <Carousel 
                  duration={500} 
                  interval={6000} 
                  navButtonsAlwaysVisible={false} 
                  indicatorContainerProps={{
                      style: {
                          marginTop: '100px'
                      }
                  }}>
                  { items.map( (item, i) => <Item key={i} item={item} /> ) }
                </Carousel> */}




                  {/* <Box sx={{ my: 2 }}>
                      {[...new Array(20)]
                          .map(
                              () => `Cras mattis consectetur purus sit amet fermentum.
  Cras justo odio, dapibus ac facilisis in, egestas eget quam.
  Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
  Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                          )
                          .join('\n')}
                  </Box> */}
              </Grid>
            </Container>
        </React.Fragment>
    );
}

function Item(props)
{
  const classes = useStyles();

  return (
      <Grid 
        spacing={4} 
        container 
        direction="row"
        className={classes.carousel_item} >

        { props.item.img && 
          <Grid item>
            <CardMedia
                component="img"
                height="300"
                image={props.item.img}
            />
          </Grid>
        }

          <Grid item>
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>

          </Grid>


          {/* <Button className="CheckButton">
              Check it out!
          </Button> */}
      </Grid>
  )
}
const useStyles = makeStyles(theme => ({
    scrolled: {
        backgroundColor: theme.palette.primary.main,
        transition: 'background-color 200ms linear',
    },
    carousel_item: {
      height: "300px",
      minHeight: "250px"
    },
    main_div: {
      marginBottom: "60px"
    },

}));

export default FrontPage;