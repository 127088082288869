import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { alpha, Box, Button, CardMedia, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import beute from '../../Static/beute.jpg';

const ZBDownload = (props) => {
    const classes = useStyles()
    const { t } = useTranslation();
    return (
        <Grid container spacing={2} >
            <Grid item xs={12}>
                <Card sx={{ display: 'flex' }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 151 }}
                        image={beute}
                        alt="Live from space album cover"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }} style={{ padding: 16 }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography component="div" variant="h5">
                                        {t('main.Aufbau der Waage')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Sie können hier die Bauanleitung als PDF downloaden oder den AnleitungsaAssistenten dazu nutzen.
                                        Haben Sie die Waage bereits aufgebaut können Sie diesen Punkt überspringen.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs />
                                        <Grid item><Button>Download</Button></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12} style={{paddingTop: 16}}>
                <Grid container>
                    <Grid item>
                        <Button variant="outlined" onClick={props.skipZusammenbau}>Überspringen</Button>
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                        <Button variant="outlined" onClick={props.handleNext}>Assistent starten</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        '&:hover': {
            backgroundColor: alpha(grey[50], 0.3),
            cursor: "pointer"
        },
    },
}));
export default ZBDownload