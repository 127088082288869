import React from 'react';
import { Backdrop, Badge, Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ProductSmall from '../../../Shop/Product/ProductSmall';

const Cart = (props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const cartItems = useSelector(state => state.shop.cartItems);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Backdrop open={open} />
            <IconButton aria-label="cart" pos={"bottom-start"} onClick={handleClick}>
                <Badge badgeContent={cartItems.length} color="secondary" anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}>
                    <ShoppingCartIcon />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                MenuListProps={{padding:0 }}
            >
                <Grid container style={{maxWidth:500, backgroundColor: '#d9e2ee'}}>
                    {cartItems.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={12} style={{ padding: 4 }}>
                                    <ProductSmall />
                                </Grid>
                            </React.Fragment>
                        )
                    })}
                </Grid>
            </Menu>
        </React.Fragment >

    );
}

export default Cart;