
import React from 'react';
import { useDispatch } from "react-redux";
import styles from './Scale.module.css';
import * as actions from "../../store/actions/main";
import Moment from "react-moment"; // Import css modules stylesheet as styles
import 'moment-timezone';
import 'moment/locale/de';

import ValueContainer from "../../components/valueContainer/ValueContainer";
import * as const_vars from "../../Static/const_vars";
import Bat10Icon from '@mui/icons-material/Battery20';
import Bat20Icon from '@mui/icons-material/Battery20';
import Bat50Icon from '@mui/icons-material/Battery50';
import Bat90Icon from '@mui/icons-material/Battery90';
import createClass from 'create-react-class';
import {isMobile} from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import {
  Area,
  AreaChart,
  CartesianGrid, ReferenceLine,  XAxis,
  YAxis
} from "recharts";
import moment from "moment/moment";
import DayComp from './DayComp';
import { Grid, Typography } from '@mui/material';

const CustomizedXAxisTick = createClass({
  render () {
      const {x, y, payload} = this.props;
      return (
          <g transform={`translate(${x},${y})`}>
              <text x={0} y={6}
              fontSize="9"
                    textAnchor="start"
                    fill="#666">{ moment(payload.value).format('HH:mm')}</text>
          </g>
      );
  }
 });

 const CustomizedYAxisTick = createClass({
  render () {
      const {x, y, payload} = this.props;
      return (
          <g transform={`translate(${x},${y})`}>
              <text x={0} y={6}
              fontSize="9"
              textAnchor="start"
              fill="#666">{Number.parseFloat(payload.value).toFixed(1)+"kg"}</text>
          </g>
      );
  }
 });
// class Scale extends Component {
const Scale = (props) => {

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const gradientOffset = () => {
    if (props.scale.weights != null) {
      const dataMax = Math.max(...props.scale.weights.map((i) => i.weight));
      const dataMin = Math.min(...props.scale.weights.map((i) => i.weight));
      if (dataMax <= 37){
        return 0
      }
      else if (dataMin >= 37){
        return 1
      }
      else{
        return dataMax / (dataMax - dataMin);
      }
    } else {

      return 0
    }
  };

  const off = gradientOffset();

  const handleScaleClick = () => {   
    console.log('bla') 
    //props.selectScale(props.scale);
    // navigate('/scale');
    dispatch(actions.setTitleAndMenu("Statistik", const_vars.MENU_STATISTIC));
    navigate('/statistic/'+props.scale.id_scales);
  };
  
  const handleKalibrierungClick = (e) => {   
    e.stopPropagation();
    // props.selectScale(props.scale);
    // navigate('/scale');
    dispatch(actions.setTitleAndMenu("Waagen", const_vars.MENU_SCALES));
    navigate('/calibration/'+props.scale.id_scales);
  };



  const tickFormatFunction = (msTime) => {
    // return moment(msTime,'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY');
    // moment('09:00','h:mm a')
      return moment.utc(msTime).format('HH:mm');
  };


  // render() {
  const {typ} = props;
  // console.log("typ: ", typ);
    // reference as a js object
  // day1 -0.03  day2 -0.01  day3 -0.01  day4  0.612  day5  0.016  day6 0.003999999999999998 day7 0.61
  // id "3"  id_gw  1015 id_scale 1015 last3 0.01 last6 0.01 last24 -0.01

  const date = new Date();
  let start = 0;

  if (props.scale != undefined) {
    start = props.scale.day_start;

    if (props.scale.weights) {
      if (props.scale.weights.length > 0) {
        // console.log("weights: ", props.scale.weights);
        // start = props.scale.weights[0].weight;
        start = props.scale.weights[0].ertrag;
      }
    }
  }

  let offset = 0.8;
  // return dataMax / (dataMax - dataMin);
  let dataMax = 0;
  let dataMin = 0;

  if (props.scale.weights != null) {
    dataMax = Math.max(...props.scale.weights.map((i) => i.ertrag));
    dataMin = Math.min(...props.scale.weights.map((i) => i.ertrag));
  }

  console.log();

  // let dataMax = Math.max(...props.scale.weights.map((i) => i.weight));
  // let dataMin = Math.min(...props.scale.weights.map((i) => i.weight));
  // const now = props.scale.weight;

  // const chartMin = precise_round(dataMin, 2); // -0.05;
  // const chartMax = precise_round(dataMax, 2); // +0.05;

  let chartMin = dataMin;// -0.05;
  let chartMax = dataMax;// +0.05;

  let hun = chartMax - chartMin;
  let lineCol = props.colorVoltage;
  // let hun = dataMax - dataMin;

  if (chartMax > (start + 0.05)) {
    offset = 1.0 * (chartMax - start) / hun;
    // console.log("dataMax > start: "+offset+" id: "+props.scale.id_scale);
    // console.log("dataMax: "+chartMax+" start: "+start);
  } else {
    offset = 0;
    lineCol = props.colorWeight;
    // console.log("ELSE: "+offset+" id: "+props.scale.id_scale);;
  }
  if ((chartMax - chartMin) < 0.3) {
    chartMax += 0.15;
    chartMin -= 0.15;
  } else {
    chartMin -= 0.15;
    chartMax += 0.15;
  }
  chartMax = Math.round(chartMax * 100) / 100;
  chartMin = Math.round(chartMin * 100) / 100;


  let chargeClass = "";
  let icon = <Bat90Icon className={styles.charge_icon}/>;
  if (Number.parseFloat(props.scale.voltage) < 3.85) {
    chargeClass = styles.critical_charge;
    icon = <Bat10Icon className={styles.charge_icon_critical}/>;
  } else if (Number.parseFloat(props.scale.voltage) < 3.9) {
    chargeClass = styles.warning_charge;
    icon = <Bat20Icon className={styles.charge_icon_warn}/>;
  } else if (Number.parseFloat(props.scale.voltage) < 4.02) {
    chargeClass = styles.info_charge;
    icon = <Bat50Icon className={styles.charge_icon_info}/>;
  }

  // console.log("props.scale: ", props.scale);
  // console.log("props.scale: ", props.scale);

  return (
    <div className={styles.scale} onClick={handleScaleClick}>
      {props.scale !== undefined &&
      <Grid container direction="column">
        <Grid item xs container direction="row" >
          <Grid item xs>
            <Typography  variant="h6" >
              { props.scale.name === "" ?
                "Waage "+props.scale.id_scales : props.scale.name
              }                
            
            </Typography>

            { isMobile && 
              <Typography gutterBottom variant="caption" className={styles.remove_bottom_margin}>                
              { Number.parseFloat(props.scale.last_weight).toFixed(2)}&nbsp;kg
                &nbsp;|&nbsp;
              { Number.parseFloat(props.scale.temp1).toFixed(2)}&nbsp;°C
                &nbsp;|&nbsp;
                ID {props.scale.id_scales}
              </Typography>
            }
          </Grid>

          <Grid item>
            <Grid container direction="row" 
                  alignItems="center"
                  justify="center" >

            { isMobile && 
                <Typography color="textSecondary" 
                  gutterBottom variant="caption" 
                  className={styles.remove_bottom_margin}>                  
                  <Moment locale="de" utc fromNow>{props.scale.activate_time}</Moment>

                  &nbsp;|&nbsp;
                  <span className={chargeClass}>
                  {
                    Number.parseFloat(props.scale.voltage).toFixed(2)
                  }
                  V</span>
                </Typography>
              }
              { !isMobile && 
                <Typography gutterBottom variant="caption" className={styles.remove_bottom_margin}>
                  
                { Number.parseFloat(props.scale.last_weight).toFixed(2)}&nbsp;kg
                  &nbsp;|&nbsp;
                { Number.parseFloat(props.scale.temp1).toFixed(2)}&nbsp;°C
                  &nbsp;|&nbsp;
                  <Moment locale="de" utc fromNow>{props.scale.activate_time}</Moment>
                  &nbsp;|&nbsp;
                  ID {props.scale.id_scales}
                  &nbsp;|&nbsp;
                  <span className={chargeClass}>
                  {
                    Number.parseFloat(props.scale.voltage).toFixed(2)
                  }
                  V</span>
                </Typography>
              }
            {/* </Typography> */}
            <Grid item>
                { icon }
            </Grid>
            </Grid>
          </Grid>
          {/* <Grid item>
          </Grid> */}
        </Grid>

        <Grid item xs container spacing={1}>
          {/*<ResponsiveContainer*/}
            {/*width={160} height={120}>*/}

            <AreaChart
              width={160} height={100}
              margin={{top: 16, right: 0, left: 16, bottom: 0}}
              data={props.scale.weights} >

              <CartesianGrid
                strokeWidth="0.5"
                strokeDasharray="3 3" />
              {/*<XAxis dataKey="signal_time"/>*/}
              <XAxis
                padding={{bottom: 5}}
                hide={false}
                dataKey="signal_time"
                type="number"
                scale="time"
                allowDecimals={false}
                allowDataOverflow={false}
                interval="preserveStartEnd"
                tick={<CustomizedXAxisTick/>}
                // tick={{
                // fontSize: 9,
                // position: 'insideLeft',
                // fill: props.colorXAxis
                // }}
                tickFormatter={tickFormatFunction}
                domain={['dataMin', 'dataMax']}/>

              {/*<YAxis/>*/}

              <YAxis
                padding={{ top: 4, bottom: 4 }}
                yAxisId="right"
                unit="kg"
                type="number"
                allowDataOverflow={true}
                // tick={{
                // fontSize: 12,
                // fill: props.colorVoltage
                // }}
                tick={<CustomizedYAxisTick/>}
                // domain={['auto', 'auto']}
                domain={[chartMin, chartMax]}
                orientation="right"/>


              { dataMax > start &&
              <defs>
                <linearGradient id={"splitColor"+props.scale.id_scale} x1="0" y1="0" x2="0" y2="1">
                  <stop offset={offset} stopColor={props.colorVoltage}  stopOpacity={0.5}/>
                  <stop offset={offset} stopColor={props.colorVoltage} stopOpacity={0.5}/>
                  <stop offset={offset} stopColor="white" stopOpacity={0.5}/>
                  <stop offset={1} stopColor="white" stopOpacity={0.5}/>
                </linearGradient>
              </defs>
              }

              { dataMax <= start &&
              <defs>
                <linearGradient id={"splitColor"+props.scale.id_scale} x1="0" y1="0" x2="0" y2="1">
                  <stop offset={offset} stopColor="red"  stopOpacity={0.5}/>
                  <stop offset={offset} stopColor="red" stopOpacity={0.5}/>
                  <stop offset={offset} stopColor="white" stopOpacity={0.5}/>
                  <stop offset={1} stopColor="white" stopOpacity={0.5}/>
                </linearGradient>
              </defs>
              }



              <ReferenceLine
                y={start}
                yAxisId="right"
                strokeWidth={props.strokeWidthRevLines}
                stroke={lineCol} />

              <Area
                type="monotone"
                allowDataOverflow={true}
                isAnimationActive={false}
                // stackId="1"
                yAxisId="right"
                dataKey="ertrag"
                // stroke="#000"
                strokeWidth={props.strokeWidthLines}
                stroke={props.colorVoltage}
                // fill='#8884d8'
                fill={"url(#splitColor"+props.scale.id_scale}
              />

              {/* //TODO aktuellen Wert schreiben oder besser markieren! */}

              {/*<Area*/}
                {/*type="monotone"*/}
                {/*allowDataOverflow={true}*/}
                {/*isAnimationActive={false}*/}
                {/*stackId="1"*/}
                {/*yAxisId="right"*/}
                {/*dataKey="weight2"*/}
                {/*stroke='#ffc658'*/}
                {/*fill='red'*/}
              {/*/>*/}

            </AreaChart>

            {/*<LineChart*/}
              {/*margin={{top: 16, right: 8, left: 16, bottom: 8}}*/}
              {/*data={props.scale.weights}>*/}

              {/*<XAxis*/}
                {/*padding={{bottom: 23}}*/}
                {/*hide={false}*/}
                {/*dataKey="signal_time"*/}
                {/*type="number"*/}
                {/*scale="time"*/}
                {/*allowDecimals={false}*/}
                {/*allowDataOverflow={false}*/}
                {/*interval="preserveStartEnd"*/}
                {/*tick={{*/}
                  {/*fontSize: 9,*/}
                  {/*fill: props.colorXAxis*/}
                {/*}}*/}
                {/*tickFormatter={this.tickFormatFunction}*/}
                {/*domain={['dataMin', 'dataMax']}/>*/}
              {/*/!*<YAxis*!/*/}
              {/*/!*tick={false}*!/*/}
              {/*/!*hide={true} type="number" domain={['dataMin', 'dataMax']} />*!/*/}

              {/*<YAxis*/}
                {/*yAxisId="right"*/}
                {/*unit="kg"*/}
                {/*type="number"*/}
                {/*allowDataOverflow={true}*/}
                {/*tick={{*/}
                  {/*fontSize: 12,*/}
                  {/*fill: props.colorVoltage*/}
                {/*}}*/}
                {/*domain={['auto', 'auto']}*/}
                {/*orientation="right"/>*/}

              {/*<Line*/}
                {/*isAnimationActive={false}*/}
                {/*yAxisId="right"*/}
                {/*dot={false}*/}
                {/*type="monotoneX"*/}
                {/*dataKey="weight"*/}
                {/*strokeWidth={props.strokeWidthLines}*/}
                {/*stroke={props.colorVoltage}/>*/}

            {/*</LineChart>*/}
          {/*</ResponsiveContainer>*/}

          <ValueContainer
            title="Tagesertrag"
            value_colors={true}
            vorzeichen={true}
            value_color_schwelle={0.1}
            color_class="dunkel_gelb"
            icon={const_vars.ICON_WEIGHT}
            value={Number.parseFloat( props.scale.day_diff ).toFixed( 2 )}
            min={Number.parseFloat( props.scale.last3 ).toFixed( 2 )+" (3h) / "+Number.parseFloat(props.scale.last6).toFixed( 2 )+" (6h)"}
            max={props.scale.last24}
            einheit="kg"
            tool_value="Tagesertrag"
            tool_min="Differenz der letzten 3 und 6 Stunden"
            tool_max="24 Stunden Vergleich">
          </ValueContainer>

          <Grid 
            item  
            className={styles.scale_grid}
            // alignItems="center"
            // justify="center"
            >
            <Grid 
              container  
              className={styles.scale_grid_week}
              direction="row" 
              alignItems="center"
              justify="center" > 

              <DayComp 
                bold={false}
                caption={<Moment subtract={{days: 6}} locale="de" format="DD.MM.">{date}</Moment>} 
                value={Number.parseFloat(props.scale.day6).toFixed(2)}>
              </DayComp>
              <DayComp 
                bold={false}
                caption={<Moment subtract={{days: 5}} locale="de" format="DD.MM.">{date}</Moment>} 
                value={Number.parseFloat(props.scale.day5).toFixed(2)}>
              </DayComp>
              <DayComp 
                bold={false}
                caption={<Moment subtract={{days: 4}} locale="de" format="DD.MM.">{date}</Moment>} 
                value={Number.parseFloat(props.scale.day4).toFixed(2)}>
              </DayComp>
              <DayComp 
                bold={false}
                caption={<Moment subtract={{days: 3}} locale="de" format="DD.MM.">{date}</Moment>} 
                value={Number.parseFloat(props.scale.day3).toFixed(2)}>
              </DayComp>
              <DayComp 
                bold={false}
                caption={<Moment subtract={{days: 2}} locale="de" format="DD.MM.">{date}</Moment>} 
                value={Number.parseFloat(props.scale.day2).toFixed(2)}>
              </DayComp>
              <DayComp 
                bold={false}
                caption={<Moment subtract={{days: 1}} locale="de" format="DD.MM.">{date}</Moment>} 
                value={Number.parseFloat(props.scale.day1).toFixed(2)}>
              </DayComp>
              <DayComp 
                bold={true}
                caption={"Heute"} 
                value={Number.parseFloat( props.scale.day_diff ).toFixed( 2 )}>
              </DayComp>
              <DayComp 
                bold={true}
                caption={"Woche"} 
                value={Number.parseFloat(props.scale.day7).toFixed(2)}>
              </DayComp>

            </Grid>
          </Grid>


        </Grid>
      </Grid>
      }
    </div>
  );
  // }
}



export default Scale;