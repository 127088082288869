import * as axios from "axios";
import store from './store/store';

let instance;
if (process.env.NODE_ENV !== 'production') {
    // console.error("DEV MODE - "+config.url.API_URL);
    instance = axios.create({
        // baseURL: 'http://localhost:10001/v3/'
        baseURL: 'https://api.modbee.io/v3/'
    });
} else {
    console.error("PROD MODE - https://api.modbee.io/v3/");
    //Production ist immer automatisch eingestellt, wenn via npm build die App erstellt wird für den Server
    instance = axios.create({
      baseURL: 'https://api.modbee.io/v3/'
    });
}

store.subscribe(() => {
    //console.log(store.getState().auth.token)
    const token = store.getState().auth.token;
    if (token) {
        instance.defaults.headers.common['Authorization'] = token;
    } else {
        instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
    }
});

export default instance;