import { Typography } from "@mui/material";
import * as React from "react";

export const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + ":" : "";
    var mDisplay = m >= 0 ? m + ":" : "";
    if (m < 10) mDisplay = '0' + mDisplay
    var sDisplay = s >= 0 ? s : "";
    if (s < 10) sDisplay = '0' + sDisplay
    return hDisplay + mDisplay + sDisplay;
}

const Countdown = (props) => {
  const [counter, setCounter] = React.useState(props.seconds);

  React.useEffect(() => {
    const timerId =  counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    return () => clearTimeout(timerId);
  }, [counter]);

  return (
    <Typography variant={props.small ? "body1" :"h5"}>{secondsToHms(counter)}</Typography>
  );
}

export default Countdown
