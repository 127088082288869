import * as actionTypes from './actionTypes';
import * as const_vars from "../../Static/const_vars";

// import axios from 'axios';
export const fetchState = () => {
  console.log("fetchState");
  return {
    type: actionTypes.FETCH_STATE
  }
}

export const setTitleAndMenu = (title, menu) => {
  return {
    type: actionTypes.MAIN_CHANGE_TITLE,
    title: title,
    menu: menu
  };
};

export const setTitle = (title) => {
  return {
    type: actionTypes.MAIN_CHANGE_TITLE_ONLY,
    title: title
  };
};

export const setCurrentScale = (scale) => {
  console.log("setCurrentScale: ", scale)

  return {
    type: actionTypes.MAIN_SELECT_SCALE,
    scale: scale,
    title: scale.name,
    menu: const_vars.MENU_SCALE
  };
};

export const setCurrentMenu = (menu) => {
  return {
    type: actionTypes.MAIN_SELECT_MENU,
    menu: menu
  };
};

export const setHelperElement = (element) => {
  return {
    type: actionTypes.MAIN_SET_HELPER_ELEMENT,
    helper_element: element
  };
};

export const setMenuOpen = (open) => {
  return {
    type: actionTypes.MAIN_SET_MENU_OPEN,
    menu_open: open
  };
};

export const setThemeColors = (themeColors) => {
  return {
    type: actionTypes.CHANGE_THEME_COLOR,
    themeColors: themeColors
  };
};


