import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import DrawerContent from './DrawerContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 36,
    //   width: `calc(${theme.spacing(7)} + 1px)`,
    //   [theme.breakpoints.up('sm')]: {
    //     width: `calc(${theme.spacing(9)} + 1px)`,
    //   },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const MenuDrawer = (props) => {
    const tooSmall = useMediaQuery('(max-width:600px)', { noSsr: true });
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            {tooSmall ? <SwipeableDrawer
                anchor={'left'}
                open={props.bigDrawer}
                onClose={props.toggleBigDrawer}
                onOpen={props.toggleBigDrawer}
                classes={{
                    paper: classes.main,
                }}
            >
                <DrawerHeader />
                <DrawerContent bigDrawer={props.bigDrawer} />
            </SwipeableDrawer> :
                <Drawer variant="permanent" open={props.bigDrawer} classes={{
                    paper: classes.main,
                }}>
                    <DrawerHeader />
                    <DrawerContent bigDrawer={props.bigDrawer} />
                </Drawer>}
        </React.Fragment>
    );
}


const useStyles = makeStyles(theme => ({
    main: {
        backgroundColor: theme.palette.colors.sideBar,
    },
}));
export default MenuDrawer;