import React, {useEffect} from 'react';
import axios from '../axios-modbee';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../store/actions/firmwares";
import 'moment-timezone';
import 'moment/locale/de';
import Moment from "react-moment";
import DeleteIcon from '@mui/icons-material/Delete';
import Draggable from 'react-draggable';
import styles from './Firmware.module.css';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import MuiAlert from '@mui/lab/Alert';

import { CreateVersionNumber, ExtractVersionNumber } from "../store/utility";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(theme => ({
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(255, 255, 255, 0.7)',
  },
  description_text: {
    marginTop: 16,
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(0, 255, 255, 0.7)',
  },
  error:  {
    color: theme.palette.error.main
  }
}));

// const styles = theme => ({
//   item: {
//     paddingTop: 4,
//     paddingBottom: 4,
//     color: 'rgba(255, 255, 255, 0.7)',
//   },
// });

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Firmware = (props) => {
  const { onFetchFirmwares, userId, loading_2 } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [selectedFirmware, setSelectedFirmware] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const token = useSelector(state => state.auth.token);
  const firmwares = useSelector(state => state.main.firmwares);

  const dispatch = useDispatch();

  const handleClickOpenDelete = (id_firmware) => {
    console.log("handleClickOpenDelete firmware: "+id_firmware);
    setSelectedFirmware(id_firmware);
    setDeleteOpen(true);
  };

  const handleCloseDelete = () => {
    setDeleteOpen(false);
  };

  const handleCloseMakeDelete = () => {
    console.log("delete firmware: "+selectedFirmware);
    setDeleteOpen(false);

    if (selectedFirmware !== null) {
      setLoading(true);
      const config = {
        headers: {'Authorization': ''+token}
      };

      axios.delete("firmware/"+selectedFirmware, config, { // receive two parameter endpoint url ,form data 

      }).then(res => { // then print response status
        console.log("antowrt delete: "+res.statusText)
        dispatch(actions.fetchFirmwares(token));
        setLoading(false);
      }).catch(function (error) {
        // handle error
        setLoading(false);
        console.log(error);
      });   

    } else {
      //Fehler anzeigen!
      // console.log("fehler anzeigen - keine Datei ausgewählt");
      setErrorMessage("Es muss zuerst eine Firmware-Datei ausgewählt werden!");
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onChangeDescHandler = (event) => {
    // console.log(event.target.files[0]);
    setDescription(event.target.value);
    // console.log(event.target.files[0].size);
  };

  const onChangeHandler = (event) => {
    // console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
    // console.log(event.target.files[0].size);
  };

  const uploadFileHandler = () => {
    // setOpen(true);
    
    if (selectedFile !== null) {
      setLoading(true);
      console.log("upload file: "+JSON.stringify(selectedFile));
      console.log("upload file description: "+description);
      const data = new FormData();
      data.append('file', selectedFile);
      data.append('fw_number', "1");
      data.append('fw_size', selectedFile.size);
      data.append('description', description);
      
      // const config = {
      //   headers: {'Authorization': ''+token}
      // };
      //TODO header mit token!
      axios.post("upload", data, { // receive two parameter endpoint url ,form data 

      }).then(res => { // then print response status
        console.log("antowrt upload: "+res.statusText)
        // onFetchFirmwares(token, userId);
        dispatch(actions.fetchFirmwares(token));
        setLoading(false);
      }).catch(function (error) {
        // handle error
        setLoading(false);
        console.log(error);
      });
    } else {
      //Fehler anzeigen!
      // console.log("fehler anzeigen - keine Datei ausgewählt");
      setErrorMessage("Es muss zuerst eine Firmware-Datei ausgewählt werden!");
      setOpen(true);
    }
  };
  console.log("firmwares: ", firmwares)

  useEffect( () => {    
    dispatch(actions.fetchFirmwares(token));
  }, [dispatch, onFetchFirmwares, token]);

  return (
    <Paper style={{margin: 16}}>
      <input
        // accept="application/octet-stream "
        accept=".sfb,.bin"
        className={classes.input}
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file" 
        onChange={onChangeHandler}
      />
      <label htmlFor="raised-button-file">
        {/* <Button variant="contained" component="span" className={classes.button}>
          Upload
        </Button> */}
        <Button variant="contained" component="span" className={classes.button}>
          Neue FW auswählen
        </Button>
      </label> 
      { selectedFile != null &&
        <p >Datei: {selectedFile.name} - Dateigröße: {selectedFile.size} Bytes</p>
      }
      <br />  

      <TextField
          id="fw_description"
          label="Beschreibung"
          style={{ margin: 8 }}
          placeholder="Beschreibung"
          className={styles.description_text}
          fullWidth
          margin="normal"
          onChange={onChangeDescHandler}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        />
      <br /> 
      <br />  
      <Button variant="contained" component="span" color="primary" 
        onClick={uploadFileHandler}>Upload</Button>
      <br />  
      <h4>Firmware Versionen für die Waagen</h4>
      <Paper>
          { loading && 
            <LinearProgress />
          }
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Firmware-Name / ID</TableCell>
              <TableCell>FW-Nummer / Datum</TableCell>
              <TableCell>Beschreibung</TableCell>
              <TableCell padding="checkbox" align="right">Löschen</TableCell>
            </TableRow>
          </TableHead>

          { firmwares !== undefined && 
          <TableBody>

            {firmwares.map(row => (
              <TableRow 
              hover 
              key={row.id_firmware}>
                <TableCell component="th" scope="row">
                  {row.filename_fw}<br />
                  <Typography variant="caption">{row.fw_size} kB / ID {row.id_firmware}</Typography> 
                </TableCell>
                <TableCell>
                  <strong>{row.fw_major}.{row.fw_minor}.{row.fw_patch}</strong> <Typography variant="caption">(<Moment locale="de" utc format="DD.MMM YYYY">{ row.upload_date }</Moment>)</Typography><br />
                  <a href={ "https://api.modbee.io/fw/scale-kit-"+row.id_firmware+".bin" } rel="noopener noreferrer" target="_blank">Download</a>
                  </TableCell>
                <TableCell  component="th" scope="row">
                {row.description}<br />
                </TableCell>


                <TableCell align="right" padding="checkbox">
                  <IconButton aria-label="delete" onClick={() => handleClickOpenDelete(row.id_firmware)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          }
        </Table>
      </Paper>
      

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={deleteOpen}
        onClose={handleCloseDelete}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title" >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Firmware löschen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Soll diese Firmware wirklich gelöscht werden?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDelete} color="primary">
            Abbruch
          </Button>
          <Button onClick={handleCloseMakeDelete}  className={classes.error}>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      <br />  
    </Paper>
  );
}



export default Firmware;