import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import axios from '../../../axios-modbee';
import { useTranslation } from 'react-i18next';
import const_vars from "../../../Static/const_vars";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { secondsToHms } from '../../../UI/Countdown/Countdown';


const Countdown = (props) => {
    const { t } = useTranslation();
    const countdown = props.caliData.duration;
    const remaining = props.caliData.duration - props.sekundenSeitStart;

    useEffect(() => {
        if (props.waage) console.log(props.waage.status)
        //    if (props.waage.status === 1015) props.next()
        // hier muss ich warten bis mir gesagt wird dass ich weiter kann (bee event)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.waage]); //

    const stoppen = () => {

        const config = {
            daten: { 'status': 1 }
        };

        axios.put('/calibration/step/' + props.stepid, config)
            .then(res => {
                console.log(res.data)
                const config = {
                    data: {
                        "befehl": const_vars.KALIBRIERUNGS_BEFEHL,
                        "cmd": const_vars.GW_STATUS_SHOULD_IDLE_CALIBRATION,
                        "id_scales": props.waage.id_scales,
                    },
                    crossdomain: true
                };

                axios.put('/scale/custom/' + props.waage.id_scales, config)
                    .then(res => {
                        props.next()
                        console.log("got sendCustomWriteToGateway answer: ", res);
                    })
                    .catch(err => {
                        console.log("got sendChargerFw error: ", err);
                    });
            })
            .catch(err => {
                console.log("got sendChargerFw error: ", err);
            });
    }

    const renderTime = ({ remainingTime }) => <Typography >{secondsToHms(remainingTime)}</Typography>

    return (
        <Grid container style={{ width: "100%" }}>
            <Grid item xs={12}>
                <Typography align="center" variant="caption" color="text.secondary" component="div">
                    Sie werden automatisch weitergeleitet
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 8 }}>
                <Grid container alignItems="flex-end">
                    <Grid item xs />
                    <Grid item>
                        <CountdownCircleTimer
                            isPlaying
                            size={100}
                            strokeWidth={6}
                            duration={countdown}
                            initialRemainingTime={remaining}
                            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                            colorsTime={[countdown, Math.floor(2 * countdown / 3), Math.floor(countdown / 3), 0]}
                            onComplete={stoppen}
                        >
                            {renderTime}
                        </CountdownCircleTimer>
                    </Grid>
                    <Grid item xs />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Countdown