import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, IconButton, Collapse, Tooltip, Button, CircularProgress, Card, Divider, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import axios from '../../axios-modbee';
import { useSelector, useDispatch } from 'react-redux';
import * as actions_scales from "../../store/actions/scales";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

const WaagenName = (props) => {
    const { row } = props;
    const [edit, setEdit] = React.useState(false);
    const [name, setName] = React.useState(props.row.name);
    const classes = useStyles();
    const { t } = useTranslation();
    const scales = useSelector(state => state.scale.scales);
    const dispatch = useDispatch()

    const rename = () => {
        const config = {
            daten: {
                "name": name,
            },
            crossdomain: true
        };
        axios.post('/scale/edit/' + row.id_scales, config)
            .then(res => {
                const tempA = [...scales]
                const index = tempA.findIndex(fff => fff.id_scales === row.id_scales)
                const newScale = { ...tempA[index], ...config.daten }
                tempA[index] = newScale;
                dispatch(actions_scales.fetchScalesSuccess(tempA));
                console.log("got edit scale answer: ", res);
                setEdit(false)
            })
            .catch(err => {
                console.log("got edit scale error: ", err);
            });

    }

    if (edit) return (
        <Grid container onClick={() => setEdit(true)} alignItems="center">
            <Grid item xs><TextField variant="standard" autoFocus
                onChange={(e) => setName(e.target.value)}
                id="name"
                label={t('main.Waagenname')}
                value={name}
                placeholder={name}
                fullWidth /></Grid>
            <Grid item style={{ paddingLeft: 8 }}><Tooltip title={t('main.Waage umbenennen')}><IconButton onClick={rename}><SaveIcon /></IconButton></Tooltip></Grid>
        </Grid>
    ); else return (

        <Grid container className={classes.editable} onClick={() => setEdit(true)}>
            <Grid item><Typography>Name: {row.name}</Typography></Grid>
            <Grid item style={{ maxHeight: 24, paddingLeft: 8 }}><Tooltip title={t('main.Waage umbenennen')}><EditIcon size='small' className={classNames("showedit", classes.editIcon)} /></Tooltip></Grid>
        </Grid>

    );
}

const useStyles = makeStyles((theme) => ({

    editable: {
        '&:hover': {
            backgroundColor: '#e0e0e0',
            cursor: 'pointer',
            "& .showedit": {
                display: "inline"
            }
        },
    },
    editIcon: {
        color: '#777',
        display: 'none'
    }
}));


export default WaagenName;