import React, { useEffect } from 'react';
import { Grid, Typography, Container } from '@mui/material';
import * as const_vars from "../Static/const_vars";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as actions from "../store/actions/main";
import { makeStyles } from '@mui/styles';

const Refurbed = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => {
    dispatch(actions.setTitleAndMenu(t('main.Refurbed'), const_vars.MENU_REFURBED));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) //

  return (

    <Container>

      <Grid container justifyContent="center" spacing={4} className={classes.root}>

        <Grid item xs={12}>
          <Typography gutterBottom variant="h6" component="div">
          Hier können Sie in Zukunft gebrauchte und überholte Bienenstockwaagen und Zubehör kaufen und verkaufen. Wir kaufen defekte Waagen auf und überholen Sie. Anschließend bieten wir die Waagen wieder zum Verkauf an inkl. Garantie
          </Typography>
        </Grid>  
      </Grid>
    </Container>
  );
}


export const useStyles = makeStyles((theme) => ({
  root: {
      padding: 16
  },
  
}));


export default Refurbed;