import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyAnyxaFvhtf2N6KLRbWfREHoI_TRVkRXx4",
  authDomain: "project-2042544741081579589.firebaseapp.com",
  databaseURL: "https://project-2042544741081579589.firebaseio.com",
  projectId: "project-2042544741081579589",
  storageBucket: "project-2042544741081579589.appspot.com",
  messagingSenderId: "551443545984",
  appId: "1:551443545984:android:cc7c1e54058227b4"
};

export const app = initializeApp(firebaseConfig);