import * as actionTypes from '../actions/actionTypes';
import {precise_round, updateObject} from '../utility';
import moment from "moment/moment";
import * as d3 from "d3";
import * as const_vars from "../../Static/const_vars";
// 
  // const primary = localStorage.getItem('primary') != null ? localStorage.getItem('primary') : primaryTemp;
  // const secondaryTemp = useSelector(state => state.main.themeColors.secondary);
  // const secondary = localStorage.getItem('secondary') != null ? localStorage.getItem('secondary') : secondaryTemp;

export const appBarStandard = "#807871";
export const sideBarStandard = "#698c7e";
export const primaryStandard = "#6f95c1"; //4a90e2"; //5faab9
export const primaryTextStandard = "#fff"; //5faab9
export const secondaryTextStandard = "#ccc"; //5faab9
export const secondaryStandard = "#698c7e";
export const highlightStandard = "#adea00";  //5faab9
export const weightStandard = "#0097A7"; //B71C1C"; //0097A7";
export const temperatureStandard = "#1565C0";
export const humidityStandard = "#6A1B9A";
export const voltageStandard = "#698c7e";
export const ertragStandard = "#7CB342";


const initialState = {
  title: "Dashboard",
  menu: 0,
  menu_open: false,
  helper_element: null,
  scaleWorker: null,
  currentScale: [],
  currentMenu: "",
  account: [],
  accounts: [],
  scale: {
    id_scale : 0,
  },
  scales: localStorage.getItem('scales') != null ? localStorage.getItem('scales') : [],
  all_scales: [],
  scaledata: [],
  sorted_scaledata: [],
  loading: false,
  purchased: false,

  stored_data: {
    loading_all: false
  },

  themeColors: {
    visible: false, //zum editierend er Farben
    primary: localStorage.getItem('primary') != null ? localStorage.getItem('primary') : primaryStandard,
    secondary: localStorage.getItem('secondary') != null ? localStorage.getItem('secondary') : secondaryStandard,
    primaryText: localStorage.getItem('primaryText') != null ? localStorage.getItem('primaryText') : primaryTextStandard,
    secondaryText: localStorage.getItem('secondaryText') != null ? localStorage.getItem('secondaryText') : secondaryTextStandard,
    highlight: localStorage.getItem('highlight') != null ? localStorage.getItem('highlight') : highlightStandard,
    appBar: localStorage.getItem('appBar') != null ? localStorage.getItem('appBar') : appBarStandard,
    sideBar: localStorage.getItem('sideBar') != null ? localStorage.getItem('sideBar') : sideBarStandard,
    ertrag: localStorage.getItem('ertrag') != null ? localStorage.getItem('ertrag') : ertragStandard,
    weight: localStorage.getItem('weight') != null ? localStorage.getItem('weight') : weightStandard,
    temperature: localStorage.getItem('temperature') != null ? localStorage.getItem('temperature') : temperatureStandard,
    humidity: localStorage.getItem('humidity') != null ? localStorage.getItem('humidity') : humidityStandard,
    voltage: localStorage.getItem('voltage') != null ? localStorage.getItem('voltage') : voltageStandard,
  },
  zoomState: {
    left : 0,
    right : 0,
    topErtrag : 0,
    bottomErtrag : 0,
    topGewicht : 0,
    bottomGewicht : 0,
    zoomDays : 7,
    refAreaLeft : '',
    refAreaRight : '',
    animation : true
  }
};

const updateZoomState = ( state, action ) => {
  // console.log("updateZoomState: "+JSON.stringify(action.zoomState));
  return updateObject( state, {
    zoomState: action.zoomState
  } );
};

const changeScaleData = ( state, action ) => {
  // console.log("changeScaleData: ", action.new_scale, " scales: ", action.scales);
  const changed_scale = action.new_scale;

  return updateObject( state, {
    scales: action.scales.map((scale, index) => {
          if (changed_scale.id_scale != scale.id_scale) {
            // This isn't the item we care about - keep it as-is
            return scale
          }
          // Otherwise, this is the one we want - return an updated value

          return {
            ...scale,  // copy the existing item
            last3: changed_scale.last3,
            last6: changed_scale.last6,
            last24: changed_scale.last24,
            weight: changed_scale.weight,
            activated: changed_scale.activated,
          }
        })
  } );

  // return updateObject( state, {
  //   scaledata: action.scaledata
  // } );
};

const fetchScaleDataStart = ( state, action ) => {
  return updateObject( state, { loading: true } );
};



const getMappedScaleData = ( scaledata ) => {

  let last_event = 0;
  let last_event_weight = 0;
  let last_event_weight_before = 0;
  let last_weight = 0;
  let last_weight_save = 0;
  let diff = 0;
  let event_diff = 0;
  let ertrag = 0;
  let event = 0;
  const timeFormat = 'DD.MM.YYYY HH:mm';

  return scaledata.map((dataPoint, index) => {
    event = dataPoint.event;

    // eslint-disable-next-line
    if (index == 0) {
      diff = 0;
    } else {
      diff = dataPoint.weight - last_weight;
      event_diff = dataPoint.weight - last_event_weight;
    }

    //TODO Fütterung, Drohnenbrutentnahme usw

    //Falls Event erkannt wird, aber nächster Datenpun
    if (event > 0) {
      // eslint-disable-next-line
      if (event === const_vars.EVENT_BODY_ADD) {
        diff = 0;
        // eslint-disable-next-line
      } else if (event === const_vars.EVENT_SWARM) {
        diff = 0;
        // eslint-disable-next-line
      } else if (event === const_vars.EVENT_BODY_REMOVE) {
        diff = 0;
      } else if (event === const_vars.EVENT_IGNORE) {
        // diff = 0;

      } else if (event === const_vars.EVENT_CONTROL) {
        diff = 0;
      } else if (event === const_vars.EVENT_FRAMES_ADD) {
        diff = 0;
      } else if (event === const_vars.EVENT_FRAMES_REMOVE) {
        diff = 0;
      } else if (event === const_vars.EVENT_HONEY) {
        diff = 0;
      } else if (event === const_vars.EVENT_VARROA) {
        diff = 0;
      } else if (event === const_vars.EVENT_ROBBING) {

      } else if (event === const_vars.EVENT_WRONG_MEASUREMENT) {
        diff = 0;
      }
      last_event_weight_before = last_event_weight;
      last_event_weight = last_weight;
      last_event = event;
    } else {
      if (index > 0) {
        //ACHTUNG! Differenz nur mit diesen Werten, falls 15 min Unterschied!
        //Sonst müssen höhere Werte genommen werden!
        if (Math.abs(diff) > 1) {
          // console.log("dataPoint: ", dataPoint);
          // console.log("last_event: ", last_event);
          // console.log("last_event_weight: ", last_event_weight);
          // console.log("last_weight: ", last_weight);
          // console.log("event_diff: ", event_diff);
          // console.log("diff: ", diff);
          // console.log("dataPoint.weight: ", dataPoint.weight);

          if (Math.abs(event_diff) < 1) {
            //Nach einem Event wird Ursprungsgewicht wieder eingenommen!
            if (last_event > 10) {
              // größer 10 heißt, dass Event bestätigt wurde!!
              diff = 0;
            }
          } else {
            if (diff > 0) {
              event = 1;  //Zarge aufgesetzt?!?
              // diff = 0;
            } else {
              //Falls es abgenommen hat, muss Ertrag weggerechnet werden!
              if (Math.abs(diff) > 5) {
                event = 3;  //Zage abgenommen!
                // diff = 0;
              } else {
                event = 2;  //SCHWARM?!?
                // diff = 0;
              }
            }
            last_event_weight_before = last_event_weight;
            last_event_weight = last_weight;
            last_event = event;
          }
        }
      }
    }

    last_weight_save = last_weight;
    ertrag += diff;
    last_weight = dataPoint.weight;

    return {
      // x: moment.utc(dataPoint.change_time).format(timeFormat),
      // y: dataPoint.scale,
      id_weight: dataPoint.id_weight,
      id_scale: dataPoint.id_scale,
      temperature: dataPoint.temp,
      humidity: dataPoint.hum,
      pressure: dataPoint.p,
      scale: dataPoint.weight,
      weight: dataPoint.weight,
      scale_val: dataPoint.scale,
      last: last_weight_save,
      diff: diff,
      ertrag: ertrag,
      event: event,
      temp_scale: dataPoint.temp2,
      time: dataPoint.change_time,
      voltage: dataPoint.vc,
      date: moment.utc(dataPoint.change_time).format(timeFormat), //moment(dataPoint.time).valueOf()/1000,
      voltage_percent: dataPoint.vc_p,
      signal_time: moment.utc(dataPoint.change_time).valueOf(), //= milliseconds
      time_string: moment.utc(dataPoint.change_time).format(timeFormat),
    };
  });
};


const createPlotlyData = ( scaledata ) => {

  let last_event = 0;
  let last_event_weight = 0;
  let last_event_weight_before = 0;
  let last_weight = 0;
  let last_weight_save = 0;
  let diff = 0;
  let event_diff = 0;
  let ertrag = 0;
  let event = 0;
  let lastTemp = 0;
  const timeFormat2 = 'DD.MM.YYYY HH:mm:ss';
  const timeFormat = 'YYYY-MM-DD HH:mm:ss';



  return scaledata.map((dataPoint, index) => {
    event = dataPoint.event;
    // eslint-disable-next-line
    if (index == 0) {
      diff = 0;
    } else {
      diff = dataPoint.weight - last_weight;
      event_diff = dataPoint.weight - last_event_weight;
    }

    //TODO Fütterung, Drohnenbrutentnahme usw

    //Falls Event erkannt wird, aber nächster Datenpun
    if (event > 0) {
      // eslint-disable-next-line
      if (event === const_vars.EVENT_BODY_ADD) {
        diff = 0;
        // eslint-disable-next-line
      } else if (event === const_vars.EVENT_SWARM) {
        diff = 0;
        // eslint-disable-next-line
      } else if (event === const_vars.EVENT_BODY_REMOVE) {
        diff = 0;
      } else if (event === const_vars.EVENT_IGNORE) {
        // diff = 0;

      } else if (event === const_vars.EVENT_CONTROL) {
        diff = 0;
      } else if (event === const_vars.EVENT_FRAMES_ADD) {
        diff = 0;
      } else if (event === const_vars.EVENT_FRAMES_REMOVE) {
        diff = 0;
      } else if (event === const_vars.EVENT_HONEY) {
        diff = 0;
      } else if (event === const_vars.EVENT_VARROA) {
        diff = 0;
      } else if (event === const_vars.EVENT_ROBBING) {

      } else if (event === const_vars.EVENT_WRONG_MEASUREMENT) {
        diff = 0;
      }
      last_event_weight_before = last_event_weight;
      last_event_weight = last_weight;
      last_event = event;
    } else {
      if (index > 0) {
        //ACHTUNG! Differenz nur mit diesen Werten, falls 15 min Unterschied!
        //Sonst müssen höhere Werte genommen werden!
        if (Math.abs(diff) > 1) {
          // console.log("dataPoint: ", dataPoint);
          // console.log("last_event: ", last_event);
          // console.log("last_event_weight: ", last_event_weight);
          // console.log("last_weight: ", last_weight);
          // console.log("event_diff: ", event_diff);
          // console.log("diff: ", diff);
          // console.log("dataPoint.weight: ", dataPoint.weight);

          if (Math.abs(event_diff) < 1) {
            //Nach einem Event wird Ursprungsgewicht wieder eingenommen!
            if (last_event > 10) {
              // größer 10 heißt, dass Event bestätigt wurde!!
              diff = 0;
            }
          } else {
            if (diff > 0) {
              event = 1;  //Zarge aufgesetzt?!?
              // diff = 0;
            } else {
              //Falls es abgenommen hat, muss Ertrag weggerechnet werden!
              if (Math.abs(diff) > 5) {
                event = 3;  //Zage abgenommen!
                // diff = 0;
              } else {
                event = 2;  //SCHWARM?!?
                // diff = 0;
              }
            }
            last_event_weight_before = last_event_weight;
            last_event_weight = last_weight;
            last_event = event;
          }
        }
      }
    }

    last_weight_save = last_weight;
    ertrag += diff;
    last_weight = dataPoint.weight;


    if (dataPoint.temperature == -40) {
      dataPoint.temperature = lastTemp;
    } else {
      lastTemp = dataPoint.temperature;
    }


    return {
      // x: moment.utc(dataPoint.change_time).format(timeFormat),
      // y: dataPoint.scale,
      id_weight: dataPoint.id_weight,
      id_scale: dataPoint.id_scale,
      temperature: lastTemp,
      humidity: dataPoint.humidity,
      pressure: dataPoint.pressure,
      scale: dataPoint.weight,
      weight: dataPoint.weight,
      scale_val: dataPoint.scale,
      last: last_weight_save,
      diff: diff,
      ertrag: ertrag,
      event: event,
      temp_scale: dataPoint.temperature2,
      time: dataPoint.change_time,
      voltage: dataPoint.vc,
      date: moment.utc(dataPoint.change_time).format(timeFormat), //moment(dataPoint.time).valueOf()/1000,
      voltage_percent: dataPoint.vc_p,
      signal_time: moment.utc(dataPoint.change_time).valueOf(), //= milliseconds
      time_string: moment.utc(dataPoint.change_time).format(timeFormat),
    };
  });
};



const fetchScaleDataSuccess = ( state, action ) => {
  console.log("fetchScaleDataSuccess: ", action);

  const newScaleData = createPlotlyData(action.scaledata);
  
  // const newScaleData = getMappedScaleData(action.scaledata);

  // const now = new Date();
  // const left = now.getTime()- (60*1000*60*24*7) ;//this.week;
  // const right = now.getTime();
  // const offsetWeight = 0.5;
  // const filtered = newScaleData.filter(function (dataPoint) {
  //   return ((dataPoint.signal_time <= right) && (dataPoint.signal_time >= left));
  // });

  // let topErtrag = Math.round(d3.max(filtered, function (d) {
  //   return d.ertrag;
  // })) + offsetWeight;
  // let bottomErtrag = Math.floor(d3.min(filtered, function (d) {
  //   return d.ertrag;
  // })) - offsetWeight;

  // let topGewicht = Math.round(d3.max(filtered, function (d) {
  //   return d.weight;
  // })) + offsetWeight;
  // let bottomGewicht = Math.floor(d3.min(filtered, function (d) {
  //   return d.weight;
  // })) - offsetWeight;

  // const zoomState = {
  //   refAreaLeft: '',
  //   refAreaRight: '',
  //   zoomDays: 7,
  //   zoomNumber: 4,
  //   left: left,
  //   right: right,
  //   topErtrag: topErtrag,
  //   bottomErtrag: bottomErtrag,
  //   topGewicht: topGewicht,
  //   bottomGewicht: bottomGewicht,
  //   animation: true
  // };

  //zoomState gleich richtig machen!
  return updateObject( state, {
    // zoomState: zoomState,
    scaledata: newScaleData,
    loading: false
  } );
  
  // const now = new Date();
  // const left = now.getTime()- (60*1000*60*24*7) ;//this.week;
  // const right = now.getTime();
  // const offsetWeight = 0.5;
  // const filtered = newScaleData.filter(function (dataPoint) {
  //   return ((dataPoint.signal_time <= right) && (dataPoint.signal_time >= left));
  // });

  // let topErtrag = Math.round(d3.max(filtered, function (d) {
  //   return d.ertrag;
  // })) + offsetWeight;
  // let bottomErtrag = Math.floor(d3.min(filtered, function (d) {
  //   return d.ertrag;
  // })) - offsetWeight;

  // let topGewicht = Math.round(d3.max(filtered, function (d) {
  //   return d.weight;
  // })) + offsetWeight;
  // let bottomGewicht = Math.floor(d3.min(filtered, function (d) {
  //   return d.weight;
  // })) - offsetWeight;

  // const zoomState = {
  //   refAreaLeft: '',
  //   refAreaRight: '',
  //   zoomDays: 7,
  //   zoomNumber: 4,
  //   left: left,
  //   right: right,
  //   topErtrag: topErtrag,
  //   bottomErtrag: bottomErtrag,
  //   topGewicht: topGewicht,
  //   bottomGewicht: bottomGewicht,
  //   animation: true
  // };

  // //zoomState gleich richtig machen!
  // return updateObject( state, {
  //   zoomState: zoomState,
  //   scaledata: newScaleData,
  //   loading: false
  // } );
};



const fetchScaleDataSuccessAll = ( state, action ) => {


  // const newScaleData = getMappedScaleData(action.scaledata);
  const newScaleData = createPlotlyData(action.scaledata);

  // console.log("state state ", JSON.stringify(state.zoomState));

  // const now = new Date();
  // const left = state.zoomState.left ;//this.week;
  // const right = now.getTime(); //state.right
  // const offsetWeight = 0.5;
  // const filtered = newScaleData.filter(function (dataPoint) {
  //   return ((dataPoint.signal_time <= right) && (dataPoint.signal_time >= left));
  // });
  // let topErtrag = Math.round(d3.max(filtered, function (d) {
  //   return d.ertrag;
  // })) + offsetWeight;
  // let bottomErtrag = Math.floor(d3.min(filtered, function (d) {
  //   return d.ertrag;
  // })) - offsetWeight;
  // let topGewicht = Math.round(d3.max(filtered, function (d) {
  //   return d.weight;
  // })) + offsetWeight;
  // let bottomGewicht = Math.floor(d3.min(filtered, function (d) {
  //   return d.weight;
  // })) - offsetWeight;

  // const zoomState = {
  //   refAreaLeft: '',
  //   refAreaRight: '',
  //   zoomDays: state.zoomState.zoomDays,
  //   zoomNumber: state.zoomState.zoomNumber,
  //   left: state.zoomState.left,
  //   right: state.zoomState.right,
  //   topErtrag: topErtrag,
  //   bottomErtrag: bottomErtrag,
  //   topGewicht: topGewicht,
  //   bottomGewicht: bottomGewicht,
  //   animation: true
  // };

  // //zoomState gleich richtig machen!
  // return updateObject( state, {
  //   zoomState: zoomState,
  //   scaledata: newScaleData,
  //   loading: false
  // } );
  return updateObject( state, {
    // zoomState: zoomState,
    scaledata: newScaleData,
    loading: false
  } );
};

const fetchScaleDataFail = ( state, action ) => {
  // console.log("fetchScaleDataFail: ", action);
  return updateObject( state, { loading: false } );
};

const fetchScalesStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchScalesSuccess = ( state, action ) => {
  console.log("fetchScalesSuccess: ", action);

  const timeFormat = 'DD.MM.YYYY HH:mm';
  const newScaleData =  action.scales.map((dataPoint, index) => {

    let day_diff = 0;
    let day_start = 0;
    let last_weight = 0;
    let diff = 0;
    let ertrag = 0;
    let event = 0;

    // eslint-disable-next-line
    if (dataPoint.weights != undefined) {
      const startOfDay = moment().startOf('day').valueOf();
      //TODO Tagesdifferenz ausrechnen!
      const firstDayEl = dataPoint.weights.filter(weight => {
          //Nur heutige Werte zurückliefern!
          return startOfDay < moment.utc(weight.change_time).valueOf();
        }).slice(0, 1);

      if (firstDayEl.length > 0) {
        console.log("firstDayEl: ", firstDayEl[0].weight);
        day_diff = (dataPoint.last_weight - firstDayEl[0].weight).toFixed(2);
        // day_diff = (dataPoint.weight - firstDayEl[0].weight).toFixed(2);
        day_start =  firstDayEl[0].weight;
      }


      return {
        ...dataPoint,
        day_start: day_start,
        weights: dataPoint.weights.map((weight, index2)=> {
          event = weight.event;

          // eslint-disable-next-line
          if (index2 == 0) {
            diff = 0;
          } else {
            diff = weight.weight - last_weight;
          }
          if (event > 0) {
            // eslint-disable-next-line
            if (event == 11) {
              //Zarge aufgesetzt?!?
              day_diff = day_diff - (weight.weight - last_weight);
              diff = 0;
              // eslint-disable-next-line
            } else if (event == 12) {
              //schwarm
              day_diff = day_diff - (weight.weight - last_weight);
              diff = 0;
              // eslint-disable-next-line
            } else if (event == 13) {
              //Zarge abgenommen
              day_diff = day_diff - (weight.weight - last_weight);
              diff = 0;
            }
          }

          ertrag += diff;
          last_weight = weight.weight;

          return {
            ...weight,
            weight: precise_round(weight.weight, 2),
            diff: diff,
            last: last_weight,
            // day_diff: day_diff,
            ertrag: precise_round(ertrag, 2),
            time: weight.change_time,
            date: moment.utc(weight.change_time).format(timeFormat), //moment(dataPoint.time).valueOf()/1000,
            signal_time: moment.utc(weight.change_time).valueOf(), //= milliseconds
            time_string: moment.utc(weight.change_time).format(timeFormat)
          }
        }),

        //day_diff wird im Map abgeändert falls events gefunden werden!!
        day_diff: day_diff
      };
    } else {
      return {
        ...dataPoint,
        day_diff: day_diff,
        day_start: 0
      };
    }
  });


  console.log("fetchScales newScaleData: "+JSON.stringify(newScaleData));
  return updateObject( state, {
      scales: newScaleData, //action.scales,
      loading: false
  } );
};
const fetchScalesFail = ( state, action ) => {
  console.log("fetchScalesFail: ", action);

    return updateObject( state, { loading: false } );
};

const setMainTitle = (state, action) => {
  return updateObject(state, {

    title: action.title,
    menu: action.menu  })
};

const setTitleOnly = (state, action) => {
  return updateObject(state, {
    title: action.title })
};


const setMenuOpen = (state, action) => {
  return updateObject(state, { menu_open: action.menu_open })
};

const setHelperElement = (state, action) => {
  return updateObject(state, { helper_element: action.helper_element })
};

const setCurrentScale = (state, action) => {
  return updateObject(state, {
    currentScale: action.scale,
    title: action.title,
    menu: action.menu })
};

const setCurrentMenu = (state, action) => {
  return updateObject(state, { menu: action.menu })
};

const fetchFirmwaresStart = ( state, action ) => {
  return updateObject( state, { loading: true } );
};

const fetchFirmwaresSuccess = ( state, action ) => {
  // console.log("fetchFirmwaresSuccess: ", action);
  return updateObject( state, {
        firmwares: action.firmwares,
        loading: false
    });
};
const fetchFirmwaresFail = ( state, action ) => {
  return updateObject( state, { loading: false } );
};

const fetchAccountsStart = ( state, action ) => {
  // console.log("fetchAccountsStart: ", action);
  return updateObject( state, { loadingAccounts: true } );
};

const fetchAccountsSuccess = ( state, action ) => {
  // console.log("fetchAccountsSuccess: ", action);
  return updateObject( state, {
        accounts: action.accounts,
        loading: false
    });
};
const fetchAccountsFail = ( state, action ) => {
  return updateObject( state, { loadingAccounts: false } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
      case actionTypes.MAIN_CHANGE_TITLE: return setMainTitle(state, action);
      case actionTypes.MAIN_CHANGE_TITLE_ONLY: return setTitleOnly(state, action);
      case actionTypes.MAIN_SET_MENU_OPEN: return setMenuOpen(state, action);
      case actionTypes.MAIN_SET_HELPER_ELEMENT: return setHelperElement(state, action);
      case actionTypes.MAIN_SELECT_SCALE: return setCurrentScale(state, action);
      case actionTypes.MAIN_SELECT_MENU: return setCurrentMenu(state, action);

      case actionTypes.FETCH_SCALES_START: return fetchScalesStart( state, action );
      case actionTypes.FETCH_SCALES_SUCCESS: return fetchScalesSuccess( state, action );
      case actionTypes.FETCH_SCALES_FAIL: return fetchScalesFail( state, action );
      case actionTypes.FETCH_SCALE_DATA_START: return fetchScaleDataStart( state, action );
      case actionTypes.FETCH_SCALE_DATA_SUCCESS: return fetchScaleDataSuccess( state, action );
      case actionTypes.FETCH_SCALE_DATA_SUCCESS_ALL: return fetchScaleDataSuccessAll( state, action );

      case actionTypes.FETCH_FIRMWARES_START: return fetchFirmwaresStart( state, action );
      case actionTypes.FETCH_FIRMWARES_SUCCESS: return fetchFirmwaresSuccess( state, action );
      case actionTypes.FETCH_FIRMWARES_FAIL: return fetchFirmwaresFail( state, action );

      case actionTypes.FETCH_SCALE_DATA_FAIL: return fetchScaleDataFail( state, action );

      case actionTypes.UPDATE_ZOOM_STATE: return updateZoomState( state, action );
      case actionTypes.CHANGE_SCALE_DATA: return changeScaleData( state, action );

      case actionTypes.FETCH_ACCOUNTS_START: return fetchAccountsStart( state, action );
      case actionTypes.FETCH_ACCOUNTS_SUCCESS: return fetchAccountsSuccess( state, action );
      case actionTypes.FETCH_ACCOUNTS_FAIL: return fetchAccountsFail( state, action );

      case actionTypes.FETCH_USER_ACCOUNT_SUCCESS:
        return updateObject( state, {
          account: action.account
        });

      case actionTypes.FETCH_ALL_SCALES:
        // console.log("FETCH_ALL_SCALES in switch")
        return updateObject( state, {
          all_scales: action.all_scales
        });

      case actionTypes.CHANGE_THEME_COLOR:        
        console.log("action.themeColors: ",action.themeColors);
        return updateObject( state, {
          themeColors: action.themeColors
        });

      case actionTypes.SET_SCALE_WORKER:
        // console.log("SET_SCALE_WORKER");
        return updateObject( state, {
          scaleWorker: action.scaleWorker
        });

      case  actionTypes.FETCH_STATE:
        // console.log("fetchState executed: ", state.scales);
          return  state;
          // {
          //       ...state, 
          //   }      

      default: return state;
    }
};

export default reducer;