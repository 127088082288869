import React, { useEffect } from 'react';
import { Grid, Typography, Container } from '@mui/material';
import * as const_vars from "../Static/const_vars";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as actions from "../store/actions/main";
import Product from './Product/Product';
import { makeStyles } from '@mui/styles';
import scaleKit from '../Static/scale-kit.jpg';
import doItYourself from '../Static/do-it-yourself.jpg';
import platine from '../Static/scale-module-sm-bw.png';
import modules from '../Static/modules-sm.png';

const Shop = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => {
    dispatch(actions.setTitleAndMenu(t('main.Shop'), const_vars.MENU_SHOP));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) //

  return (

    <Container>

      <Grid container justifyContent="center" spacing={4} className={classes.root}>

        <Grid item xs={12}>
          <Typography gutterBottom variant="h6" component="div">
            ACHTUNG: Aufgrund von Corona und des weltweiten Chip-Mangels, sind aktuell leider keine scale-kits verfügbar. Bitte besuchen Sie uns in einiger Zeit wieder, oder melden sich <b>hier</b> bei unserem Newsletter an, dann werden Sie sofort informiert, sobald wieder welche verfügbar sind.
          </Typography>
        </Grid>

        <Grid item>
          <Product img={scaleKit}
            title={"scale-kit"}
            desc={"Aus alt mach neu! Elektronik zum Austausch defekter Waagenelektronik. Auch Laien können mit diesem Reparatur-Set eine defekte elektronische Bienenstockwaage reparieren."} />
        </Grid>
        <Grid item>
          <Product 
            img={platine} 
            title={"Reparatur-Service"}
            desc={"Sie schicken uns Ihre defekte elektronische Bienenstockwaage - wir erneuern die Elektronik und machen daraus eine moderne Hightech-Waage "} />
        </Grid>
        <Grid item>
          <Product img={doItYourself}
            title={"do-it-yourself Paket"}
            desc={"Beinhaltet das modbee.io scale-kit inklusive einer Wägezelle."} />
        </Grid>
        <Grid item>
          <Product 
            img={modules} 
            title={"Ersatzteile"}
            desc={"Ersatzteile für ihre Waagen - mit Hilfe der Analyseanwendung lässt sich bestimmen, ob und welches Modul defekt ist. ACHTUNG: Wenn Sie eine Reparatur selbst durchführen, können wir leider keine Garantie mehr geben. Allerdings bekommen Sie von uns eine genaue Anleitung zur Reparatur."} />
        </Grid>   
      </Grid>
    </Container>
  );
}


export const useStyles = makeStyles((theme) => ({
  root: {
      padding: 16
  },
  
}));


export default Shop;