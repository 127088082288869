import React from 'react';
import { IconButton, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

const DialogHeaderX = (props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.header}>
            <Grid item xs={12}>
                <Grid container wrap="nowrap" alignItems="center">
                    <Grid item xs style={{ paddingRight: 8 }}>
                        <Typography variant="h6" className={classes.closeButton}>
                            {props.title}
                        </Typography>
                    </Grid>
                    {props.close ? <Grid item>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={props.close}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid> : null}
                </Grid>
            </Grid>
            {props.zweiterText ? <Grid item xs={12} style={{ paddingRight: 8 }}>
                <Typography variant="caption" >
                    {props.zweiterText}
                </Typography>
            </Grid> : null}
        </Grid>
    );
}
 
const useStyles = makeStyles((theme) => ({
    header: {
        //minHeight: 56,
        paddingLeft: 16,
        //paddingRight: 16,
        backgroundColor: theme.palette.primary.main
    },
    closeButton: {
        color: theme.palette.primary.text,
    },

}));
export default (DialogHeaderX);