import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { alpha, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

const KalibrierungsArten = (props) => {
    const classes = useStyles()
    return (
        <Grid container spacing={2} >
            <Grid item xs={6}>
                <Card className={classes.card} onClick={()=>props.handleNext(1)}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Genau
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Dauer: 36h
                        </Typography>
                        <Typography variant="body2">
                            ++ Genaueres Gewicht
                            <br />
                            -- Kalibrierung dauert länger
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card  className={classes.card} onClick={()=>props.handleNext(2)}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Schnell
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Dauer: 10 Minuten
                        </Typography>
                        <Typography variant="body2">
                            ++ Waage ist sofort einsatzbereit
                            <br />
                            -- Gewicht leicht temperaturabhängig
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        '&:hover': {
            backgroundColor: alpha(grey[50], 0.3),
            cursor: "pointer"
        },
    },
}));
export default KalibrierungsArten