import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import const_vars from "../../../Static/const_vars";


const Schritt2 = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
      //props.next()
       console.log('Schritt2: ',props.waage.status)
       if (props.waage.status === const_vars.GW_STATUS_WAITING_IN_CALIBRATION_MODE) {
        props.next();
       }
       // hier muss ich warten bis mir gesagt wird dass ich weiter kann (bee event)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.waage.status]); //

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                    Verbindung aufgebaut!
                    Bitte drücken Sie jetzt den RESET Knopf auf der Waage.
                </Typography>
                <Typography variant="caption" color="text.secondary" component="div">
                    (Die Waage wird nun die ersten Daten senden, das kann bis zu 15 Minuten dauern)
                </Typography>
            </Grid>
        </Grid >
    );
}

export default Schritt2