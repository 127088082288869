import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import MainAppBar from './AppBar/AppBar'
import { Button, Divider, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import MenuDrawer from './MenuDrawer/MenuDrawer';
import BeeEvents from '../Events/BeeEvents';

const Layout = (props) => {
    const [bigDrawer, setBigDrawer] = React.useState(localStorage.getItem('bigDrawer') === 'false' ? false : true)
    const loggedIn = useSelector(state => state.auth.loggedIn);
    const { t } = useTranslation();
    const cookie = localStorage.getItem('beeCookie') === 'true' ? true : false;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();
    const saveCookie = () => {
        localStorage.setItem('beeCookie', true)
        closeSnackbar()
    }
    const toggleBigDrawer = () => {
        console.log(bigDrawer)
        localStorage.setItem('bigDrawer', !bigDrawer)
        setBigDrawer(!bigDrawer)
    }
    const action = () => (
        <Button onClick={saveCookie} variant="text" size='small' style={{ color: '#000' }}>{t('main.Verstanden')}</Button>
    );


    useEffect(() => {
        if (!cookie) enqueueSnackbar(t('main.CookieMeldung'), { variant: 'info', persist: true, action });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) //

    return (
        <React.Fragment>
            <CssBaseline />
            <MainAppBar toggleBigDrawer={toggleBigDrawer}/>
            <Grid container color="inherit" direction="row" >
                {loggedIn ? <React.Fragment>
                    <Grid item className={classes.drawer} style={{ height: '100%', overflowY: 'auto' }}>
                        <MenuDrawer bigDrawer={bigDrawer} toggleBigDrawer={toggleBigDrawer} />
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                </React.Fragment> : null}
                <Grid item xs style={{ height: '100%', overflowY: 'auto' }} >
                    {props.children}
                </Grid>
            </Grid>
            <BeeEvents />

        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    drawer: {
        backgroundColor: theme.palette.primary.main,
    },
    scrolled: {
        backgroundColor: '#EFEFEF', //theme.palette.primary.main,
        transition: 'background-color 200ms linear',
    },
    top: {
        backgroundColor: 'transparent',
        transition: 'background-color 200ms linear',
    },
    logo: {
        paddingTop: 3,
        width: 36,
    },
}));

export default Layout;