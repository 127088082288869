import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";


const initialState = {
  token:  localStorage.getItem('token') != null ? localStorage.getItem('token') : null,
  userId:  localStorage.getItem('userId') != null ? localStorage.getItem('userId') : null,
  email: "",
  password: "", //darf nicht gespeichert werden - nur temporär!
  remember: true,
  error: null,
  loading: false,
  loggedIn: localStorage.getItem('loggedIn') === 'true' ? true : false,
  authRedirectPath: '/'
};

const authStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
  console.log("authSuccess - token: ", action.idToken);

  return updateObject( state, {
    token: action.idToken,
    userId: action.userId,
    error: null,
    loggedIn: true,
    loading: false
  } );
};


const authRefreshSuccess = (state, action) => {
  console.log("authRefreshSuccess - token: ", action.idToken);

  return updateObject( state, {
    token: action.idToken,
  } );
};


const authFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const authLogout = (state, action) => {
  console.log("authLogout - reducer..");
  return updateObject(state, { 
    token: null, 
    userId: null,
    loggedIn:false });
};

const authChangeMail = (state, action) => {
  return updateObject( state, {
    email: action.email
  });
};

// const authChangePassword = (state, action) => {
//   return updateObject( state, {
//     password: action.password
//   });
// };

const authChangeRemember = (state, action) => {
  return updateObject( state, {
    remember: action.remember
  });
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_REFRESH_SUCCESS: return authRefreshSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    case actionTypes.AUTH_CHANGE_MAIL: return authChangeMail(state, action);
    // case actionTypes.AUTH_CHANGE_PASSWORD: return authChangePassword(state, action);
    case actionTypes.AUTH_CHANGE_REMEMBER: return authChangeRemember(state, action);
    default:
      return state;
  }
};

export default reducer;