import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import makeStyles from '@mui/styles/makeStyles';
import { Badge, Button, Divider, Grid, IconButton } from '@mui/material';
import beelogo from '../../Static/beelogo.png';
import beelogow from '../../Static/beelogo_white.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useTranslation } from 'react-i18next';
import SignIn from "../../Login/SignIn";
import DialogUniversal from '../../UI/DialogUniversal/DialogUniversal';
import { useSelector } from 'react-redux';
import UserMenu from './UserMenu/UserMenu';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import ColorSelect from '../../Settings/ColorSelect';
import Cart from './Cart/Cart';
import Notifications from './Notifications/Notifications';
import MenuIcon from '@mui/icons-material/Menu';

const ElevationScroll = (props) => {
    const { children, window, setTrigger, loggedIn } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    //TODO nächste Zeile löst Error aus!
    // setTrigger(trigger ? true : false);

    if (loggedIn) return React.cloneElement(children, {
    });
    else return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,

    });
}

const MainAppBar = (props) => {
    const [trigger, setTrigger] = React.useState(false)
    const [showLogin, setShowLogin] = React.useState(false)
    const classes = useStyles();
    const { t } = useTranslation();
    let navigate = useNavigate();
    const loggedIn = useSelector(state => state.auth.loggedIn);
    const cartItems = useSelector(state => state.shop.cartItems);


    //TODO alarme online beziehen
    const alarme = 0;
    useEffect(() => {
        if (loggedIn) setShowLogin(false)
    }, [loggedIn])

    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props} loggedIn={loggedIn} setTrigger={setTrigger}>
                <AppBar color="transparent" style={{ zIndex: 1201 }}>
                    <Toolbar className={trigger || loggedIn ? classes.scrolled : classes.top}>
                        <Grid container alignItems="center">
                            <Grid item style={{ paddingRight: 16 }} onClick={props.toggleBigDrawer} className={loggedIn ? classes.imgswitch : null}>
                                <img src={beelogo} alt="" className={classNames(loggedIn ? "hider" : null, classes.logo1)} />
                                <img src={beelogow} alt="" className={classNames("shower", classes.logo2)} />
                                {/* <svg data-testid="MenuIcon" alt="" className={classNames("shower", classes.logo2)}></svg>
                                <svg data-testid="MenuIcon" alt="" className={classNames(loggedIn ? "hider" : null, classes.logo1)}></svg> */}
                            </Grid>
                            <Grid item onClick={() => navigate('/')} className={classes.hover}>
                                <Typography variant="h5">
                                    {/* <Typography variant="h5" className={classes.categoryHeaderPrimary}> */}
                                    modbee.io
                                </Typography>
                            </Grid>
                            <Grid item style={{ paddingLeft: 16 }}>
                                <ColorSelect color="appBar" name="Hintergrund" />
                            </Grid>
                            <Grid item xs />
                            {cartItems.length > 0 ? <React.Fragment>
                                <Cart />
                                <Grid item>
                                    <Typography>|</Typography>
                                </Grid> </React.Fragment> : null}
                            {loggedIn ? <React.Fragment>
                                {/* <IconButton aria-label="show new notifications" >
                                    <Badge badgeContent={alarme} color="secondary" anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}>
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton> */}
                                <Grid item>
                                    <Notifications />
                                </Grid>
                                <Grid item>
                                    <Typography>|</Typography>
                                </Grid>
                                <Grid item>
                                    <UserMenu />
                                </Grid>
                            </React.Fragment> : <React.Fragment>
                                <Grid item>
                                    <Button variant="text" size='small' style={{ color: '#000' }} onClick={() => navigate('/about')}>{t('main.About')}</Button>
                                </Grid>
                                <Grid item><Typography>|</Typography></Grid>
                                <Grid item>
                                    <Button variant="text" size='small' style={{ color: '#000' }} onClick={() => navigate('/shop')}>{t('main.Shop')}</Button>
                                </Grid>
                                <Grid item><Typography>|</Typography></Grid>
                                <Grid item>
                                    <Button variant="text" size='small' style={{ color: '#000' }} onClick={() => navigate('/refurbed')}>{t('main.Refurbed')}</Button>
                                </Grid>
                                <Grid item><Typography>|</Typography></Grid>
                                <Grid item>
                                    <Button variant="text" size='small' style={{ color: '#000' }} onClick={() => navigate('/impressum')}>{t('main.Impressum')}</Button>
                                </Grid>
                                <Grid item><Typography>|</Typography></Grid>
                                <Grid item>
                                    <Button onClick={() => setShowLogin(true)} variant="text" size='small' style={{ color: '#000' }}>{t('login.login')}</Button>
                                </Grid>
                                <Grid item xs={12}><Divider style={{ backgroundColor: '#aaa' }} /></Grid>
                            </React.Fragment>}
                        </Grid>
                    </Toolbar>
                </AppBar>
            </ElevationScroll >
            <Toolbar />

            <DialogUniversal open={showLogin} close={() => setShowLogin(false)} maxWidth={'xs'}>
                <SignIn />
            </DialogUniversal>
        </React.Fragment >
    );
}

const useStyles = makeStyles(theme => ({
    scrolled: {
        backgroundColor: theme.palette.colors.appBar,
        transition: 'background-color 200ms linear',
    },
    top: {
        backgroundColor: 'transparent',
        transition: 'background-color 200ms linear',
    },
    logo1: {
        paddingTop: 3,
        width: 36,
    },
    logo2: {
        paddingTop: 3,
        width: 36,
        display: "none"
    },
    imgswitch: {
        '&:hover': {
            cursor: 'pointer',
            "& .hider": {
                display: "none"
            },
            "& .shower": {
                display: "inline"
            }
        },
    },
    hover: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

export default MainAppBar;