
export const MENU_DASH = 0;
export const MENU_SCALE = 1;
export const MENU_SCALES = 2;
export const MENU_APIARIES = 3;
export const MENU_STATISTIC = 4;
export const MENU_EVENTS = 5;
export const MENU_SETTINGS = 6;
export const MENU_ALARMS = 7;
export const MENU_FIRMWARE = 8;
export const MENU_WARTUNG = 9;
export const MENU_CALIBRATION = 10;
export const MENU_SHOP = 11;
export const MENU_HIVES = 12;
export const MENU_IMPRESSUM = 13;
export const MENU_REFURBED = 14;


export const ICON_TEMPERATURE = 'ICON_TEMPERATURE';
export const ICON_HUMIDITY = 'ICON_HUMIDITY';
export const ICON_WEIGHT = 'ICON_WEIGHT';
export const ICON_PRESSURE = 'ICON_PRESSURE';
export const ICON_VOLTAGE= 'ICON_VOLTAGE';
export const ICON_BACK= 'ICON_BACK';

export const EVENT_IGNORE = 99;
export const EVENT_BODY_ADD = 11;
export const EVENT_SWARM = 12;
export const EVENT_BODY_REMOVE = 13;
export const EVENT_CONTROL = 14;
export const EVENT_FRAMES_ADD = 15;
export const EVENT_FRAMES_REMOVE = 16;
export const EVENT_HONEY = 17;
export const EVENT_VARROA = 18;
export const EVENT_ROBBING = 19;
export const EVENT_WRONG_MEASUREMENT = 20;

const Constants =  {
    DATENABFRAGE :  0 ,
    ANFORDERUNG_DEBUG_INFORMATIONEN: 1,
    ANFORDERUNG_RESET: 2,
    BEFEHL_XY: 3,
    DEBUG_MELDUNG: 4,
    FIRMWARE_ANFRAGE: 5,
    FIRMWARE_INFOS: 6,
    ACKNOWLEDGE: 7,
    PAKET_FEHLER: 8,
    KEINE_DATEN: 9,
    GET_FIRMWARE_VERSION: 10,
    GET_BOOTLOADER_VERSION: 11,
    GET_CHARGING_STATE: 12,
    GET_CABLE_ID: 13,	//oder besser CHARGIN_BIKE?
    GET_CURRENT_STATE_OF_CHARGE: 14, //[0.0 - 100%]
    GET_CHARGING_VOLTAGE: 15, // [0.0, 70.0]V
    GET_CHARGING_CURRENT: 16, // [0, 10000]mA
    GET_CHARGING_REPORT: 17, //report from last charge
    ACTION_NEW_BIKE_CONNECTED: 18,
    ACTION_CHARGE_FINISHED: 19,
    GET_LOCK_STATUS: 20,
    ACTION_SET_LOCK_STATUS: 21,
    ACTION_SOFT_RESTART_CHARGER: 22,
    ACTION_GET_LOG_FILE: 23, //date_range, error_level
    ACTION_CLEAR_LOG_FILE: 24,
    GET_CHARGER_UI_LANGUAGE: 25,
    HW_ERROR_OCCURED: 26,
    GET_CHARGING_STATS: 27, //aktuelle Ladedaten
    SET_CURRENT_TIME: 28,
    GET_CURRENT_TIME: 29,
    UPLOAD_FW_TO_BOARD: 30,
    DATENPAKET: 31,

    BROADCAST_CONFIG_NET_ID: 32,
    BROADCAST_SET_NET_ID: 33,
    DEV_CONF_SET: 34,
    FILE_REQUEST: 35,
    ACTION_BIKE_DISCONNECTED: 36,


    //Firmware-Updates:
    //    FIRMWARE_HEADER: 8,
    //    FIRMWARE_DATA: 9,
    //    FIRMWARE_HEADER_CHARGER: 12,
    //    FIRMWARE_DATA_CHARGER: 13,
    FIRMWARE_HEADER: 200,
    FIRMWARE_DATA: 201,
    FIRMWARE_HEADER_CHARGER: 202,
    FIRMWARE_DATA_CHARGER: 203,
    DEVICE_ID: 204,
    UART_DATA: 205,
    RSSI: 206,
    IMSI: 207,
    UPDATE_FIRMWARE: 208,
    SCALE_DATA: 209,
    FIRMWARE_VERSION: 210,
    INSTALLING_FW: 211,
    SCALE_DATA_RAW: 212,
    KALIBRIERUNGS_BEFEHL: 213,
    MISC_DATA: 214,



    //Für Kommunikation mit Linux-Client (lokales debuggen)
    FIRMWARE_INFOS_LINUX: 240,
    MODEM_STEUERUNG: 241,

    /* 
    APP - Kommunikation! (React - Node)
    */

    GW_STATUS_UPDATE: 300,
    CHARGER_FW_INSTALLING: 301,
    CREATE_ACCESS_CODE: 302,
    TEST_OPEN_VPN: 303,
    CHARGER_STATUS_UPDATE: 304,
    CHARGER_DESIRED_STATUS_UPDATE: 305,
    SCALE_STATUS_UPDATE: 306,

    /*
        CHARGER STATUS
    */
   CHARGER_STATUS_IDLE: 0,
   CHARGER_STATUS_UNKNOWN: 501,
   CHARGER_STATUS_UPDATE_FW: 502,
   CHARGER_STATUS_INSTALL_FW: 503,
   CHARGER_STATUS_NOT_RESPONDING: 504,
   CHARGER_STATUS_GET_FW_VERSION: 505,
   CHARGER_STATUS_DISABLED: 506,

   CHARGER_STATUS_CHARGE_FINISHED: 530,
   CHARGER_STATUS_CREATING_CHARGE_REPORT: 531,
   CHARGER_STATUS_BIKE_DISCONNECTED: 532,



    /*
        Gateway STATUS
    */

   GW_STATUS_IDLE: 1,
   GW_STATUS_SHOULD_DOWNLOAD_FIRMWARE: 1000,
   GW_STATUS_HEADER_DOWNLOAD_FIRMWARE: 1001,
   GW_STATUS_IS_DOWNLOADING_FIRMWARE: 1002,
   GW_STATUS_FINISHED_DOWNLOADING_FIRMWARE: 1003,
   GW_STATUS_INSTALLING_DOWNLOADED_FIRMWARE: 1004,
   GW_STATUS_SHOULD_PAUSE_DOWNLOAD_FIRMWARE: 1005,
   GW_STATUS_DOWNLOAD_FIRMWARE_PAUSED: 1006,
   GW_STATUS_SHOULD_CONTINUE_DOWNLOAD_FIRMWARE: 1007,
   GW_STATUS_DOWNLOAD_FIRMWARE_ERROR: 1008,
   GW_STATUS_SHOULD_CANCEL_DOWNLOAD_FIRMWARE: 1009,

   GW_STATUS_SHOULD_START_CALIBRATION: 1010,
   GW_STATUS_SHOULD_CANCEL_CALIBRATION: 1011,
   GW_STATUS_SHOULD_STOP_CALIBRATION: 1012,   
   GW_STATUS_SHOULD_SEND_CALIBRATION_VALUES: 1013,
   GW_STATUS_IS_CALIBRATING: 1014,
   GW_STATUS_WAITING_IN_CALIBRATION_MODE: 1015,
   GW_STATUS_SHOULD_IDLE_CALIBRATION: 1016,
   GW_STATUS_IS_SENDING_VALUES: 1017,
}
export default Constants