import React from 'react';
import { Backdrop, Divider, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'
import {  useDispatch } from "react-redux";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import * as actions from '../../../store/actions/index';


const UserMenu = (props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    let navigate = useNavigate();
    const dispatch = useDispatch();


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Backdrop open={open} />
            <IconButton pos={"bottom-start"} onClick={handleClick}>
                <AccountCircleIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                // PaperProps={{
                //     elevation: 0,
                //     sx: {
                //         overflow: 'visible',
                //         filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                //         mt: 1.5,
                //         '& .MuiAvatar-root': {
                //             width: 32,
                //             height: 32,
                //             ml: -0.5,
                //             mr: 1,
                //         },
                //         '&:before': {
                //             content: '""',
                //             display: 'block',
                //             position: 'absolute',
                //             top: 0,
                //             right: 14,
                //             width: 10,
                //             height: 10,
                //             bgcolor: theme.palette.primary.menuleiste,
                //             transform: 'translateY(-50%) rotate(45deg)',
                //             zIndex: 0,
                //         },
                //     },
                // }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >


                <MenuItem onClick={() => navigate('/settings')}>
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    {t('main.Einstellungen')}
                </MenuItem>

                <Divider />
                <MenuItem onClick={() => dispatch(actions.logout())}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    {t('login.Abmelden')}
                </MenuItem>
            </Menu>
        </React.Fragment >

    );
}

export default UserMenu;