import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


import translationEN from '../src/public/locales/en/translation.json';
import translationDE from '../src/public/locales/de/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  }
};

const options = {
  order: ['navigator']
}

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: options,
    resources,
    debug: true,
    //lng: 'en',
    fallbackLng: 'en',
    whitelist: ['en', 'de'], //wenn wir dann zB deutsch auch wollen
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: { 
      useSuspense: false //   <---- this will do the magic
    }
    // backend: {
    //   loadPath: '/public/locales/{{lng}}/{{ns}}.json',
    // },
  });
  //i18n.changeLanguage()
export default i18n;