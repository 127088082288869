import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'typeface-roboto';
import FrontPage from './LandingPage/FrontPage/FrontPage';
import Dashboard from './Dashboard/Dashboard';
import Statistic from './Statistic/Statistic';
import Calibration from './Calibration/Calibration';
import Apiaries from './Apiaries/Apiaries';
import Settings from './Settings/Settings';
import Scales from './Scales/Scales';
import Alarms from './Alarms/Alarms';
import Firmware from './Firmware/Firmware';
import ClientsComp from './Clients/ClientsComp';
import PrivateRoute from './UI/privateRoute';
import Shop from './Shop/Shop';
import Impressum from './Impressum/Impressum';
import Refurbed from './Refurbed/Refurbed';
import BeeEvents from './Events/BeeEvents';
import Waagen from './Waagen/Waagen';
import Hives from './Hives/Hives';

const Routing = (props) => {

  const loggedIn = useSelector(state => state.auth.loggedIn);


  return (
    loggedIn ?
      <Routes>
        <Route exact path="/" element={<PrivateRoute loggedIn={loggedIn}><Dashboard /></PrivateRoute>} />
        <Route exact path="/statistic/:scale_id" element={<PrivateRoute loggedIn={loggedIn}><Statistic /></PrivateRoute>} />
        <Route exact path="/statistic" element={<PrivateRoute loggedIn={loggedIn}><Statistic /></PrivateRoute>} />
        <Route exact path="/calibration/:scale_id" element={<PrivateRoute loggedIn={loggedIn}><Calibration /></PrivateRoute>} />
        <Route exact path="/apiaries" element={<PrivateRoute loggedIn={loggedIn}><Apiaries /></PrivateRoute>} />
        <Route exact path="/settings" element={<PrivateRoute loggedIn={loggedIn}><Settings /></PrivateRoute>} />
        <Route exact path="/scale" element={<PrivateRoute loggedIn={loggedIn}><Statistic /></PrivateRoute>} />
        <Route exact path="/scales" element={<PrivateRoute loggedIn={loggedIn}><Waagen/></PrivateRoute>} />
        <Route exact path="/alarms" element={<PrivateRoute loggedIn={loggedIn}><Alarms /></PrivateRoute>} />
        <Route exact path="/firmware" element={<PrivateRoute loggedIn={loggedIn}><Firmware /></PrivateRoute>} />
        <Route exact path="/wartung" element={<PrivateRoute loggedIn={loggedIn}><ClientsComp /></PrivateRoute>} />
        <Route exact path="/events" element={<PrivateRoute loggedIn={loggedIn}><BeeEvents /></PrivateRoute>} />
        <Route exact path="/shop" element={<PrivateRoute loggedIn={loggedIn}><Shop/></PrivateRoute>} />
        <Route exact path="/hives" element={<PrivateRoute loggedIn={loggedIn}><Hives/></PrivateRoute>} />
        <Route path="*" element={<Dashboard />} />
      </Routes > :
      <Routes>
        <Route exact path="/shop" element={<Shop />} />
        <Route exact path="/impressum" element={<Impressum />} />
        <Route exact path="/refurbed" element={<Refurbed />} />
        <Route path="/" element={<FrontPage />} />
        <Route path="*" element={<FrontPage />} />
      </Routes>
  );
}

export default Routing;
