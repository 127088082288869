import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import mainReducer from "./reducers/main";
import thunk from "redux-thunk";
import authReducer from "./reducers/auth";
import scaleReducer from "./reducers/scale";
import settingsReducer from "./reducers/settings";
import serviceWorkerReducer from './reducers/serviceWorker';
import shopReducer from './reducers/shop';
import standortReducer from './reducers/standort'
import hivesReducer from './reducers/hives'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  main: mainReducer,
  auth: authReducer,
  scale: scaleReducer,
  settings: settingsReducer,
  serviceWorker: serviceWorkerReducer,
  shop: shopReducer,
  standort: standortReducer,
  hives: hivesReducer,
});
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));


export default store;