import React from 'react';
import { useDispatch, useSelector} from "react-redux";
import 'moment-timezone';
import 'moment/locale/de';
import makeStyles from '@mui/styles/makeStyles';
import {  Paper, TablePagination, Grid } from '@mui/material';
import { useEffect } from 'react';
import * as actions_scales from "../store/actions/scales";
import * as mainactions from "../store/actions/main";
import ClientComp from '../Clients/ClientComp';
import { useTranslation } from 'react-i18next';
import axios from '../axios-modbee';
import const_vars from "../Static/const_vars";
import Button from '@mui/material/Button';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    margin: 16,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ApiExamples = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accounts = useSelector(state => state.main.accounts);
  const token = useSelector(state => state.auth.token);
  const [activeStep, setActiveStep] = React.useState(0);

  const scales = useSelector(state => state.scale.scales);
  const { t } = useTranslation();
  
  console.log(scales)


  const deleteCalibration = (id_calibration) => {
      const config = {
          headers: {
              'Authorization': '' + token
          }
      };

      // console.log("deleteCalibration token: ", token);
      //schreibt bei typ 99 hinein im Moment und wenn TYP 99 ist, dann liefert der Server das nicht mehr zurück
      axios.delete('/calibration/' + id_calibration, config)
          .then(res => {
              console.log("deleteCalibration result: ", res.data);
          }).catch(err => {
              console.error("err: " + err);
          });
  }

  const editCalibration = (id_calibration) => {
      const config = {
          headers: {
              'Authorization': '' + token
          }
      };

      const calibration = {
          'typ': 2,
          'step': 2,
          'weight': 5000 //5kg - in Gramm speichern?! obwohl es im moment float ist.. 
      };

      console.log("edit calibration: ", calibration);
      axios.put('/calibration/' + id_calibration, calibration, config)
          .then(res => {
              console.log("edit calibration result: ", res.data);
          }).catch(err => {
              console.error("err: " + err);
          });
  }


  
  // sendCustomDataScale(1150, const_vars.KALIBRIERUNGS_BEFEHL, 1, 60, 5, 1000, 10);
  const sendScaleUpdate = async (id_scales, name, kalibriert) => {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ''+token
    }        
    const config = {
      daten: {
        "name": name,
        "kalibriert": kalibriert
      },
      crossdomain: true
    };    
    
    axios.post( '/scale/edit/'+id_scales, config, {
    // axios.put( '/station/custom/'+res_id, config, { //leshan
          headers: headers
        })
        .then( res => {            
            console.log("got edit scale answer: ", res);
        })
        .catch( err => {
            console.log("got edit scale error: ", err);
        } );
  }

  // sendCustomDataScale(1150, const_vars.KALIBRIERUNGS_BEFEHL, 1, 60, 5, 1000, 10);
  const sendCustomDataScale = async (id_scales, res_id, cmd, duration, count, weight, interval) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ''+token
    }        
    const config = {
      data: {
        "befehl": res_id,
        "cmd": cmd,
        "id_scales": id_scales,
        "duration": duration,
        "count": count,
        "weight": weight,
        "interval": interval
      },
      crossdomain: true
    };    
    
    axios.put( '/scale/custom/'+id_scales, config, {
    // axios.put( '/station/custom/'+res_id, config, { //leshan
          headers: headers
        })
        .then( res => {            
            console.log("got sendCustomWriteToGateway answer: ", res);
        })
        .catch( err => {
            console.log("got sendChargerFw error: ", err);
        } );
  }

  const startCalibration = () => {
    // sendCustomDataScale(id_scales, res_id, cmd, duration, count, weight, interval);
    sendCustomDataScale(1151, 
      const_vars.KALIBRIERUNGS_BEFEHL, 
      const_vars.GW_STATUS_SHOULD_START_CALIBRATION, 
       0, 0, 0, 0);
  }
  const stopCalibration = () => {
    // sendCustomDataScale(id_scales, res_id, cmd, duration, count, weight, interval);
    sendCustomDataScale(1151, 
      const_vars.KALIBRIERUNGS_BEFEHL, 
      const_vars.GW_STATUS_SHOULD_STOP_CALIBRATION, 
      60, //duration, 60 Sekunden
      5, //count, Mittelwert aus 5
      0, //weight, 0kg
      3); //interval); alle 3 Sekunden
    
  }

  const startValues = () => {
    // sendCustomDataScale(id_scales, res_id, cmd, duration, count, weight, interval);
    sendCustomDataScale(1151, 
      const_vars.KALIBRIERUNGS_BEFEHL, 
      const_vars.GW_STATUS_SHOULD_SEND_CALIBRATION_VALUES, 
      60, //duration, 60 Sekunden
      5, //count, Mittelwert aus 5
      0, //weight, 0kg
      3); //interval); alle 3 Sekunden
  }
  
  const scaleShouldWait = () => {
    // sendCustomDataScale(id_scales, res_id, cmd, duration, count, weight, interval);
    sendCustomDataScale(1151, 
      const_vars.KALIBRIERUNGS_BEFEHL, 
      const_vars.GW_STATUS_SHOULD_IDLE_CALIBRATION, 
      0, 0, 0, 0);    
  }

  return (
    <Grid container>
      <Grid item>
          <Button color="inherit" onClick={startCalibration} >
              waage 1151 start calibration
          </Button>
      </Grid>
      <Grid item>
          <Button color="inherit" onClick={stopCalibration} >
              waage 1151 stop calibration
          </Button>
      </Grid>
      <Grid item>
          <Button color="inherit" onClick={startValues} >
              waage 1151 send calibration value
          </Button>
      </Grid>
      <Grid item>
          <Button color="inherit" onClick={scaleShouldWait} >
              waage 1151 warten auf befehle
          </Button>
      </Grid>

      
      <br />
      <br />
    </Grid>
  );  
}


export default ApiExamples;