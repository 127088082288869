import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const fakeStandorte = [
  {id_standort: 1, ort: 'Alm Schneeberg', anzahl: 8, latlong: "47.36569133839066, 13.100383623482427", bemerkung: [{timestamp: '2022-03-25T21:50:18.000Z', bemerkung: 'sturmschäden beseitigt'}, {timestamp: '2022-01-25T21:50:18.000Z', bemerkung: 'Winterkontrolle, hat alles gepasst'}]},
  {id_standort: 2, ort: 'Daheim', anzahl: 3, latlong: "47.351595135301764, 13.19423472368916", bemerkung: [{timestamp: '2022-03-28T15:50:18.000Z', bemerkung: 'Bierzeit'}]}
]


const initialState = {
    standorte: fakeStandorte,
    error: null
};

const standorteSuccess = (state, action) => {
    console.log("standorteSuccess: ", action.standorte);
   return updateObject( state, {
    standorte: action.standorte,
     error: null,
   } );
 };
 
 const standorteFail = (state, action) => {
   return updateObject( state, {
     error: action.error,
   });
 };

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STANDORT_SUCCESS: return standorteSuccess(state, action);
        case actionTypes.STANDORT_FAIL: return standorteFail(state, action);
        default: return state;
    }
};


export default reducer;