
export const getChartConfig = () => {
    
    const icon1 = {
        'width': 500,
        'height': 600,
        'path': 'M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z'
    }
  
    return {
        editable: false,
        // autosizable: true,
        // fillFrame: true,
        // frameMargins: 5, /** if we DO autosize, set the frame margins in percents of plot size */
        /** mousewheel or two-finger scroll zooms the plot */
        scrollZoom: true,
        /** double click interaction (false, 'reset', 'autosize' or 'reset+autosize') */
        doubleClick: 'reset+autosize', // | 'reset' | 'autosize' | false;
        /** new users see some hints about interactivity */
        showTips: true, //boolean;
        /** enable axis pan/zoom drag handles */
        showAxisDragHandles: true, //true,
        /** enable direct range entr at the pan/zoom drag points (drag handles must be enabled above) */
        // showAxisRangeEntryBoxes: TruckCheckOutline,
        /** link to open this plot in plotly */
        showLink: false,
        /** if we show a link, does it contain data or just link to a plotly file? */
        sendData: false,
        /** text appearing in the sendData link */
        linkText: '', 
        /** false or function adding source(s) to linkText <text> */
        showSources: false,
        /** display the mode bar (true, false, or 'hover') */
        displayModeBar: false, // | boolean;
        modeBarButtonsToAdd: [
            'hoverClosestGl2d',
          {
            name: 'color toggler',
            icon: icon1,
            click: function(gd) {
            //   var newColor = colors[Math.floor(3 * Math.random())]
            //   Plotly.restyle(gd, 'line.color', newColor)
            }
            },
        ],
        modeBarButtonsToRemove: 
            [
                'pan2d',
            'select2d',
            'lasso2d']

      }
}

export const getRangeSlider = ( from, to ) => {
    return {
      // range: [from, to],
      bgcolor: '#efefef',
      borderwidth: 1,
      bordercolor: '#ccc',
      thickness: 0.05
    }
}

export const getRangeButtons = () => {
    return {
        buttons: [
          { 
            step: 'all',
            label: 'Alles',      
          },
          {
            count: 1,
            label: '1J',
            step: 'year',
            stepmode: 'backward'
          },
          {
            count: 6,
            label: '6M',
            step: 'month',
            stepmode: 'backward'
          },
          {
            count: 3,
            label: '3M',
            step: 'month',
            stepmode: 'backward'
          },
          {
            count: 1,
            label: '1M',
            step: 'month',
            stepmode: 'backward'
          },
          {
            count: 14,
            label: '2W',
            step: 'day',
            stepmode: 'backward'
          },
          {
            count: 7,
            label: '1W',
            step: 'day',
            stepmode: 'backward'
          },
          {
            count: 3,
            label: '3T',
            step: 'day',
            stepmode: 'backward'
          },
          {
            count: 1,
            label: '1T',
            step: 'day',
            stepmode: 'backward'
          },
        ],
        yanchor: 'bottom',
        y: 1.02
      }
}