import React, { Suspense } from 'react';
import 'typeface-roboto';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import store from "./store/store";
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';
import { updateServiceworker } from './store/actions/index';
import { CssBaseline } from '@mui/material';
import './i18n';
import './index.css';
// import history from './store/history';

const onUpdate = () => {
  console.log('onUpdate in index.js')
  store.dispatch(updateServiceworker());


  setTimeout(function () {
    console.log('reloadPage - automatisch starten...')
    if ('caches' in window) {
      console.log('reloadPage - delete cache')
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        })
        window.location.reload();
      });
    } else {
      console.log('reloadPage cache not in windows...')
    }
  }, 10000);
}

const app = (
  <Suspense fallback={null}>
    <Provider store={store}>
      <BrowserRouter>
        <CssBaseline />
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
);

ReactDOM.render(app, document.getElementById('root'));
// initializeFirebase();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register(onUpdate);
registerServiceWorker(onUpdate);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
