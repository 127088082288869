import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";

import 'moment-timezone';
import 'moment/locale/de';
import {Button, Paper, Divider, Typography} from "@mui/material";

import * as actions from "../store/actions/main";
import ColorSelect from '../Settings/ColorSelect';
import * as main from "../store/reducers/main";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import * as const_vars from "../Static/const_vars";

const useStyles = makeStyles((theme) => ({
  customClass: {
    padding: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
  },
  buttonSpace: {
    margin: theme.spacing(2),
    // paddingBottom: theme.spacing(4),
  },
}));


const Settings = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setTitleAndMenu(t('main.Allgemein'), const_vars.MENU_SETTINGS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []) //

  const visible = useSelector(state => state.main.themeColors.visible);
  const themeColors = useSelector(state => state.main.themeColors);


  const toggleColorBar = () => {
    console.log("Farbenbar anzeigen!")
    themeColors["visible"] = !visible;
    dispatch(actions.setThemeColors(themeColors));
  }

  const setThemeOne = () => {
    console.log("setThemeOne")
    themeColors["visible"] = false;
    themeColors["primary"] = main.primaryStandard;
    themeColors["secondary"] = main.secondaryStandard;
    themeColors["primaryText"] = main.primaryTextStandard;
    themeColors["secondaryText"] = main.secondaryTextStandard;
    themeColors["highlight"] = main.highlightStandard;
    themeColors["appBar"] = main.appBarStandard;
    themeColors["sideBar"] = main.sideBarStandard;
    themeColors["weight"] = main.weightStandard;
    themeColors["temperature"] = main.temperatureStandard;
    themeColors["humidity"] = main.humidityStandard;
    themeColors["voltage"] = main.voltageStandard;


    localStorage.setItem("primary", main.primaryStandard);
    localStorage.setItem("secondary", main.secondaryStandard);
    localStorage.setItem("primaryText", main.primaryTextStandard);
    localStorage.setItem("secondaryText", main.secondaryTextStandard);
    localStorage.setItem("highlight", main.highlightStandard);
    localStorage.setItem("appBar", main.appBarStandard);
    localStorage.setItem("sideBar", main.sideBarStandard);
    localStorage.setItem("weight", main.weightStandard);
    localStorage.setItem("temperature", main.temperatureStandard);
    localStorage.setItem("humidity", main.humidityStandard);
    localStorage.setItem("voltage", main.voltageStandard);

    dispatch(actions.setThemeColors(themeColors));
  }

  return (
    <Paper className={classes.customClass}>
      <Typography>
        Farben      
      </Typography>
      <Divider></Divider>
      <Button variant="contained" color="primary" className={classes.buttonSpace} onClick={toggleColorBar}>
        Farben ändern
      </Button>
      <Button variant="contained" color="primary" className={classes.buttonSpace} onClick={setThemeOne}>
        Standardfarben zurücksetzen
      </Button>

      <ColorSelect color="primary" name="Primärfarbe"></ColorSelect>
      {/* <ColorSelect color="primaryText"></ColorSelect>
      <ColorSelect color="secondary"></ColorSelect>
      <ColorSelect color="secondaryText"></ColorSelect> */}
    </Paper>
  );
}

export default Settings;