import React, { Component } from 'react';
import styles from './IconContainer.module.css';
import 'moment-timezone';
import 'moment/locale/de';
import {BatteryUnknown, ImageFilterHdr, Scale, Thermometer, Water, ArrowLeft} from "mdi-material-ui";
import * as const_vars from "../../Static/const_vars";


class IconContainer extends Component {

  render() {
    switch(this.props.icon) {
      case const_vars.ICON_BACK:
        return (
          <ArrowLeft className={styles.value_icon} />
        );

      case const_vars.ICON_WEIGHT:
        return (
          <Scale className={styles.value_icon} />
        );

      case const_vars.ICON_TEMPERATURE:
        return (
          <Thermometer className={styles.value_icon} />
        );

      case const_vars.ICON_PRESSURE:
        return (
          <ImageFilterHdr className={styles.value_icon} />
        );

      case const_vars.ICON_HUMIDITY:
        return (
          <Water className={styles.value_icon} />
        );

      case const_vars.ICON_VOLTAGE:
        return (
          <BatteryUnknown className={styles.value_icon} />
        );

      default:
        return (
          <Thermometer className={styles.value_icon} />
        );
    }
  }
}


// export default IconContainer;
export default IconContainer;
