import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import 'moment-timezone';
import 'moment/locale/de';
import {Button, Paper, Divider, Grid} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import IconContainer from "../components/iconContainer/IconContainer";
import * as const_vars from "../Static/const_vars";
import Plot from "react-plotly.js";
import moment from "moment";
import * as settings from "./ChartSettings";
import StatisticScale from "./StatisticScale";
import { pointer } from 'd3';
import db from '../store/db';
import * as actions from "../store/actions/main";
import {isMobile} from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  customClass: {
    padding: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    cursor: pointer
  },
}));

const Statistic = (props) => {

  const { t } = useTranslation();
  let params = useParams();
  useEffect(() => {
    dispatch(actions.setTitleAndMenu(t('main.Statistik'), const_vars.MENU_STATISTIC));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) //


  const classes = useStyles();
  const dispatch = useDispatch();
  const visible = useSelector(state => state.main.themeColors.visible);
  const token = useSelector(state => state.auth.token);

  const primary = useSelector(state => state.main.themeColors.primary);
  const secondary = useSelector(state => state.main.themeColors.secondary);
  const primaryText = useSelector(state => state.main.themeColors.primaryText);
  const secondaryText = useSelector(state => state.main.themeColors.secondaryText);
  const highlight = useSelector(state => state.main.themeColors.highlight);
  const appBar = useSelector(state => state.main.themeColors.appBar);
  const sideBar = useSelector(state => state.main.themeColors.sideBar);
  let navigate = useNavigate();
  // const ertrag = useSelector(state => state.main.themeColors.ertrag);
  const weight = useSelector(state => state.main.themeColors.weight);
  const temperature = useSelector(state => state.main.themeColors.temperature);

  const voltage = useSelector(state => state.main.themeColors.voltage);
  const humidity = useSelector(state => state.main.themeColors.humidity);

  const scale = useSelector(state => state.main.currentScale);
  const id_scales = params.scale_id;

  const timeFormat = 'YYYY-MM-DD HH:mm:ss';
  // const timeFormat = 'YYYY-MM-DD HH:mm:ss';
  const scaledata = useSelector(state => state.main.scaledata);
  const [statScales, setStatScales] = useState([]);
  const [wantedScales, setWantedScales] = useState([]);
  // const scales = useSelector(state => state.main.scales);
  const scales = useSelector(state => state.scale.scales);
  const plotlyRef = useRef(null);
  const [scaleRange, setScaleRange] = useState({
      start: moment().subtract(1, 'week').format(timeFormat),
      end: moment().add(2, 'hour').format(timeFormat),
      weightStart: 0,
      weightEnd: 100,
      ertragStart: 0,
      ertragEnd: 10 });

    // console.log("scale: ", scale)

  let dragLayer = document.getElementsByClassName("nsewdrag")[0];

  useEffect( () => {    
    if (id_scales != undefined) {
      addStatisticScale(id_scales);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_scales]);

  const handleBackClick = () => {
    dispatch(actions.setTitleAndMenu("Dashboard", const_vars.MENU_DASH));
    navigate('/')
  };

  var t1 = Date.now(); 
  var timestamp1 = t1 - (1000 + 1) * 1000;
  // console.log("timestamp1: ", timestamp1);

  const addStatisticScale = async (id_scales) => {
    console.log("addStatisticScale: ", id_scales);
    let add = true;
    statScales.forEach(function (item, index) {
      if (item.id_scales == id_scales) {
        add = false;
        console.log("wanted to add same id to scales: ", item);
      }
    });

    if (add) {      
      let temp_scales;
      // console.log("addStatisticScale add.. scales = ", scales);
      if (scales != undefined) {
        if (scales.length < 1) {
          try {        
            temp_scales = await db.scales
            // .where('age').above(75)
              .toArray();
            // const myScales = await db.getAllFromIndex('scales', 'id_scales');
            // const myScales = await db.getAll('scales');
            console.log("scales from db: ",temp_scales);  
            // dispatch(actions_scale.fetchScalesSuccess(myScales));
          } catch (error) {
            console.log("error getting scales from DB: ",error)
          }
        } else {
          temp_scales = [...scales];
        };   

        console.log("scales from db: ",temp_scales);  
        temp_scales.forEach(async function (item, index) {
          if (item.id_scales == id_scales) {          
            // const newScale = new ScaleClass(item);
            // console.log("add scale to statistic page: "+id_scales);
            const now = Date.now()+1000*24*60*60*9; 
            const week = Date.now()-1000*24*60*60*9; 

            dispatch(actions.setTitle(item.name));

            // console.time('db-get');
            let scaleData = await db.scaledata
              // .where("id_scale")
              // .belowOrEqual(scale.id_scales)
              // .equals(+id_scales)
              .where('[id_scale+signal_time]')
              .between([+id_scales, week], [+id_scales, now])
              // .between ([some_item_id, -Infinity], [some_item_id, Dexie.maxKey])
              // .orderBy("id_weight").reverse()
              // .limit(1)
              .toArray()
              .catch (async function (err) {
                console.log("error getting scaleData from db - reopen: ",err);
                await db.open();
                scaleData = [];
              });;
            item.scaledata = scaleData;
            
            // console.timeEnd('db-get');
            // console.time('datamapping');
            // let lastHum = 0;
            // var offset = moment().utcOffset();

            // let ertragsArray = [];
            // let ertragsArrayPos = [];
            // let ertragsArrayNeg = [];
            // let ertragsArrayDay = [];
            // let ertragsArrayPosDay = [];
            // let ertragsArrayNegDay = [];

            // let dayArray = [];

            // let ticksDayArray = [];
            // let ticksWeekArray = [];

            // let day = -1;
            // let hour = -1;
            // let lastWeightValue = 0;
            // let lastValue = 0;
            // let lastValueHour = 0;
            // let humDayMin = 0;
            // let humDayMax= 0;
            // let humDayAvg = 0;
            // let tempDayMin = 0;
            // let tempDayMax= 0;
            // let tempDayAvg = 0;
            // let weightDayMin = 0;
            // let weightDayMax= 0;
            // let weightDayAvg = 0;
            // let dayCount = 0;
            // let lastDaySignalTime = 0;
            // let lastDayTimeString = '';
            // let last_id_weight = 0;
            // let last_time = 0;

            // let currentEvent = {
            //   id_start: 0,
            //   id_before: 0,
            //   id_end: 0,
            //   id_after: 0,
            //   weight_before: 0,
            //   weight_start: 0,
            //   weight_end: 0,
            //   weight_after: 0,
            //   time_before: 0,
            //   time_start: 0,
            //   time_end: 0,
            //   time_after: 0,
            //   time_string_start: '',
            //   time_string_end: '',
            //   reset_ertrag: false
            // }

            // let startEvent = false;
            // let endEvent = false;
            // let diff = 0.0;
            // let ertragSum = 0.0;

            // let lastTime = 0;
            // if (scaleData != undefined) {                
            //   console.log("scaledata: ", scaleData[scaleData.length-1], " offset: "+offset);
            //   item.scaledata = scaleData.map((dataPoint, sc_index) => { 
            //     startEvent = false;
            //     endEvent = false;

            //     if (sc_index == 0) {
            //       diff = 0;
            //     } else {
            //       diff = dataPoint.weight - lastWeightValue;
            //     }

            //     /* *********************
            //     Ereignisse suchen:
            //     *************************/
            //     if (sc_index > 0) { //erstes Item wird übersprungen                                    
            //       //Gewicht um mehr als 1kg geändert
            //       if (currentEvent.id_start == 0) {
            //         if (Math.abs(diff) > 1) {
            //           //Aktuell kein Event im Gange
            //           //Event nur starten, falls die Zu- oder Abnahme nicht erklärt werden kann!
            //           //Abnahme -> falls innerhalb einer Stunde, sofort Event starten
                      
            //           if (diff < 0) { //Abnahme
            //             //Falls größerer Zeitpunkt keine Daten - auch Event starten
            //             startEvent = true;
                        
            //           } else {  //Zunahme Gewicht

            //             //Bei Zunahme kann es bei extremen Honigzunahmen große Sprünge geben
            //             //allerdings auch nicht innerhalb von kurzer Zeit, sondern stetig über den
            //             //Tag verteilt!

            //             //Es kann aber auch Regen sein!
            //             startEvent = true;
            //           }


            //           if (startEvent) { //Event starten!
            //             currentEvent.id_before = last_id_weight;
            //             currentEvent.id_start = dataPoint.id_weight;
            //             currentEvent.weight_before = lastWeightValue;
            //             currentEvent.weight_start = dataPoint.weight ;
            //             currentEvent.time_before = last_time;
            //             currentEvent.time_start = dataPoint.signal_time;

            //             // console.log("start event at point: ", dataPoint);
            //             // console.log("start event: ", dataPoint.date);
            //             // console.log("weight event start: ", dataPoint.weight);
            //             // console.log("weight before: ", lastWeightValue);

            //           }
            //         }
            //       } else {
            //         //Es läuft ein Event, schauen ob wir das Event beenden müssen
            //         if (currentEvent.time_start+1000*60*60*1.5 < dataPoint.signal_time) {
            //           //Nach einer Stunde automatisch Abbruch!
                      
            //           endEvent = true;
            //         } else if (Math.abs(diff) > 1) {
            //           endEvent = true;
            //         }


            //         if (endEvent) { //Event abschließen und speichern!
            //           // console.log("end event at point: ", dataPoint);

            //           currentEvent.id_end = last_id_weight;
            //           currentEvent.weight_end = lastWeightValue;    
            //           currentEvent.time_end = last_time; 

            //           currentEvent.id_after = dataPoint.id_weight;
            //           currentEvent.weight_after = dataPoint.weight;
            //           currentEvent.time_after = dataPoint.signal_time; 
            //           // console.log("end event: ", dataPoint.date);
            //           // console.log("weight event end: ", lastWeightValue);
            //           // console.log("weight after: ", dataPoint.weight);
            //           //TODO - Event in Datenbank und Online eintragen!

            //           //Werte für Ertragsberechnung zurücksetzen!
            //           lastValue = dataPoint.weight;      
            //           lastValueHour = dataPoint.weight;   

            //           lastWeightValue = dataPoint.weight;  

            //           currentEvent.id_end = 0; 
            //           currentEvent.id_start = 0; 
            //           diff = 0;
            //         }
            //       }           
            //     }

            //     if (currentEvent.id_start !== 0) {
            //       dataPoint.ertrag = 0; //Kein Ertrag falls gerade EVENT läuft!
            //       // ertragSum = 0;
            //       dataPoint.ertrag = ertragSum;
            //     } else {
            //       ertragSum += diff;
            //       dataPoint.ertrag = ertragSum; //Kein Ertrag falls gerade EVENT läuft!                
            //     }
                
            //     last_time = dataPoint.signal_time; 
            //     last_id_weight = dataPoint.id_weight;
            //     lastWeightValue = dataPoint.weight;

            //     if (lastTime != 0) {
            //       //Falls 1 Tage keine Werte, dann autoamtisch GAP erzeugen
            //       if (lastTime < moment(dataPoint.time_string).valueOf()-1000*60*60*24*1) {
            //         // dataPoint.weight = null;
            //         // dataPoint.humidity = null;
            //         // dataPoint.temperature = null;
            //         // lastValueHour = 0;
            //         // lastHum = null;
            //         // dataPoint.humidity = null;
            //       }
            //     }
            //     lastTime = moment(dataPoint.time_string).valueOf();
                
            //     if (moment(dataPoint.time_string).day() != day) {
            //       day = moment(dataPoint.time_string).day();

            //       // console.log("Neuer tag: ", moment(dataPoint.time_string));
            //       if (lastValue != 9999) {                    
            //         humDayAvg = humDayAvg / dayCount;
            //         tempDayAvg = tempDayAvg / dayCount;
            //         weightDayAvg = weightDayAvg / dayCount;

            //         //TODO eventuell halben Tag dazu rechnen?! für Darstellung!
            //         const ertragsItem = {
            //           ertrag: (currentEvent.id_start !== 0) ? 0 : (dataPoint.weight -lastValue),
            //           time_string: lastDayTimeString, //dataPoint.time_string,
            //           signal_time: lastDaySignalTime, //dataPoint.signal_time,
            //           humAvg: humDayAvg,
            //           humMin: humDayMin,
            //           humMax: humDayMax,
            //           tempAvg: tempDayAvg,
            //           tempMin: tempDayMin,
            //           tempMax: tempDayMax,
            //           weightAvg: weightDayAvg,
            //           weightMin: weightDayMin,
            //           weightMax: weightDayMax,
            //         }
            //         ertragsArrayDay.push(ertragsItem);

            //         if (ertragsItem.ertrag >= 0) {
            //           ertragsArrayPosDay.push(ertragsItem);
            //         } else {
            //           ertragsArrayNegDay.push(ertragsItem);
            //         }
            //         dayCount = 0;
            //         humDayMin = dataPoint.humidity;
            //         humDayMax= dataPoint.humidity;
            //         humDayAvg = 0;
            //         tempDayMin = dataPoint.temperature;
            //         tempDayMax= dataPoint.temperature;
            //         tempDayAvg = 0;
            //         weightDayMin = dataPoint.weight;
            //         weightDayMax = dataPoint.weight;
            //         weightDayAvg = 0;
            //       }
            //       lastDaySignalTime = dataPoint.signal_time;
            //       lastDayTimeString = dataPoint.time_string;
            //       lastValue = dataPoint.weight;
            //     } 

            //     //Berechnung der Werte
            //     dayCount++;

            //     if (dataPoint.humidity != 0) {
            //       lastHum = dataPoint.humidity;
            //     } else {
            //       dataPoint.humidity = lastHum;
            //       // dataPoint.change_time new:  {
            //       //   "id_weight":122321,
            //       // "id_scale":1029,
            //       // "temperature":2.72,
            //       // "humidity":99.9,
            //       // "pressure":9000,
            //       // "scale":49.747,
            //       // "weight":49.747,
            //       // "scale_val":18330584,
            //       // "last":49.753,
            //       // "diff":-0.006000000000000227,
            //       // "ertrag":1.0989999999999966,
            //       // "event":0,
            //       // "temp_scale":-40,
            //       // "time": "2020-11-12T17:29:02.000Z",
            //       // "voltage":4.16,
            //       // "date":"2020-11-12 17:29:02",
            //       // "voltage_percent":96.71,
            //       // "signal_time":1605202142000,
            //       // "time_string":"2020-11-12 17:29:02"}
            //       // console.log("dataPoint.change_time new: "+dataPoint.time_string);
            //     }              

            //     weightDayAvg += dataPoint.weight;
            //     tempDayAvg += dataPoint.temperature;
            //     humDayAvg += dataPoint.humidity;

            //     humDayMax = (dataPoint.humidity > humDayMax) ? dataPoint.humidity : humDayMax;
            //     humDayMin = (dataPoint.humidity < humDayMin) ? dataPoint.humidity : humDayMin;
            //     tempDayMax = (dataPoint.temperature > tempDayMax) ? dataPoint.temperature : tempDayMax;
            //     tempDayMin = (dataPoint.temperature < tempDayMin) ? dataPoint.temperature : tempDayMin;
            //     weightDayMax = (dataPoint.weight > weightDayMax) ? dataPoint.weight : weightDayMax;
            //     weightDayMin = (dataPoint.weight < weightDayMin) ? dataPoint.weight : weightDayMin;

            //     if (moment(dataPoint.time_string).hour() != hour) {
            //       hour = moment(dataPoint.time_string).hour();
            //       // console.log("Neuer tag: ", moment(dataPoint.time_string));
            //       if (lastValueHour != 9999) {
            //         const ertragsItem = {
            //           ertrag: lastValueHour == null ? 0 : (dataPoint.weight -lastValueHour),
            //           time_string: dataPoint.time_string,
            //           signal_time: dataPoint.signal_time
            //         }
            //         ertragsArray.push(ertragsItem);
            //         if (ertragsItem.ertrag >= 0) {
            //           ertragsArrayPos.push(ertragsItem);
            //         } else {
            //           ertragsArrayNeg.push(ertragsItem);
            //         }
            //       }
            //       lastValueHour = dataPoint.weight;
            //     } 


            //     // last_weight_save = last_weight;
            //     // ertrag += diff;
            //     // last_weight = dataPoint.weight;              
            
                
            //     return {
            //       ...dataPoint,
            //       // humidity: hum,
            //       // x: moment.utc(dataPoint.change_time).format(timeFormat),
            //       // y: dataPoint.scale,
            //       // id_weight: parseInt(dataPoint.id_weight),
            //       // id_scale: parseInt(dataPoint.id_scale),
            //       // temperature: lastTemp,
            //       // humidity: dataPoint.humidity,
            //       // pressure: dataPoint.pressure,
            //       // scale: dataPoint.weight,
            //       // weight: dataPoint.weight,
            //       // scale_val: dataPoint.scale,
            //       // last: last_weight_save,
            //       // diff: diff,
            //       // ertrag: ertrag,
            //       // event: parseInt(event),
            //       // temp_scale: dataPoint.temperature2,
            //       // time: dataPoint.change_time,
            //       // voltage: dataPoint.vc,
            //       // date: moment.utc(dataPoint.change_time).format(timeFormat), //moment(dataPoint.time).valueOf()/1000,
            //       // voltage_percent: dataPoint.vc_p,
            //       // time_string: moment.utc(dataPoint.time).local().format('YYYY-MM-DD HH:mm:ss'), //.format('YYYY-MM-DD HH:mm:ss').valueOf(), //.valueOf(),
            //       // signal_time: moment(dataPoint.time).local().valueOf(), //= milliseconds
            //       // time_string: moment.utc(dataPoint.change_time).format(timeFormat),
            //     };
            //   });
            // }


            // // console.log("ertragsArray: ", ertragsArray);
            // item.ertragsArray = ertragsArray;
            // item.ertragsArrayPos = ertragsArrayPos;
            // item.ertragsArrayNeg = ertragsArrayNeg;
            // item.ertragsArrayDay = ertragsArrayDay;
            // item.ertragsArrayPosDay = ertragsArrayPosDay;
            // item.ertragsArrayNegDay = ertragsArrayNegDay;

            // // console.log("scaledata: ", item.scaledata[item.scaledata.length-1].signal_time_local);

            console.timeEnd('datamapping');
            setStatScales(statScales => [...statScales, item]);

            const timer = setTimeout(async () => {
              console.log('now load whole data..')
              const now = Date.now(); 
              const week = Date.now()-1000*24*60*60*9; 
              console.time('db-get');
              
              let scaleData = await db.scaledata
                // .where("id_scale")
                // .belowOrEqual(scale.id_scales)
                // .equals(+id_scales)
                .where('[id_scale+signal_time]')
                .between([+id_scales, -Infinity], [+id_scales, +Infinity])
                // .between([+id_scales, -Infinity], [+id_scales, now])
                // .between ([some_item_id, -Infinity], [some_item_id, Dexie.maxKey])
                // .orderBy("id_weight").reverse()
                // .limit(1)
                .toArray()
                .catch (async function (err) {
                  console.log("error getting scaleData from db - reopen: ",err);
                  await db.open();
                  scaleData = [];
                });;
                item.scaledata = scaleData;

                console.timeEnd('db-get');
            }, 500);
          }
        });        
      }
    }
    // dispatch( fetchScaleData(token, id_scales) );
    // dispatch( fetchScaleDataAll(token, id_scales) );   
    // return () => clearTimeout(timer); 
  }

  useEffect( () => {    
    if (statScales) {
      if (statScales.length > 0) {
        recalcValues(scaleRange.start, scaleRange.end);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statScales]);

  const unpack = (rows, key) => {
    if (rows !== undefined) {
      return rows.map(function(row) { return row[key]; });
    } else {
      return [];
    }
  }
  const unpackAndAddReverse = (rows, key, key2) => {
    if (rows !== undefined) {
      return [...rows.map(function(row) { return row[key]; }), ...rows.slice(0).reverse().map(function(row) { return row[key2]; })]; ;
    } else {
      return [];
    }
  }

  const unpackFilter = (rows, key) => {
    if (rows !== undefined) {
      return rows.map(function(row) { return row[key]; });
    } else {
      return [];
    }
  }

  let timeout = null;
  let data = [{}];

  if (statScales !== undefined) {
    statScales.forEach(function (item, index) {
    //add scale-data to data!
      console.log("scale: ", item, index);
      // if (moment(scaleRange.end).valueOf() - moment(scaleRange.start).valueOf() > 1000*60*60*24*15) {
      //   //DAtenbereich sehr groß für Balkenanzeige - 
      //   //werden nicht eingeblendet!    
      //   const bar_trace = {
      //     type: "bar",
      //     // mode: "lines+markers",
      //     // mode: "bar",
      //     base: 0,
      //     name: 'Zunahme',
      //     x: unpack(item.ertragsArrayPosDay, 'time_string'),
      //     y: unpack(item.ertragsArrayPosDay, 'ertrag'),
      //     xaxis: 'x2',
      //     yaxis: 'y2',
      //     marker: {
      //       color: weight
      //     },
      //     hovertemplate: '%{y:.2f}kg',
      //   }

      //   const bar_trace_neg = {
      //     type: "bar",
      //     // mode: "lines+markers",
      //     // mode: "bar",
      //     base: 0,
      //     name: 'Abnahme',
      //     x: unpack(item.ertragsArrayNegDay, 'time_string'),
      //     y: unpack(item.ertragsArrayNegDay, 'ertrag'),
      //     xaxis: 'x2',
      //     yaxis: 'y2',
      //     marker: {
      //       color: "#FF0000"
      //     },
      //     hovertemplate: '%{y:.2f}kg',
      //   }
      //   // data.push(bar_trace);
      //   // data.push(bar_trace_neg);
            
      // } else {        
      //   const bar_trace = {
      //     type: "bar",
      //     // mode: "lines+markers",
      //     // mode: "bar",
      //     base: 0,
      //     name: 'Zunahme',
      //     x: unpack(item.ertragsArrayPos, 'time_string'),
      //     y: unpack(item.ertragsArrayPos, 'ertrag'),
      //     xaxis: 'x2',
      //     yaxis: 'y2',
      //     marker: {
      //       color: weight
      //     },
      //     hovertemplate: '%{y:.2f}kg',
      //   }

      //   const bar_trace_neg = {
      //     type: "bar",
      //     // mode: "lines+markers",
      //     // mode: "bar",
      //     base: 0,
      //     name: 'Abnahme',
      //     x: unpack(item.ertragsArrayNeg, 'time_string'),
      //     y: unpack(item.ertragsArrayNeg, 'ertrag'),
      //     xaxis: 'x2',
      //     yaxis: 'y2',
      //     marker: {
      //       color: "#FF0000"
      //     },
      //     hovertemplate: '%{y:.2f}kg',
      //   }

      //   // data.push(bar_trace);
      //   // data.push(bar_trace_neg);
      // }

      // const ertrag_trace = {
      //   type: "scattergl",
      //   // mode: "lines+markers",
      //   mode: "lines",
      //   name: 'Ertrag',
      //   x: unpack(item.scaledata, 'time_string'),
      //   y: unpack(item.scaledata, 'ertrag'),
      //   xaxis: 'x2',
      //   yaxis: 'y2',
      //   line: {
      //     color: ertrag //'#17BECF'
      //   },
      //   // hoverlabel: {
      //   //   align: 'left',
      //   //   bgcolor: '#fff',
      //   //   bordercolor: '#ccc',
      //   //   font: {
      //   //     size: 10
      //   //   }
      //   // },
      //   hovertemplate: '%{y:.2f}kg',
      // }
        // data.push(ertrag_trace);


      const daysToSimplification = 3000; //10000;
      console.log("scaleRange.end: ", scaleRange.end, " scaleRange.start: ", scaleRange.start);
      if (moment(scaleRange.end).valueOf() - moment(scaleRange.start).valueOf() > 1000*60*60*24*daysToSimplification) {
               
        console.log("daysToSimplificatio2n");
        const weight_trace = {
          type: "scattergl",
          // mode: "lines+markers",
          mode: "lines",
          name: 'Gewicht',
          x: unpack(item.scaledata, 'time_string'),
          y: unpack(item.scaledata, 'weight'), //'weight'),
          xaxis: 'x1',
          yaxis: 'y1',
          line: {
            color: weight //'#17BECF'
          },
          hovertemplate: '%{y:.2f}kg',
        }
        // const weight_trace = {
        //   type: "scattergl",
        //   // mode: "lines+markers",
        //   mode: "lines",
        //   name: 'Gewicht',
        //   x: unpack(item.ertragsArrayDay, 'time_string'),
        //   y: unpack(item.ertragsArrayDay, 'weight'),
        //   xaxis: 'x2',
        //   yaxis: 'y1',
        //   line: {
        //     color: weight, //'#17BECF'
        //     shape: 'linear', //spline',
        //   },
        //   hovertemplate: '%{y:.2f}kg',
        // }

        // const weight_trace2 = {
        //   type: "scattergl",
        //   // mode: "lines+markers",
        //   mode: "lines",
        //   name: 'Gewicht (Min)',
        //   // x: unpack(item.ertragsArrayDay, 'time_string'),
        //   // y: unpack(item.ertragsArrayDay, 'weightAvg'),
        //   x: unpackAndAddReverse(item.ertragsArrayDay, 'time_string', 'time_string'),
        //   y: unpackAndAddReverse(item.ertragsArrayDay, 'weightMin', 'weightMax'),
        //   fill: "toself", //tozerox", 
        //   fillcolor: "rgba(0,151,167,0.2)", 
        //   line: {color: "transparent"}, 
        //   xaxis: 'x2',
        //   yaxis: 'y1',
        //   // line: {
        //   //   color: weight, //'#17BECF'
        //   //   shape: 'linear', //spline',
        //   // },
        //   // hovertemplate: '%{y:.2f}kg',
        // }

        const temp_trace = {
          type: "scatter", //scattergl
          // mode: "lines+markers",
          mode: "lines",
          name: 'Temperatur',  
          x: unpack(item.ertragsArrayDay, 'time_string'),
          y: unpack(item.ertragsArrayDay, 'tempAvg'),
          xaxis: 'x1',
          yaxis: 'y3',
          line: {
            color: temperature,
            width: 1.5, 
            shape: 'spline', //'hv', //spline',
            simplify: true,
            // smoothing: 0.1,
            dash: 'solid'
          },
          hovertemplate: '%{y:.2f}°C',
          // hovertemplate: '<i>Temperatur</i>: %{y:.2f}°C' +
                          // '<br><b>X</b>: %{x}',
        }  
        const temp_trace2 = {
          type: "scattergl",
          // mode: "lines+markers",
          // mode: "lines",
          name: 'Temperatur (Min)',        

          x: unpackAndAddReverse(item.ertragsArrayDay, 'time_string', 'time_string'),
          y: unpackAndAddReverse(item.ertragsArrayDay, 'tempMin', 'tempMax'),
          fill: "tozerox", 
          fillcolor: "rgba(21,101,192,0.2)", 
          line: {color: "transparent"}, 
          xaxis: 'x1',
          yaxis: 'y3',
          // line: {
          //   color: temperature,
          //   width: 1.5, 
          //   shape: 'hv', //spline',
          //   simplify: true,
          //   // smoothing: 0.1,
          //   dash: 'solid'
          // },
          // hovertemplate: '%{y:.2f}°C',
          // hovertemplate: '<i>Temperatur</i>: %{y:.2f}°C' +
                          // '<br><b>X</b>: %{x}',
        }  



        const hum_trace = {
          type: "scattergl", //scattergl
          // mode: "lines+markers",
          mode: "lines",
          name: 'Luftfeuchtigkeit',
          x: unpack(item.ertragsArrayDay, 'time_string'),
          y: unpack(item.ertragsArrayDay, 'humAvg'), // 'humidity'),
          xaxis: 'x1',
          yaxis: 'y4',
          line: {
            color: humidity,
            width: 1.5, 
            shape: 'spline', //'hv', //spline',
            simplify: true,
            smoothing: 0.5,
            dash: 'solid'
          },
          hovertemplate: '%{y:.1f}%',
        }  
        const hum_trace2 = {
          type: "scattergl", //scattergl
          // mode: "lines+markers",
          mode: "lines",
          name: 'Luftfeuchtigkeit (Min)',
          x: unpackAndAddReverse(item.ertragsArrayDay, 'time_string', 'time_string'),
          y: unpackAndAddReverse(item.ertragsArrayDay, 'humMin', 'humMax'),
          fill: "tozerox", 
          fillcolor: "rgba(106,27,154,0.2)", 
          line: {color: "transparent"}, 
          xaxis: 'x1',
          yaxis: 'y4',
          hovertemplate: '%{y:.1f}%',
        }  
        
        data.push(hum_trace);
        data.push(hum_trace2);
        data.push(weight_trace);
        // data.push(weight_trace2);
        data.push(temp_trace);
        data.push(temp_trace2);

        console.log("trace 22222");
      } else {

        const weight_trace = {
          type: "scattergl",
          // mode: "lines+markers",
          mode: "lines",
          name: 'Gewicht',
          x: unpack(item.scaledata, 'time_string'),
          y: unpack(item.scaledata, 'weight'), //'weight'),
          xaxis: 'x1',
          yaxis: 'y1',
          line: {
            color: weight //'#17BECF'
          },
          hovertemplate: '%{y:.2f}kg',
        }
  
        const temp_trace = {
          type: "scattergl", //scattergl
          // mode: "lines+markers",
          mode: "lines",
          name: 'Temperatur',
          x: unpack(item.scaledata, 'time_string'),
          y: unpack(item.scaledata, 'temperature'),
          xaxis: 'x1',
          yaxis: 'y3',
          line: {
            color: temperature,
            width: 1.5, 
            shape: 'spline',
            simplify: true,
            smoothing: 0.5,
            dash: 'solid'
          },
          hovertemplate: '%{y:.2f}°C',
          // hovertemplate: '<i>Temperatur</i>: %{y:.2f}°C' +
                          // '<br><b>X</b>: %{x}',
        }  
  
        const hum_trace = {
          type: "scattergl", //scattergl
          // mode: "lines+markers",
          mode: "lines",
          name: 'Luftfeuchtigkeit',
          x: unpack(item.scaledata, 'time_string'),
          y: unpack(item.scaledata, 'humidity'), // 'humidity'),
          xaxis: 'x1',
          yaxis: 'y4',
          line: {
            color: humidity,
            width: 1.5, 
            shape: 'spline',
            simplify: true,
            smoothing: 0.5,
            dash: 'solid'
          },
          hovertemplate: '%{y:.1f}%',
        }  

        const voltage_trace = {
          type: "scattergl", //scattergl
          // mode: "lines+markers",
          mode: "lines",
          name: 'Spannung',
          x: unpack(item.scaledata, 'time_string'),
          y: unpack(item.scaledata, 'voltage'), // 'humidity'),
          xaxis: 'x1',
          yaxis: 'y5',
          line: {
            color: voltage,
            width: 1.5, 
            shape: 'spline',
            simplify: true,
            smoothing: 0.5,
            dash: 'solid'
          },
          hovertemplate: '%{y:.2f}V',
        }  
        
        
        data.push(hum_trace);
        data.push(voltage_trace);
        data.push(weight_trace);
        data.push(temp_trace);
        
      }
    });
  } else {
    console.error("show something");
  }

  // const timeDomain = d3.scaleTime()
  //   .domain([moment(scaleRange.start).valueOf(), moment(scaleRange.end).valueOf()]);
  // let ticks;
  // ticks = timeDomain.ticks(
  //   d3.timeHour.every(24)).map(d => d.getTime());//(timeDomain(d) + 68));
  // if (moment(scaleRange.end).valueOf() - moment(scaleRange.start).valueOf() > 1000*60*60*24*14) {
  //   // ticks = timeDomain.ticks(
  //   //   d3.timeDay.every(7)).map(d => d.getTime());//(timeDomain(d) + 68));
  //   //   // console.log("zeitraum > 14 Tage - ticks: ", ticks);
  //   //mehr als 14 Tage -> nur Wochen anzeigen!
  //   //TODO - filtern
  //   let currentIndex = -1;
  //   ticks = ticks.filter(tick => {
  //     // if (currentIndex == -1) {
  //       if (moment(tick).day() == 0) {
  //         // console.log(moment(tick).day() );
  //         return true;
  //       }
  //       return false;
  //     // }
  //   })
  // }

  // let dayShapes = [];
  // let added = false;
  // let last_val = 0;

  // console.log('vor ticks-foreach '+moment().format('HH:mm:ss.SSS'));
  // ticks.forEach(function (item, index) {
  //   // console.log("tick: ", item, " added: ", added);
  //   if (!added) {
  //     last_val = item;
  //   } else {
  //     //Jetzt hinzufügen!
  //     const new_day =  {
  //       type: 'rect',
  //       xref: 'x',
  //       yref: 'paper',
  //       x0: moment(last_val).format(timeFormat), //'2021-03-07', //scaleRange.start, //'2015-02-04',
  //       y0: 0.40,
  //       x1: moment(item).format(timeFormat), //'2021-03-05', //scaleRange.end,
  //       y1: 1,
  //       fillcolor: '#ccc',
  //       opacity: 0.2,
  //       line: {
  //         width: 0
  //       }
  //     }
  //     const new_day2 =  {
  //       type: 'rect',
  //       xref: 'x',
  //       yref: 'paper',
  //       x0: moment(last_val).format(timeFormat), //'2021-03-07', //scaleRange.start, //'2015-02-04',
  //       y0: 0,
  //       x1: moment(item).format(timeFormat), //'2021-03-05', //scaleRange.end,
  //       y1: 0.34,
  //       fillcolor: '#ccc',
  //       opacity: 0.2,
  //       line: {
  //         width: 0
  //       }
  //     }
  //     dayShapes.push(new_day);
  //     dayShapes.push(new_day2);
  //   }
  //   added = !added;
  // });

  // if (added) {
  //   //müssen letzten Tag abschließen!
  //   const new_day =  {
  //     type: 'rect',
  //     xref: 'x',
  //     yref: 'paper',
  //     x0: moment(last_val).format(timeFormat), //'2021-03-07', //scaleRange.start, //'2015-02-04',
  //     y0: 0.40,
  //     x1: moment(scaleRange.end).format(timeFormat), //'2021-03-05', //scaleRange.end,
  //     y1: 1,
  //     fillcolor: '#ccc',
  //     opacity: 0.2,
  //     line: {
  //       width: 0
  //     }
  //   }
  //   const new_day2 =  {
  //     type: 'rect',
  //     xref: 'x',
  //     yref: 'paper',
  //     x0: moment(last_val).format(timeFormat), //'2021-03-07', //scaleRange.start, //'2015-02-04',
  //     y0: 0,
  //     x1: moment(scaleRange.end).format(timeFormat), //'2021-03-05', //scaleRange.end,
  //     y1: 0.34,
  //     fillcolor: '#ccc',
  //     opacity: 0.2,
  //     line: {
  //       width: 0
  //     }
  //   }
  //   dayShapes.push(new_day);
  //   dayShapes.push(new_day2);
  // }

  console.log('nach ticks-foreach '+moment().format('HH:mm:ss.SSS'));
    // {
    //   type: 'rect',
    //   // x-reference is assigned to the x-values
    //   xref: 'x',
    //   // y-reference is assigned to the plot paper [0,1]
    //   yref: 'paper',
    //   x0: moment(scaleRange.start).format(timeFormat), //'2021-03-07', //scaleRange.start, //'2015-02-04',
    //   y0: 0.40,
    //   x1: moment(scaleRange.end).subtract(2, 'day').format(timeFormat), //'2021-03-05', //scaleRange.end,
    //   y1: 1,
    //   fillcolor: '#ccc',
    //   opacity: 0.2,
    //   line: {
    //       width: 0
    //   }
    // },
  



  var layout = {
    // shapes: dayShapes,
    showlegend: false,
    legend: {
      font: 4,
      x: 1.1,
      y: 1.2
    },
    dragmode: 'pan', // 'select',
    font: {
      size: 11,
      family: 'Calibri'
    },
    plot_bgcolor: '#ffffff',
    paper_bgcolor: '#ffffff',
    // selectdirection	:	'h',

    hovermode: 'x unified' , //'x unified', //compare',
    height: 650,
    // width: 600,

    // if (isMobile) {
    margin: {
      l: isMobile ? 25 : 50,
      r: isMobile ? 25 : 50,
      b: isMobile ? 5 : 10,
      t: isMobile ? 40 : 50,
      pad: 0
    },
    spikedistance: 200,
    hoverdistance: 100,

    xaxis: {
      showgrid: true,
      showline: true, //Line unter und über achse
      linecolor: '#666',
      // side	:	'bottom',
      // showspikes: true,
      mirror: 'ticks',
      // spikemode: "across",
      // spikedash: "solid",
      // spikecolor: "#333",
      // spikethickness: 1,
      // autorange: true,
      // rangemode	:	'normal',
      // showticklabels: true,
      // matches: 'x2',
      // ticks: 'outside',
      // ticklen: 4,
      // tickwidth: 1,
      // tickcolor: '#ccc',
      matches: 'x2',
      anchor: 'y3', //'y3',
      range: [scaleRange.start, scaleRange.end], //'2021-12-31'],
      rangeselector: settings.getRangeButtons(),
      rangeslider: settings.getRangeSlider(0,0),
      type: 'date',
      domain: [0, 1],
    },
    xaxis2: {
      anchor: 'y1',
      showline: true,
      mirror: 'ticks',
      showticklabels: false,
    },

    yaxis: {
      showgrid: true,
      showline: true,
      linecolor: '#ccc',
      autorange: false,
      zeroline: true,
      ticksuffix: isMobile ? '' : 'kg',
      // title: 'Gewicht',
      range: [scaleRange.weightStart, scaleRange.weightEnd],
      // titlefont: {color: weight},
      tickfont: {color: weight},
      ticks: isMobile ? 'inside' : 'outside',
      ticklen: 3,
      tickwidth: 1,
      anchor: 'x1',
      domain: [0.40, 1],
    },
    yaxis2: {
      showgrid: true,
      showline: true,
      linecolor: '#ccc',
      autorange: false,
      domain: [0.40, 1],
      tickmode: 'auto',
      anchor: 'x2',
      side: 'right',
      ticksuffix: isMobile ? '' : 'kg',
      overlaying: 'y',  //sonst wird es nicht im hover gezeigt
      zeroline: true,
      // ticks: 'outside',
      ticks: isMobile ? 'inside' : 'outside',
      ticklen: 3,
      tickwidth: 1,
      // type: 'linear',
      // title: 'Ertrag',
      // titlefont: {color: ertrag},
      range: [scaleRange.weightStart, scaleRange.weightEnd],
      tickfont: {color: weight},
    },
    yaxis3: {
      // showgrid: false,
      showgrid: true,
      showline: true,
      linecolor: '#ccc',
      // title: 'Temperatur',
      // titlefont: {color: temperature},
      ticks: isMobile ? 'inside' : 'outside',
      tickfont: {color: temperature},
      ticksuffix: isMobile ? '' : '°C',
      anchor: 'x2',
      ticklen: 3,
      tickwidth: 1,
      // side: 'left',
      domain: [0, 0.34],
      // position: 0,
      type: 'linear',
    },
    yaxis4: {
      showgrid: false,
      showline: true,
      linecolor: '#ccc',
      // title: 'Luftfeuchtigkeit',
      // titlefont: {color: humidity},
      ticks: isMobile ? 'inside' : 'outside',
      tickfont: {color: humidity},
      ticksuffix: isMobile ? '' : '%',
      ticklen: 3,
      tickwidth: 1,
      domain: [0, 0.34],
      anchor: 'x2',
      overlaying: 'y3',
      side: 'right',
    },
    yaxis5: {
      showgrid: false,
      showline: false,
      visible: false,
      // linecolor: '#ccc',
      // title: 'Luftfeuchtigkeit',
      // titlefont: {color: humidity},
      // ticks: 'none',
      // tickfont: {color: humidity},
      // ticksuffix: isMobile ? '' : '',
      range: [3, 4.3],
      ticklen: 0,
      tickwidth: 0,
      domain: [0, 0.34],
      anchor: 'x2',
      overlaying: 'y3',
      side: 'right',
    }
  };


  /*
  * Start und Ende der Y-Achsen berechnen
  */
  const recalcValues = (start, end) => {
    console.log('recalcValues at '+moment().format('HH:mm:ss.SSS'));
    let max;
    let min;
    let maxErtrag;
    let minErtrag;

    // console.log("recalcValues statScales: ", statScales);
    const weightOffset = 0.2;
    const ertragOffset = 0.2;

    if ((start === 0) && (end === 1)) {
      //Auto-Range eingestellt! kompletten Verlauf anzeigen!!

      let newStart = 0;
      statScales.forEach(function (item, index) {
        if (newStart === 0) {
          if (item.scaledata.length > 0) {
            newStart = item.scaledata[0].signal_time;
          }
        }
      //   // console.log("statScales forEach");
      //   // value = unpack(item.fio, 'time_string', start, end)

      //   //TODO Zeitmessung für Variante!
      //   // const now = new Date();
      //   // const left = now.getTime()-this.week;
      //   // const right = now.getTime();

      //   // const filtered = item.scaledata.filter(function (dataPoint) {
      //   //   // return ((dataPoint.signal_time <= endTime) && (dataPoint.signal_time >= startTime));
      //   // });
      });

      start = newStart;
      end = moment().format(timeFormat);
    }
    
    const startTime = moment.utc(start).valueOf();
    const endTime = moment.utc(end).valueOf();


    statScales.forEach(function (item, index) {
      // console.log("statScales forEach");
      // value = unpack(item.fio, 'time_string', start, end)

      //TODO Zeitmessung für Variante!
      // const now = new Date();
      // const left = now.getTime()-this.week;
      // const right = now.getTime();

      if (item.scaledata === undefined) {
        return;
      }
      const filtered = item.scaledata.filter(function (dataPoint) {
        return ((dataPoint.signal_time <= endTime) && (dataPoint.signal_time >= startTime));
      });

      // const filteredErtrag = item.ertragsArray.filter(function (dataPoint) {
      //   return ((dataPoint.signal_time <= endTime) && (dataPoint.signal_time >= startTime));
      // });
      // const shortenedArray = unpack(item.scaledata, 'time_string', start, end);

      // const dataMax = Math.max(...this.props.scale.weights.map((i) => i.weight));
      // const dataMin = Math.min(...this.props.scale.weights.map((i) => i.weight));

      const valueMin = Math.min.apply(null, unpack(filtered, 'weight')) -weightOffset; 
      const valueMax = Math.max.apply(null,  unpack(filtered, 'weight')) +weightOffset;

      // const valueMinErtrag = Math.min.apply(null, unpack(filteredErtrag, 'ertrag')) -ertragOffset;
      // const valueMaxErtrag = Math.max.apply(null,  unpack(filteredErtrag, 'ertrag')) +ertragOffset; 

      if (min !== undefined) {
        min = (valueMin < min) ? valueMin : min;
      } else {
        min = valueMin;
      }
      if (max !== undefined) {
        max = (valueMax > max) ? valueMax : max;
      } else {
        max = valueMax;
      }
       
      // if (minErtrag !== undefined) {
      //   minErtrag = (valueMinErtrag < minErtrag) ? valueMinErtrag : minErtrag;
      // } else {
      //   minErtrag = valueMinErtrag;
      // }
      // if (maxErtrag !== undefined) {
      //   maxErtrag = (valueMaxErtrag > maxErtrag) ? valueMaxErtrag : maxErtrag;
      // } else {
      //   maxErtrag = valueMaxErtrag;
      // }

      // let topErtrag = Math.round(d3.max(filtered, function (d) {
      //   return d.ertrag;
      // })) + offsetWeight;
      // let bottomErtrag = Math.floor(d3.min(filtered, function (d) {
      //   return d.ertrag;
      // })) - offsetWeight;
      // let topGewicht = Math.round(d3.max(filtered, function (d) {
      //   return d.weight;
      // })) + offsetWeight;
      // let bottomGewicht = Math.floor(d3.min(filtered, function (d) {
      //   return d.weight;
      // })) - offsetWeight;
    });    

    // d3.select('g.draglayer').selectAll('rect')
    // .style('cursor', 'help');

    console.log("minErtrag: "+minErtrag+" maxErtrag: "+maxErtrag);
      
    setScaleRange({
      start: start, 
      end: end,
      weightStart: min,
      weightEnd: max,
      ertragStart: minErtrag,
      ertragEnd: maxErtrag});
  }


  const handleAnimateClick = () => {
    
      // Plotly.animate('plot-div', {
      //   layout: {
      //     // xaxis: {range: [start, end]},
      //     yaxis: {range: [0, 20]}
      //   }
      // }, {
      //   transition: {
      //     duration: 500,
      //     easing: 'cubic-in-out'
      //   }
      // })
  }


  console.log('render return '+moment().format('HH:mm:ss.SSS'));

  return (
    <Paper className={classes.customClass}>
      <Grid
        container 
        direction="row" >

        <Grid item xs={4} sm={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBackClick}>
            <IconContainer icon={const_vars.ICON_BACK} />
            Zurück
          </Button>
        </Grid>

        <Grid item xs={8} sm={10}>
          { statScales !== undefined &&
            statScales.map((item, key)=>
              { return <StatisticScale 
                scale={item}
                key={key} />
              })
          }
        </Grid>
      </Grid>


      <Divider></Divider>
      
      <Plot
        style={{width: '100%', cursor: pointer}}
        className={classes.customPlot}
        data={data}
        layout={layout}
        graphDiv="graph"
        config={settings.getChartConfig()}
        divId="plot-div"
        ref={plotlyRef} 
        onRelayout={(event) => {
          // console.log("onRelayout Event: ", event);
          if (event["xaxis.range[0]"] !== undefined) {
            recalcValues(moment(event["xaxis.range[0]"]).format(timeFormat),
                      moment(event["xaxis.range[1]"]).format(timeFormat));
          } else if (event["xaxis.range"] !== undefined) {
            clearTimeout(timeout);
            //timeout weil das so oft hintereinander gefeuert wird
            timeout = setTimeout(function () {
                // console.log('Input Value:', textInput.value);
              // console.log('ongoing '+moment().format('HH:mm:ss'));
              recalcValues(moment(event["xaxis.range"][0]).format(timeFormat),
                          moment(event["xaxis.range"][1]).format(timeFormat));
            }, 500);
            // console.log("ongoing Event: ", event);
          } else if (event["xaxis.autorange"] !== undefined) {
            console.log("xaxis.autorange detected");       
            recalcValues(0,1); 
          } else {
            console.log("onRelayout Event not handled - autorange!! ", event);      
          }
        }} //?: (event: Readonly<Plotly.PlotRelayoutEvent>) => void;

        onHover={(event) => {
          // if (dragLayer != undefined) {
          //   dragLayer.style.cursor = "pointer";
          // }
          // console.log("hover: ", event);
        }} //: (event: Readonly<Plotly.PlotMouseEvent>) => void;
        onUnhover={(event) => {
          // console.log("unhover: ", event);
        }} //: (event: Readonly<Plotly.PlotMouseEvent>) => void;
        />
    </Paper>
        // onRestyle?: (event: Readonly<Plotly.PlotRestyleEvent>) => void;
        // onRedraw?: () => void;
        // onSelected?: (event: Readonly<Plotly.PlotSelectionEvent>) => void;
        // onSelecting?: (event: Readonly<Plotly.PlotSelectionEvent>) => void;
        // onSliderChange?: (event: Readonly<Plotly.SliderChangeEvent>) => void;
        // onSliderEnd?: (event: Readonly<Plotly.SliderEndEvent>) => void;
        // onSliderStart?: (event: Readonly<Plotly.SliderStartEvent>) => void;
        // onTransitioning?: () => void;
        // onTransitionInterrupted?: () => void;
        // useResizeHandler='true'    
        // onAfterExport?: () => void;
        // onAfterPlot?: () => void;
        // onAnimated?: () => void;
        // onAnimatingFrame?: (event: Readonly<Plotly.FrameAnimationEvent>) => void;
        // onAnimationInterrupted?: () => void;
        // onAutoSize?: () => void;
        // onBeforeExport?: () => void;
        // onButtonClicked?: (event: Readonly<Plotly.ButtonClickEvent>) => void;
        // onClick?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
        // onClickAnnotation?: (event: Readonly<Plotly.ClickAnnotationEvent>) => void;
        // onDeselect?: () => void;
        // onDoubleClick?: () => void;
        // onFramework?: () => void;
        // onHover?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
        // onLegendClick?: (event: Readonly<Plotly.LegendClickEvent>) => boolean;
        // onLegendDoubleClick?: (event: Readonly<Plotly.LegendClickEvent>) => boolean;
  );
}

export default Statistic;
