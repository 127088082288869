import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, TextField } from '@mui/material';
import axios from '../../../axios-modbee';
import { useTranslation } from 'react-i18next';
import const_vars from "../../../Static/const_vars";
import { LoadingButton } from '@mui/lab';


const Schritt5 = (props) => {
    const { t } = useTranslation();
    const [gewicht, setGewicht] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [klicked, setKlicked] = React.useState(false)

    useEffect(() => {
        console.log('Schritt1: ',props.waage.status)
        if (props.waage.status === const_vars.GW_STATUS_IS_CALIBRATING && klicked) {
            setLoading(false)
            props.next()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.waage.status, klicked]); //

    const kalibriere = () => {
        setLoading(true)
        const config = {
            'id_calibration': props.id_calibration,
            'weight': props.gewicht ? props.gewicht : gewicht,
            'status': 0,
            ...props.caliData
        };

        axios.post('/calibration/step', config)
            .then(res => {
                console.log('/calibration/step', res.data)
                props.setstepid(res.data[0])
                const config = {
                    data: {
                        "befehl": const_vars.KALIBRIERUNGS_BEFEHL,
                        "cmd": const_vars.GW_STATUS_SHOULD_SEND_CALIBRATION_VALUES,
                        "id_scales": props.waage.id_scales,
                        ...props.caliData,
                        weight: props.gewicht ? props.gewicht : gewicht,
                    },
                    crossdomain: true
                };

                axios.put('/scale/custom/' + props.waage.id_scales, config)
                    .then(res => {
                        setKlicked(true)
                        console.log("got sendCustomWriteToGateway answer: ", res);
                    })
                    .catch(err => {
                        console.log("got sendChargerFw error: ", err);
                    });
            })
            .catch(err => {
                console.log("got sendChargerFw error: ", err);
            });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                    Bitte legen Sie nun ein bekanntes Gewicht zwischen 5 und 10 kg auf die Waage (am Besten mit einer Lebensmittelwaage abwiegen und in Gramm eintragen)
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="flex-end">
                    {props.gewicht ? null :
                        <Grid item> <TextField disabled = {loading} id="standard-number"
                            label={t('main.Gewicht [g]')}
                            type="number"
                            value={gewicht}
                            onChange={(e) => setGewicht(e.target.value)}
                            variant="standard" /></Grid>}
                    <Grid item xs />
                    <Grid item>
                        <LoadingButton
                            disabled={gewicht === ''}
                            loading={loading}
                            variant="outlined"
                            onClick={kalibriere}
                        >
                            {t('main.Fortfahren')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}

export default Schritt5