import * as actionTypes from './actionTypes';
// import axios from 'axios';
// import firebase from 'firebase/app';
// import 'firebase/auth';
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";

// import {firebaseLogOut} from "../../components/firebase/firebase";
import store from "../../store/store";
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';


export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, userId) => {
  console.log('authSuccess');
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
  };
};

export const refreshSuccess = (token, userId) => {
  console.log('refreshSuccess');
  return {
    type: actionTypes.AUTH_REFRESH_SUCCESS,
    idToken: token,
    userId: userId,
    loggedIn: true
  };
};


export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};
export const changeMail = (email) => {
  return {
    type: actionTypes.AUTH_CHANGE_MAIL,
    email: email
  };
};
export const changePassword = (password) => {
  // console.log("changePassword ", password);
  return {
    type: actionTypes.AUTH_CHANGE_PASSWORD,
    password: password
  };
};
export const changeRemember = (remember) => {
  return {
    type: actionTypes.AUTH_CHANGE_REMEMBER,
    remember: remember
  };
};

export const logout = () => {
  console.log('logout in actions auth');
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  localStorage.removeItem('loggedIn');

  //  firebaseLogOut();
  return {
    type: actionTypes.AUTH_LOGOUT,
    loggedIn: false
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      // dispatch(logout());
      dispatch(authCheckState());
    }, expirationTime * 1000);
  };
};

export const auth = (email, password, remember, register) => {
  return dispatch => {
    dispatch(authStart());
    console.log("actions auth");
    const auth = getAuth();
    
    if (register) {
      //TODO - Registrierung erlauben ...
      //createUserWithEmailAndPassword(auth, email, password);
    } else {
      setPersistence(auth, browserSessionPersistence)
        .then(() => {

          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.      
          // console.log("then...", email, " pass: ", password);
          // New sign-in will be persisted with session persistence.
          signInWithEmailAndPassword(auth, email, password)
            .then(function (user) {
              console.log('signInWithEmailAndPassword then user: ', user);

              let refreshToken = null;
              if (user) {
                  if (user._tokenResponse) {
                    refreshToken = user._tokenResponse.refreshToken;
                    console.log('refreshToken: ', refreshToken);
                  } else {
                    console.log('signInWithEmailAndPassword tno token response');
                  }
              }

              if (auth.currentUser != null) {
                auth.currentUser.getIdToken(true)
                  .then((idToken) => {
                    // console.log('authRefreshToken currentUser: ', firebase.auth().currentUser);
                    // idToken can be passed back to server.
                    // console.log('idToken: ', idToken);

                    const expirationDate = new Date(new Date().getTime() + 15 * 60 * 1000);
                    localStorage.setItem('token', idToken);
                    localStorage.setItem('refreshToken', refreshToken);                    
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('userId', auth.currentUser.uid);
                    localStorage.setItem('loggedIn', true);

                    dispatch(authSuccess(idToken, auth.currentUser.uid));
                    checkTokenTimeout(45 * 60); //alle 29 Minuten neuen Token abholen!
                  })
                  .catch((error) => {
                    // Error occurred.
                    console.error('authRefreshToken - error: ', error.message);
                    store.dispatch(authFail(error.message));
                  });
              } else {
                console.log('authRefreshToken - currentUser is NULL');
                // console.log('firebase.auth().currentUser is NULL');
              }
            })
            .catch(function (err) {
              console.error('An error occurred while user login. ', err);
              // showToken('Error retrieving Instance ID token. ', err);
              // setTokenSentToServer(false);
              dispatch(authFail(err.message));
            });
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    }
  };
};

export const checkToken = () => {
  return {
    type: actionTypes.AUTH_REFRESH_TOKEN
  };
};


export const checkTokenTimeout = (expirationTime) => {

  console.log('checkTokenTimeout startet at ' + moment().format('HH:mm:ss.SSS')+ ' expirationTime: '+expirationTime);
  // console.log('checkTokenTimeout - expirationTime: ' + (expirationTime * 1000));
  setTimeout(() => {
    console.log('checkTokenTimeout executed at ' + moment().format('HH:mm:ss.SSS'));      
    store.dispatch(authRefreshToken());


    checkTokenTimeout(45 * 60); //alle 29 Minuten neuen Token abholen!
  }, expirationTime * 1000);  
};


export const authRefreshToken = () => {
  return dispatch => {
    console.log('authRefreshToken - started');

    const refreshToken = localStorage.getItem('refreshToken');
    const auth = getAuth();
    console.log(auth)
    if (!refreshToken) {
      console.log('authRefreshToken - refreshToken null');
    } else {
      // console.log('authRefreshToken - token ok', auth.currentUser, refreshToken);
      // console.log('authRefreshToken at ' + moment().format('HH:mm:ss.SSS'));

      //Anleitung :
      // https://firebase.google.com/docs/reference/rest/auth#:~:text=You%20can%20refresh%20a%20Firebase,securetoken.googleapis.com%20endpoint.&text=The%20refresh%20token's%20grant%20type%2C%20always%20%22refresh_token%22.&text=A%20Firebase%20Auth%20refresh%20token.&text=The%20number%20of%20seconds%20in%20which%20the%20ID%20token%20expires.,-token_type
            
      fetch('https://securetoken.googleapis.com/v1/token?key=AIzaSyAnyxaFvhtf2N6KLRbWfREHoI_TRVkRXx4', {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: 'grant_type=refresh_token&refresh_token=' + refreshToken
      })
      .then(response => {
        // console.log(response);
        // console.log("body: ", response.body);
        // console.log("Response: ", response.Response);
        const reader = response.body.getReader();

        let charsReceived = 0;
        let result = '';
      
        // read() returns a promise that resolves
        // when a value has been received
        reader.read().then(function processText({ done, value }) {
          // Result objects contain two properties:
          // done  - true if the stream has already given you all its data.
          // value - some data. Always undefined when done is true.
          if (done) {
            // let text_res = String.fromCharCode(result);
            // console.log("Stream result: ", result);
            // console.log("Stream complete: ", text_res);
            // para.textContent = result;
            // console.log("Stream result: ", result);
            const obj = JSON.parse(result);

            if (obj.id_token) {
              const expirationDate = new Date(new Date().getTime() + 15 * 60 * 1000);
              localStorage.setItem('token', obj.id_token);
              localStorage.setItem('refreshToken', obj.refresh_token);                    
              localStorage.setItem('expirationDate', expirationDate);
              //TODO nur token refreshen!!
              dispatch(refreshSuccess(obj.id_token, obj.user_id));
            }

            return;
          }
      
          charsReceived += value.length;

          let char;
          for (let i of value) {
            // console.log(i); // logs "3", "5", "7"
            char = String.fromCharCode(i)
            result += char;
         }

          // let listItem = document.createElement('li');
          // listItem.textContent = 'Read ' + charsReceived + ' characters so far. Current chunk = ' + chunk;

          // list2.appendChild(listItem);
      
      
          // Read some more, and call this function again
          return reader.read().then(processText);
        });

      })
      .catch(error => console.error(error))



      // if (auth.currentUser) {
      //   auth.currentUser.getIdToken(true)
      //     .then((idToken) => {
      //       const expirationDate = new Date(new Date().getTime() + 15 * 60 * 1000);
      //       localStorage.setItem('token', idToken);
      //       localStorage.setItem('expirationDate', expirationDate);
      //       localStorage.setItem('userId', auth.currentUser.uid);
      //       localStorage.setItem('loggedIn', true);

      //       // //wenn getIdToken aufgerufen wird, dann wird session verlängert!

      //       //       console.log('authRefreshToken currentUser: ', auth.currentUser);
      //       //       // idToken can be passed back to server.
      //       //       // console.log('idToken: ', idToken);
      //       //       const expirationDate = new Date(new Date().getTime() + 15 * 60 * 1000);
      //       //       localStorage.setItem('token', idToken);
      //       //       localStorage.setItem('expirationDate', expirationDate);
      //       //       localStorage.setItem('userId', firebase.auth().currentUser.uid);
      //       dispatch(authSuccess(idToken, auth.currentUser.uid));
      //       checkTokenTimeout(29 * 60);
      //     })
      //     .catch((error) => {
      //       // Error occurred.
      //       console.error('authRefreshToken - error: ', error.message);
      //       store.dispatch(authFail(error.message));
      //     });
      // } else {
      //   console.log('authRefreshToken - currentUser is NULL');
      //   // console.log('firebase.auth().currentUser is NULL');
      // }
    }
  }
};



export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('authCheckState / token null -> logout');
      dispatch(logout());
    } else {
      //wenn getIdToken aufgerufen wird, dann wird session verlängert!
      //  if (firebase.auth().currentUser != null) {

      //   console.log('authCheckState null -> firebase.auth().currentUser != null');

      //     firebase.auth().currentUser.getIdToken(true)
      //        .then((idToken) => {
      //           console.log('authCheckState currentUser: ', firebase.auth().currentUser);
      //           // idToken can be passed back to server.
      //           // console.log('idToken: ', idToken);
      //            const expirationDate = new Date(new Date().getTime() + 15 * 60 * 1000);
      //           localStorage.setItem('token', idToken);
      //           localStorage.setItem('expirationDate', expirationDate);
      //           localStorage.setItem('userId', firebase.auth().currentUser.uid);
      //           // authSuccess(idToken, user.user.uid);
      //           // dispatch(authSuccess(idToken, firebase.auth().currentUser.user.uid));
      //           dispatch(checkAuthTimeout(15 * 60));
      //        })
      //        .catch((error) => {
      //           console.error('auth error: ', error);

      //           // Error occurred.
      //           dispatch(authFail(error.message));
      //        });
      //  } else {
      //     // console.log('firebase.auth().currentUser is NULL');
      //  }
      // const expirationDate = new Date(localStorage.getItem('expirationDate'));
      // if (expirationDate <= new Date()) {
      //   // dispatch(logout());
      //
      // } else {
      //   const userId = localStorage.getItem('userId');
      //   dispatch(authSuccess(token, userId));
      //   dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
      // }
    }
  };
};