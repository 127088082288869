import React, { useEffect } from 'react';
import { Backdrop, Badge, Button, Divider, Grid, IconButton, Typography, alpha, MenuItem, ListItemIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'
import { useDispatch, useSelector } from "react-redux";
import ProductSmall from '../../../Shop/Product/ProductSmall';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from '../../../axios-modbee';
import { styled } from '@mui/material/styles';
import * as actions_scales from "../../../store/actions/scales";
import Countdown from '../../../UI/Countdown/Countdown';
import makeStyles from '@mui/styles/makeStyles';
import { teal } from '@mui/material/colors';
import DialogUniversal from '../../../UI/DialogUniversal/DialogUniversal';
import Kalibrierung from '../../../Waagen/Kalibrierung/Kalibrierung';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import DoneIcon from '@mui/icons-material/Done';
import { GreenDotBadge } from '../../../UI/GreenDotBadge/GreenDotBadge';
import moment from 'moment';

const Notifications = (props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedScale, setSelectedScale] = React.useState(null)
    const [badge, setBadge] = React.useState(0)
    const open = Boolean(anchorEl);
    const theme = useTheme();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const cartItems = useSelector(state => state.shop.cartItems);
    const [calis, setCalis] = React.useState([])
    const token = useSelector(state => state.auth.token);
    const scales = useSelector(state => state.scale.scales);
    const calibrations = useSelector(state => state.scale.calibrations);
    const classes = useStyles();

    useEffect(() => {
        if (token) {
            dispatch(actions_scales.fetchScalesFast(token));
            dispatch(actions_scales.fetchCalibrations(token));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);
    useEffect(() => {
        //row.status >= const_vars.GW_STATUS_SHOULD_START_CALIBRATION && row.status <= const_vars.GW_STATUS_IS_SENDING_VALUES
        if (scales.length !== 0 && calibrations.length !== 0) {
            const offeneKalib = calibrations.filter(a => a.status === 0)
            console.log(offeneKalib)
            const calibs = [];
            for (let i = 0; i < offeneKalib.length; i++) {
                const my_scale = scales.filter(a => a.id_scales === offeneKalib[i].id_scale)
                let psoido = { name: 'Nicht gefunden' }
                if (my_scale.length > 0) psoido = { ...my_scale[0] }
                console.log(psoido)
                if (psoido.name === '') psoido.name = t('main.Waage') + ' ' + offeneKalib[i].id_scale

                const offenesteps = offeneKalib[i].steps.filter(a => a.status === 0)
                let lauft = false
                if (offenesteps.length === 0) lauft = false
                else {                           
                    lauft = (offenesteps[0].duration - Math.abs((moment().valueOf() - (moment(offenesteps[0].start_time).valueOf() - (moment().utcOffset() * 60 * 1000))) / 1000)) > 0
                }              


                calibs.push({
                    ...offeneKalib[i],
                    scale: psoido,
                    lauftnoch: lauft
                })
            }
            console.log(calibs)
            setCalis(calibs)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calibrations, scales]);
    const handleClick = (event) => {
        if (calis.length !== 0)
            setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <React.Fragment>
            <Backdrop open={open} />
            <IconButton aria-label="cart" pos={"bottom-start"} onClick={handleClick}>
                {calis.filter(a => !a.lauftnoch).length > 0 ?
                    <Badge badgeContent={calis.filter(a => !a.lauftnoch).length} color="secondary" anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}><NotificationsIcon />
                    </Badge> :

                    <GreenDotBadge
                        badgeContent={calis.filter(a => a.lauftnoch).length}
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >
                        <NotificationsIcon />
                    </GreenDotBadge>}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}

                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                MenuListProps={{ padding: 0 }}
            >
                {/* <Grid container style={{ maxWidth: 320, paddingTop: 8, paddingBottom: 8 }}> */}
                {calis.map((item, index) => {
                    console.log(item)
                    if (!item.lauftnoch) return (
                        // <React.Fragment key={index}>
                        <div key={index}>
                            <MenuItem onClick={() => setSelectedScale(item)}>
                                <ListItemIcon>
                                    <DoneIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography>{item.scale.name + ' - ' + t('main.Kalibrierung fortsetzen')}</Typography>
                            </MenuItem>

                            <Divider style={{ margin: 0 }} />
                        </div>
                    ); else {
                        const aktuellerStep = item.steps.filter(a => a.status === 0)
                        const sekundenSeitStart2 = Math.abs((moment().valueOf() - (moment(aktuellerStep[0].start_time).valueOf() - (moment().utcOffset() * 60 * 1000))) / 1000)
                        return (
                            <div key={index}>
                                {/* <React.Fragment key={index}> */}
                                <MenuItem onClick={() => setSelectedScale(item)}>
                                    <ListItemIcon>
                                        <AccessAlarmIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Grid container>
                                        <Grid item style={{ paddingRight: 8 }}><Typography>{item.scale.name + ' - ' + t('main.Kalibrierung') + ': '}</Typography></Grid>
                                        <Grid item><Countdown small seconds={aktuellerStep[0].duration - sekundenSeitStart2} /></Grid>
                                    </Grid>
                                </MenuItem>

                                <Divider style={{ margin: 0 }} />
                            </div>
                        )
                    }
                })}
                {/* </Grid> */}
            </Menu>
            <DialogUniversal open={selectedScale !== null} close={() => setSelectedScale(null)} maxWidth={'sm'} fullWidth>
                <Kalibrierung waage={selectedScale && selectedScale.scale} kalibrierung={selectedScale} close={() => setSelectedScale(null)} />
            </DialogUniversal>
        </React.Fragment >

    );
}


const useStyles = makeStyles((theme) => ({
    menuitem: {
        padding: 8,
        '&:hover': {
            backgroundColor: alpha(teal[50], 0.3),
            cursor: "pointer"
        },
    }
}));


export default Notifications;