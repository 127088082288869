import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import KalibrierungsArten from './KalibrierungsArten';
import DialogHeaderX from '../../UI/DialogUniversal/DialogHeaderX'
import { DialogContent, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import axios from '../../axios-modbee';
import KVorgang from './KVorgang';
import const_vars from "../../Static/const_vars";

const Kalibrierung = (props) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const { t } = useTranslation();
    const token = useSelector(state => state.auth.token);
    const steps = [t('main.Kalibrierungsart wählen'), t('main.Kalibrieren'), t('main.Fertig')];
    const [kTyp, setKTyp] = React.useState(0)
    const [sperre, setSperre] = React.useState(false)

    useEffect(() => {
        if (props.kalibrierung) {
            console.log(props.kalibrierung)
            setKTyp(props.kalibrierung.typ)
            setActiveStep(1)
        }
    }, [props.kalibrierung]);

    // useEffect(() => {
    //   //Testdaten an Server schicken (wird an Waage weitergeleitet falls gültig!)  
    //   // sendCustomDataScale(1150, const_vars.KALIBRIERUNGS_BEFEHL, 1, 60, 5, 1000, 10);
    //   sendScaleUpdate(1150, "hugo", 33);

    //     if (props.waage) {
    //         if (props.waage.id_kalibrierung) {
    //             setActiveStep(props.waage.step)
    //             setKTyp(props.waage.typ)
    //         }
    //     }
    //     // addCalibration();
    //     // deleteCalibration(1);
    //     // editCalibration(2);
    //     // deleteCalibration(2);
    //     // deleteCalibration(3);
    //     // deleteCalibration(4);
    //     // deleteCalibration(5);
    //     // deleteCalibration(6);
    //     // deleteCalibration(7);
    //     // deleteCalibration(8);
    //     console.log("lets get calibrations");

    //     // dispatch(actions_scales.fetchScalesFast(token));

    //     // console.log("fetchAllScales: ",token);
    //     axios.get('/calibration')
    //         // axios.get( '/scale', config)
    //         .then(res => {
    //             const fetchScales = [];
    //             // for ( let key in res.data ) 
    //             console.log("calibrations ", res.data);

    //         }).catch(err => {
    //             console.error("fetch - error: ", err);
    //         });

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const deleteCalibration = (id_calibration) => {
        const config = {
            headers: {
                'Authorization': '' + token
            }
        };

        // console.log("deleteCalibration token: ", token);
        //schreibt bei typ 99 hinein im Moment und wenn TYP 99 ist, dann liefert der Server das nicht mehr zurück
        axios.delete('/calibration/' + id_calibration, config)
            .then(res => {
                console.log("deleteCalibration result: ", res.data);
            }).catch(err => {
                console.error("err: " + err);
            });
    }

    const editCalibration = (id_calibration) => {
        const config = {
            headers: {
                'Authorization': '' + token
            }
        };

        const calibration = {
            'typ': 2,
            'step': 2,
            'weight': 5000 //5kg - in Gramm speichern?! obwohl es im moment float ist.. 
        };

        console.log("edit calibration: ", calibration);
        axios.put('/calibration/' + id_calibration, calibration, config)
            .then(res => {
                console.log("edit calibration result: ", res.data);
            }).catch(err => {
                console.error("err: " + err);
            });
    }


    const addCalibration = () => {
        const config = {
            headers: {
                'Authorization': '' + token
            }
        };
        //USER-ID weiß der server wegen token
        //FAKE-Daten
        const calibration = {
            'id_scale': 1001,
            'typ': 1,
            'step': 1,
            'weight': 0
        };
        console.log("add calibration: ", calibration);
        axios.post('/calibration', calibration, config)
            .then(res => {
                console.log("add calibration result: ", res.data);
            }).catch(err => {
                console.error("err: " + err);
            });
    }
    // sendCustomDataScale(1150, const_vars.KALIBRIERUNGS_BEFEHL, 1, 60, 5, 1000, 10);
    const sendScaleUpdate = async (id_scales, name, kalibriert) => {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': '' + token
        }
        const config = {
            daten: {
                "name": name,
                "kalibriert": kalibriert
            },
            crossdomain: true
        };

        axios.post('/scale/edit/' + id_scales, config, {
            // axios.put( '/station/custom/'+res_id, config, { //leshan
            headers: headers
        })
            .then(res => {
                console.log("got edit scale answer: ", res);
            })
            .catch(err => {
                console.log("got edit scale error: ", err);
            });
    }

    // sendCustomDataScale(1150, const_vars.KALIBRIERUNGS_BEFEHL, 1, 60, 5, 1000, 10);
    const sendCustomDataScale = async (id_scales, res_id, cmd, duration, count, weight, interval) => {
        // console.log("sendCustomWriteToGateway station: "+JSON.stringify(station));
        // console.log("sendCustomWriteToGateway: "+token);
        let customData = 0;
        let customData2 = 0;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': '' + token
        }
        const config = {
            data: {
                "befehl": res_id,
                "cmd": cmd,
                "id_scales": id_scales,
                "duration": duration,
                "count": count,
                "weight": weight,
                "interval": interval
            },
            crossdomain: true
        };

        axios.put('/scale/custom/' + id_scales, config, {
            // axios.put( '/station/custom/'+res_id, config, { //leshan
            headers: headers
        })
            .then(res => {
                console.log("got sendCustomWriteToGateway answer: ", res);
            })
            .catch(err => {
                console.log("got sendChargerFw error: ", err);
            });
    }

    const handleNext = (typ) => {
        if (typ) setKTyp(typ)
        console.log("handleNext: ", typ);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const abbruch = () => {
        const config = {
            daten: {
                "status": 2,
            }, crossdomain: true
        };

        axios.put('/calibration/' + props.id_calibration, config)
            .then(res => {
                console.log(res.data)
                const config = {
                    data: {
                        "befehl": const_vars.KALIBRIERUNGS_BEFEHL,
                        "cmd": const_vars.GW_STATUS_SHOULD_STOP_CALIBRATION,
                        "id_scales": props.waage.id_scales,
                    },
                    crossdomain: true
                };
                axios.put('/scale/custom/' + props.waage.id_scales, config)
                    .then(res => {
                        props.close()
                        console.log("got sendCustomWriteToGateway answer: ", res);
                    })
                    .catch(err => {
                        console.log("got sendChargerFw error: ", err);
                    });
            })
            .catch(err => {
                console.log("got sendChargerFw error: ", err);
            });
    }


    const backNext = sperre ?
        (<Grid container>
            <Grid item xs />
            <Grid item>
                <Button color="inherit" onClick={abbruch} >
                    {t('main.Kalibrierung abbrechen')}
                </Button>
            </Grid>
            <Grid item xs />
        </Grid>)
        : (
            <Grid container>
                <Grid item>
                    <Button color="inherit" onClick={handleBack} >
                        {t('main.Zurück')}
                    </Button>
                </Grid>
                <Grid item xs />
                <Grid item>
                    <Button color="inherit" onClick={handleNext} >
                        {t('main.Weiter')}
                    </Button>
                </Grid>
            </Grid>
        )

    return (
        <React.Fragment>
            <DialogHeaderX title={t('main.Kalibrierungsassistent')} close={props.close} />
            <DialogContent style={{ backgroundColor: '#f0f0f0' }}>
                <Box sx={{
                    width: '100%'
                }} >

                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <Grid container style={{ paddingTop: 16 }}>
                        {activeStep === 0 ?
                            <Grid item xs={12}>
                                <KalibrierungsArten handleNext={handleNext} />
                            </Grid> :
                            activeStep === 1 ?
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <KVorgang typ={kTyp} waage={props.waage} handleNext={handleNext} setSperre={setSperre} kalibrierung={props.kalibrierung}/>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: 8 }}>{backNext}</Grid>
                                </React.Fragment>
                                :
                                <React.Fragment>

                                    <Grid item xs={12}><Typography variant="h5">Gratulation, Ihre Waage wurde erfolgreich kalibriert!</Typography></Grid>
                                    <Grid item xs={12}><Typography>Die Waage schließt die Kalibririerung automatisch ab, die Anzeige wird anschließend automatisch aktualisiert</Typography></Grid>
                                    <Grid item xs={12} style={{ paddingTop: 8 }}>
                                        <Grid container>
                                            <Grid item xs />
                                            <Grid item>
                                                <Button color="inherit" onClick={props.close} >
                                                    {t('main.Fertig')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </React.Fragment>}
                    </Grid>

                </Box>
            </DialogContent>
        </React.Fragment >
    );
}

export default Kalibrierung;