import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import DialogHeaderX from '../../UI/DialogUniversal/DialogHeaderX'
import { DialogContent, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import axios from '../../axios-modbee';

import const_vars from "../../Static/const_vars";
import ZBDownload from './ZBDownload';
import Anleitung from './Anleitung';

const Zusammenbau = (props) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const { t } = useTranslation();
    const steps = [t('main.Aufbau der Waage'), t('main.Anleitung'), t('main.Fertig')];



    const handleNext = (typ) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const backNext = (
        <Grid container>
            <Grid item>
                <Button color="inherit" onClick={handleBack} >
                    {t('main.Zurück')}
                </Button>
            </Grid>
            <Grid item xs />
            <Grid item>
                <Button color="inherit" onClick={activeStep === steps.length - 1 ? props.skipZusammenbau : handleNext} >
                    {activeStep === steps.length - 1 ? t('main.Weiter zur Kalibrierung') : t('main.Weiter')}
                </Button>
            </Grid>
        </Grid>
    )

    return (
        <React.Fragment>
            <DialogHeaderX title={t('main.Bauanleitung')} close={props.close} />
            <DialogContent style={{ backgroundColor: '#f0f0f0' }}>
                <Box sx={{
                    width: '100%'
                }} >

                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <Grid container style={{ paddingTop: 16 }}>
                        {activeStep === 0 ?
                            <Grid item xs={12}>
                                <ZBDownload handleNext={handleNext} skipZusammenbau={props.skipZusammenbau} />
                            </Grid> :
                            activeStep === 1 ?
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Anleitung />
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: 8 }}>{backNext}</Grid>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Grid item xs={12}><Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography></Grid>
                                    <Grid item xs={12} style={{ paddingTop: 8 }}>{backNext}</Grid>
                                </React.Fragment>}
                    </Grid>
                </Box>
            </DialogContent>
        </React.Fragment >
    );
}

export default Zusammenbau;