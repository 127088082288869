import React from 'react';
import { useDispatch, useSelector} from "react-redux";
import 'moment-timezone';
import 'moment/locale/de';
import makeStyles from '@mui/styles/makeStyles';
import {  Paper, TablePagination, Grid } from '@mui/material';
import { useEffect } from 'react';
import * as actions_scales from "../store/actions/scales";
import * as mainactions from "../store/actions/main";
import { useTranslation } from 'react-i18next';
import { getComparator, stableSort } from '../UI/Table/TableFunctions';
// import OperatorComp from './OperatorComp';
// import { getAccounts } from '../../store/utility';
import * as const_vars from "../Static/const_vars";
import WaagenTableToolbar from './Table/WaagenTableToolbar';
import WaagenTableHead from './Table/WaagenTableHead';
import Waageninhalt from './Waageninhalt';
import ApiExamples from './ApiExamples';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    margin: 16,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const Waagen = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  const scales = useSelector(state => state.scale.scales);
  const { t } = useTranslation();
    console.log(scales)

    useEffect(() => {
        dispatch(mainactions.setTitleAndMenu(t('main.Waagen'), const_vars.MENU_SCALES));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) //

    useEffect(() => {
      if (token) {
        console.log("lets get scales");
        dispatch(actions_scales.fetchScalesFast(token));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    console.log("handleRequestSort: "+isAsc+ " event: "+event)
    setOrder(
      isAsc ? 'desc' : 'asc');
      setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  

  return (
    <Paper className={classes.paper}> 
      <WaagenTableToolbar numSelected={0} />
      {/* <ApiExamples /> */}

      <Grid
        spacing={1}
        container
        direction="column"
        justify="center"
        alignItems="center">   

        <WaagenTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}/>
          
          { scales  && 
            stableSort(scales, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <Waageninhalt
                  hover
                  index={index}
                  row={row} 
                  key={index}
                />
              );
            })
          }
      </Grid>

      <TablePagination
        rowsPerPageOptions={[3, 5, 10, 25, 50]}
        component="div"
        count={scales.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage} 
      />
    </Paper>
  );  
}


export default Waagen;