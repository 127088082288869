import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, IconButton } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const Product = (props) => {


  const addToShop = (item) => {
    console.log("wieder aktivieren - add item to cart!"); //, item);

  }

  return (
      <Card sx={{ maxWidth: 345 }}>
          <CardMedia
              component="img"
              // alt="oh bee hive"
              height="230"
              image={props.img}
          />
          <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {props.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                  {props.desc} 
              </Typography>
          </CardContent>
          <CardActions>
              <Grid container alignItems="center">
                  {/* <Grid item>
                      <Button size="small">Details</Button>
                  </Grid> */}
                  <Grid item xs />
                  <Grid item>
                    Out of Stock 
                      <IconButton color="primary" aria-label="add to shopping cart" onClick={() => addToShop(props)}>
                          <AddShoppingCartIcon />
                      </IconButton>
                  </Grid>
              </Grid>
          </CardActions>
      </Card>
  );
}

export default Product;