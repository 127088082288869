import React from 'react';
import {  Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';

const SicherAbfrage = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Typography style={{ paddingLeft: 8 }}>{t('main.Sicher') + '? '}</Typography>
            <IconButton onClick={props.continue} size="medium" aria-label="delete"><DoneIcon /></IconButton >
            <IconButton onClick={props.abbrechen} size="medium" aria-label="delete"><BlockIcon /></IconButton >
        </React.Fragment>
    );
}

export default SicherAbfrage;