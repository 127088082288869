import React from 'react';
import 'moment-timezone';
import 'moment/locale/de';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, IconButton, Collapse, Button, Card, Divider, DialogContent, TextField } from '@mui/material';
import { alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { deepOrange, lime, teal } from '@mui/material/colors';
import axios from '../axios-modbee';
import { useSelector, useDispatch } from 'react-redux';
import * as actions_standort from "../store/actions/standort";
import moment from 'moment'
import DialogUniversal from '../UI/DialogUniversal/DialogUniversal';
import DialogHeaderX from '../UI/DialogUniversal/DialogHeaderX';
import Hivesbearbeiten from './Hivesbearbeiten';

const Hiveinhalt = (props) => {
    const { row, index } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [addBemerkung, setAddBemerkung] = React.useState(false);
    const [bemerkung, setBemerkung] = React.useState('')
    const standorte = useSelector(state => state.standort.standorte);
    const [moveHives, setMoveHives] = React.useState(false)
    const dispatch = useDispatch()

    let rowClass = "";
    if (index % 2 === 0) {
        rowClass = classes.tableRowEven;
    } else {
        rowClass = classes.tableRowOdd;
    }

    const addRemark = () => {
        if (bemerkung !== '') {
            // const config = {
            //     daten: {
            //         "bemerkung": bemerkung,
            //     },
            //     crossdomain: true
            // };
            // axios.post('/standort/addBemerkung' + row.id_standort, config)
            //     .then(res => {

            const tempA = [...standorte]
            const index = tempA.findIndex(fff => fff.id_standort === row.id_standort)
            const tempB = [...tempA[index].bemerkung]
            tempB.unshift({ timestamp: new Date(), bemerkung: bemerkung })

            tempA[index].bemerkung = tempB;
            dispatch(actions_standort.fetchStandorteSuccess(tempA));
            setBemerkung('')
            setAddBemerkung(false)
            //console.log("got edit standort answer: ", res);
            // })
            // .catch(err => {
            //     console.log("got edit standort error: ", err);
            // });
        }
    }

    return (
        <React.Fragment>
            <Grid
                container
                spacing={1}
                direction="column">

                <Grid
                    className={classNames(classes.tableRow, rowClass)}
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    onClick={() => setOpen(!open)}>
                    <Grid item xs={12} sm={1}>
                        <Typography variant="subtitle1" className={classes.gridtext}>
                            {row.nummer}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Typography variant="subtitle1" className={classes.gridtext}>
                            {row.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5} >

                        <Grid container direction="row"
                            alignItems="center"
                            justify="center" >
                            <Grid item xs={12}>
                                {row.stockkarten && row.stockkarten.length !== 0 ? <Typography variant="caption" >
                                    {/* {id_eintrag: 1, datum: '2022-03-25T21:50:18.000Z', besetztewaben: 14, bebrutetewaben: 8, sanftmut: 4, wabensitz: 3, schwarmneigung: 4, leerwaben: -1, mittelwände: 2, brutaben: -1, bienenkg: 0, honigkg: 0, zuckerkg: 0, anmerkung: 'brutentnahme für ableger'}, */}

                                    Datum: {row.stockkarten[0].besetztewaben} | besetzt: {row.stockkarten[0].besetztewaben} | bebrütet: {row.stockkarten[0].bebrutetewaben} |
                                    Sanftmut: {row.stockkarten[0].sanftmut} | Wabensitz: {row.stockkarten[0].wabensitz} | Schwarmneigung: {row.stockkarten[0].schwarmneigung} |
                                    Leerwaben: {row.stockkarten[0].leerwaben} | Mittelwände: {row.stockkarten[0].mittelwände} | Brutwaben: {row.stockkarten[0].brutaben} | Anmerkung: {row.stockkarten[0].anmerkung}
                                </Typography> :
                                    <Typography variant="caption" >Noch keine Stockkarte ausgefüllt</Typography>}
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm>
                        <Typography variant="caption" className={classes.gridtext}>
                            {row.bemerkung.length === 0 ? '-' : moment(new Date(row.bemerkung[0].timestamp)).format(t('main.DateTimeFormat')) + ': ' + row.bemerkung[0].bemerkung}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Grid>
                </Grid>
                {
                    // VERSTECKTE ROW - mit einzelnen Chargern!
                }
                <Grid item container xs={12}>
                    <Grid item xs={12} container direction="column">
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Grid
                                container>
                                <Grid item xs={1} />
                                <Grid item xs>
                                    <Card style={{ padding: 16, marginBottom: 8, backgroundColor: '#eaeaea' }}>
                                        <Grid
                                            container>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}><Typography>Details</Typography></Grid>
                                                    <Grid item xs={6}>
                                                        <Typography>Funktionen</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} style={{ padding: 4 }}><Divider /></Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                        <Grid container>
                                                        <Grid item xs={12}><Hivesbearbeiten hive={row}/> </Grid>
                                                            <Grid item xs={12}>Alle Bemerkungen: </Grid>
                                                            {row.bemerkung.map((a, index) => {
                                                                return (
                                                                    <Grid item xs={12} key={index}>
                                                                        <Typography variant="caption" className={classes.gridtext}>
                                                                            {moment(new Date(a.timestamp)).format(t('main.DateTimeFormat')) + ': ' + a.bemerkung}
                                                                        </Typography>
                                                                    </Grid>
                                                                );
                                                            })}

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Button size="small" variant="outlined" onClick={() => setAddBemerkung(true)}>Bemerkung hinzufügen</Button>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ paddingTop: 8 }}>
                                                                <Button size="small" variant="outlined" onClick={() => setMoveHives(true)}>Stockkarte</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment >
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        verticalAlign: 'bottom',
        height: 18,
        width: 18,
        padding: theme.spacing(0, 0),
        lineHeight: 1,
    },
    tableRow: {
        borderTop: `1px solid ${theme.palette.divider}`,
        // borderBottom: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2, 4),
        '&:hover': {
            backgroundColor: alpha(teal[50], 0.3),
            cursor: "pointer"
        },
    },
    tableRowEven: {
        backgroundColor: alpha(lime[50], 0.3),
    },
    tableRowOdd: {
        // backgroundColor: teal[50],
        // backgroundColor: fade(teal[50], 0.3),
    },
    gridtext: {
        padding: theme.spacing(0, 0),
        lineHeight: 1,
    },
    ok: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.warning.main,
    },
    charge_icon: {
        /* margin-top: 2px; */
        color: '#777 !important',
        paddingTop: 0,
        height: 14,
        width: 14,
    },

    charge_icon_info: {
        /* margin-top: 2px; */
        color: '#ffd600 !important',
        paddingTop: 0,
        height: 14,
        width: 14,
    },

    charge_icon_warn: {
        color: '#ff6d00 !important',
        paddingTop: 0,
        height: 14,
        width: 14,
    },

    charge_icon_critical: {
        color: '#B71C1C !important',
        paddingTop: 0,
        height: 14,
        width: 14,
    },
    info_charge: {
        color: '#ffd600 !important',
        fontWeight: 900,
    },
    warning_charge: {
        color: '#ff6d00 !important',
        fontWeight: 900,
    },
    critical_charge: {
        color: '#B71C1C !important',
        fontWeight: 900,
    },
    remove_bottom_margin: {
        marginBottom: 0,
        lineHeight: 1.1,
    },
    warning: {
        backgroundColor: alpha(deepOrange[50], 1)
    },
    editable: {
        '&:hover': {
            backgroundColor: '#e0e0e0',
            cursor: 'pointer',
            "& .showedit": {
                display: "inline"
            }
        },
    },
    editIcon: {
        color: '#777',
        display: 'none'
    }
}));


export default Hiveinhalt;