import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, IconButton, Collapse, Tooltip, Button, CircularProgress, Card, Divider, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import axios from '../../../axios-modbee';
import { useSelector, useDispatch } from 'react-redux';
import * as actions_scales from "../../../store/actions/scales";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SicherAbfrage from '../../../UI/SicherAbfrage/SicherAbfrage';
import LaunchIcon from '@mui/icons-material/Launch';
import { GreenDotBadge } from '../../../UI/GreenDotBadge/GreenDotBadge';
import DialogUniversal from '../../../UI/DialogUniversal/DialogUniversal';
import Kalibrierung from '../Kalibrierung';
const KalibrierungsEintrag = (props) => {
    const [sicher, setSicher] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [orderBy, setOrderBy] = React.useState('');
    // const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const calibrations = useSelector(state => state.scale.calibrations);
    const [showCalib, setShowCalib] = React.useState(false);

    const [edit, setEdit] = React.useState(false);
    const [name, setName] = React.useState('');
    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch()

    const deleteEintrag = () => {
        setSicher(false)
        const config = {
            daten: {
                "status": 3,
            }, crossdomain: true
        };

        axios.put('/calibration/' + props.data.id_kalibrierung, config)
            .then(res => {
                console.log(res.data)
                const tempA = [...calibrations]
                const index = tempA.findIndex(fff => fff.id_kalibrierung === props.data.id_kalibrierung)

                tempA.splice(index, 1);
                dispatch(actions_scales.fetchCalibrationsSuccess(tempA))
            })
            .catch(err => {
                console.log("got sendChargerFw error: ", err);
            });
    }

    const sicherr = (e) => {
        e.stopPropagation();
        setSicher(true)
    }
    const showCalibToggle = (e) => {
        e.stopPropagation();
        setShowCalib(true)
    }

    return (
        <React.Fragment>
            <Grid
                className={classNames(classes.root, classes.tableRow)}
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center" onClick={() => setOpen(!open)}>

                <Grid item xs={1} style={{ textAlign: 'center' }}>
                    {props.data.status === 1 ? <Tooltip title={t('main.Kalibrierung abgeschlossen')}><CheckIcon fontSize="small" /></Tooltip> :
                        props.data.status === 2 ? <Tooltip title={t('main.Kalibrierung abgebrochen')}><CancelIcon fontSize='small' /></Tooltip> :
                            <Tooltip title={t('main.Kalibrierung läuft')}>
                                <GreenDotBadge
                                    onClick={(showCalibToggle)}
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                >
                                    <LaunchIcon />
                                </GreenDotBadge>
                            </Tooltip>}
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="caption" className={classes.gridtext}>
                        {moment(new Date(props.data.create_time)).format(t('main.DateTimeFormat'))}
                    </Typography>
                </Grid>
                <Grid item xs={2} >
                    <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                        {props.data.typ === 1 ? 'Genau' : 'Schnell'}
                    </Typography>
                </Grid>
                <Grid item xs={2} >
                    <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                        {props.data.steps.length}
                    </Typography>
                </Grid>
                <Grid item xs >
                    <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                        9,7
                    </Typography>
                </Grid>
                <Grid item >
                    {sicher ? <SicherAbfrage continue={deleteEintrag} abbrechen={() => setSicher(false)} /> :
                        <IconButton onClick={sicherr}><DeleteIcon fontSize='small' /></IconButton>}
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <Collapse in={open} timeout="auto" unmountOnExit>

                    <Grid container>
                        <Grid item xs={12} style={{ paddingBottom: 8 }}>
                            <Divider />
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs>
                            <Grid container>

                                <Grid item xs={1} style={{ paddingRight: 16 }}>
                                    <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                                        Schritt
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} style={{ paddingRight: 16 }}>
                                    <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                                        Gewicht
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} style={{ paddingRight: 16 }}>
                                    <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                                        Dauer
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} style={{ paddingRight: 16 }}>
                                    <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                                        Messintervall
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} style={{ paddingRight: 16 }}>
                                    <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                                        Startzeit
                                    </Typography>
                                </Grid>
                                {props.data.steps.map((b, index) => {
                                    return (
                                        <React.Fragment key={index} >
                                            <Grid item xs={1} style={{ paddingRight: 16 }}>
                                                <Grid container>
                                                    <Grid item style={{ paddingTop: 2 }}>
                                                        {b.status === 1 ? <Tooltip title={t('main.Schritt abgeschlossen')}><CheckIcon fontSize="small" /></Tooltip> :
                                                            b.status === 2 ? <Tooltip title={t('main.Schritt abgebrochen')}><CancelIcon fontSize='small' /></Tooltip> :
                                                                <Tooltip title={t('main.Schritt läuft')}><CircularProgress color="secondary" size={24} /></Tooltip>}
                                                    </Grid>
                                                    <Grid item xs style={{ paddingLeft: 8 }}>
                                                        <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                                                            {index + 1}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                            <Grid item xs={2} style={{ paddingRight: 16 }}>
                                                <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                                                    {b.weight} Gramm
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ paddingRight: 16 }}>
                                                <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                                                    {b.duration}  Sekunden
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ paddingRight: 16 }}>
                                                <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                                                    {b.interval} Sekunden
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} style={{ paddingRight: 16 }}>
                                                <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                                                    {moment(new Date(b.start_time)).format(t('main.DateTimeFormat'))}
                                                </Typography>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 8 }}>
                            <Divider />
                        </Grid>
                    </Grid>

                </Collapse>
            </Grid >
            <DialogUniversal open={showCalib} close={() => setShowCalib(false)} maxWidth={'sm'} fullWidth>
                <Kalibrierung waage={props.scale} kalibrierung={props.data} close={() => setShowCalib(false)} />
            </DialogUniversal>
        </React.Fragment >
    );
}

const useStyles = makeStyles((theme) => ({

    root: {
        '&:hover': {
            backgroundColor: '#e0e0e0',
            cursor: 'pointer',
        },
    },
    editIcon: {
        color: '#777',
        display: 'none'
    },
    gridtext: { fontWeight: 500 }
}));


export default KalibrierungsEintrag;