import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, IconButton, Collapse, Tooltip, Button, CircularProgress, Card, Divider, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import axios from '../../../axios-modbee';
import { useSelector, useDispatch } from 'react-redux';
import * as actions_scales from "../../../store/actions/scales";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import KalibrierungsEintrag from './KalibrierungsEintrag';

const Kalibrierungen = (props) => {
    const [myCalibs, setMyCalibs] = React.useState([]);
    const calibrations = useSelector(state => state.scale.calibrations);
    const dispatch = useDispatch()
    const classes = useStyles();
    const { t } = useTranslation();



    useEffect(() => {
        if (calibrations) {
            const tempA = calibrations.filter(a => a.id_scale === props.scale.id_scales && a.status < 3)
            setMyCalibs(tempA)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calibrations]);


    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Grid container style={{ paddingTop: 16 }}>
                    <Grid item xs={12}><Typography>{"Kalibrierungen (" + myCalibs.length + ")"}</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: 4 }}><Divider /></Grid>
            <Grid item xs={12}>
                <Grid
                    className={classNames(classes.root, classes.tableRow)}
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center">

                    <Grid item xs={1} style={{textAlign:'center'}}>
                        <Typography variant="caption" className={classes.gridtext}>
                            Status
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="caption" className={classes.gridtext}>
                            Startzeit
                        </Typography>
                    </Grid>
                    <Grid item xs={2} >
                        <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                            Typ
                        </Typography>
                    </Grid>
                    <Grid item xs={2} >
                        <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                            Schritte
                        </Typography>
                    </Grid>
                    <Grid item xs={2} >
                        <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                            Genauigkeit
                        </Typography>
                    </Grid>

                    {myCalibs.map((a, index) => {
                        return (
                            <Grid item xs={12} key={index}>
                                <KalibrierungsEintrag scale={props.scale} index={index} data={a} />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({

    editable: {
        '&:hover': {
            backgroundColor: '#e0e0e0',
            cursor: 'pointer',
            "& .showedit": {
                display: "inline"
            }
        },
    },
    editIcon: {
        color: '#777',
        display: 'none'
    },
    gridtext: { fontWeight: 500 }
}));


export default Kalibrierungen;