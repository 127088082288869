import React from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import axios from '../../../axios-modbee';
import { useTranslation } from 'react-i18next';
import const_vars from "../../../Static/const_vars";
import { LoadingButton } from '@mui/lab';


const Schritt1 = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false)

    const starten = () => {
        setLoading(true)
        props.setSperre(true)


        const config = {
            "status": 0,
            "typ": props.typ,
            "id_scale": props.waage.id_scales,
            "comment": "",
            crossdomain: true
        };

        axios.post('/calibration', config)
            .then(res => {
                console.log(res.data)
                props.setid_calibration(res.data[0])
                const config = {
                    data: {
                        "befehl": const_vars.KALIBRIERUNGS_BEFEHL,
                        "cmd": const_vars.GW_STATUS_SHOULD_START_CALIBRATION,
                        "id_scales": props.waage.id_scales,
                    },
                    crossdomain: true
                };
                axios.put('/scale/custom/' + props.waage.id_scales, config)
                    .then(res => {
                        props.next()
                        console.log("got sendCustomWriteToGateway answer: ", res);
                    })
                    .catch(err => {
                        console.log("got sendChargerFw error: ", err);
                    });
            })
            .catch(err => {
                console.log("got sendChargerFw error: ", err);
            });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                    Im ersten Schritt wird die Verbindung zur Waage aufgebaut.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="flex-end">
                    <Grid item xs />
                    <Grid item>
                        <LoadingButton
                            loading={loading}
                            variant="outlined"
                            onClick={starten}
                        >
                            {t('main.Jetzt Verbinden')}
                        </LoadingButton>
                    </Grid>
                    <Grid item xs />
                </Grid>
            </Grid>
        </Grid >
    );
}

export default Schritt1