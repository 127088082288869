import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, IconButton, Collapse, Tooltip, Button, CircularProgress, Card, Divider, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import axios from '../../axios-modbee';
import { useSelector, useDispatch } from 'react-redux';
import * as actions_scales from "../../store/actions/scales";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

const EditSomething = (props) => {
    const [edit, setEdit] = React.useState(false);
    const [name, setName] = React.useState(props.inhalt);
    const classes = useStyles();
    const { t } = useTranslation();

    const saveIt = () => {
        if(name.trim().length !== 0) {
            setEdit(false)    
            props.saveIt(name)
        }
    }

    if (edit) return (
        <Grid container alignItems="center">
            <Grid item xs><TextField variant="standard" autoFocus
                onChange={(e) => setName(e.target.value)}
                id="name"
                label={props.title}
                value={name}
                error={name.length === 0}
                fullWidth /></Grid>
            <Grid item style={{ paddingLeft: 8 }}><Tooltip title={t('main.Speichern')}><IconButton onClick={saveIt}><SaveIcon /></IconButton></Tooltip></Grid>
        </Grid>
    ); else return (

        <Grid container className={classes.editable} onClick={() => setEdit(true)}>
            <Grid item><Typography>{props.title}: {name}</Typography></Grid>
            <Grid item style={{ maxHeight: 24, paddingLeft: 8 }}><Tooltip title={t('main.Bearbeiten')}><EditIcon size='small' className={classNames("showedit", classes.editIcon)} /></Tooltip></Grid>
        </Grid>

    );
}

const useStyles = makeStyles((theme) => ({

    editable: {
        '&:hover': {
            backgroundColor: '#e0e0e0',
            cursor: 'pointer',
            "& .showedit": {
                display: "inline"
            }
        },
    },
    editIcon: {
        color: '#777',
        display: 'none'
    }
}));


export default EditSomething;