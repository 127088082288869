import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Alert, alpha, Box, Button, CardMedia, Divider, Grid, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import garden from '../../Static/garden.jpg';
import dumbell from '../../Static/dumbell.jpg';
import axios from '../../axios-modbee';
import { useTranslation } from 'react-i18next';
import Countdown from '../../UI/Countdown/Countdown';





const Anleitung = (props) => {
    const classes = useStyles()
    const { t } = useTranslation();
    const [gewicht, setGewicht] = React.useState('')
    const [startzeit, setStartZeit] = React.useState(null)

    useEffect(() => {
        if (props.waage) {
            if (props.waage.id_kalibrierung) {
                setGewicht(props.waage.weight)
                setStartZeit(new Date(props.waage.start_time))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const starten = () => {
        const calibration = {

            id_scale: props.waage.id_scales,
            typ: props.typ,
            step: 1,
            weight: gewicht
        };
        setStartZeit(new Date())
        console.log("add calibration: ", calibration);
        axios.post('/calibration', calibration)
            .then(res => {
                console.log("add calibration result: ", res.data);
            }).catch(err => {
                console.error("err: " + err);
            });
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Card sx={{ display: 'flex' }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 151 }}
                        image={garden}
                        alt="Live from space album cover"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h5">
                                {props.typ === 1 ? t('main.Genaue Kalibrierung') : t('main.Schnelle Kalibrierung')}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                Bitte positionieren Sie die Waage im Schatten blabla
                            </Typography>
                        </CardContent>
                    </Box>

                </Card>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 8 }}>
                <Card sx={{ display: 'flex' }}>

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Legen Sie ein bekanntes Gewicht (ca. 5kg, am BEsten mit der Küchenwaage überprüfen) auf die Waage, tragen das Gewicht ein und Starten die Kalibrierung
                                    </Typography>
                                </Grid>
                              
                                
                            </Grid>

                        </CardContent>
                    </Box>
                    <CardMedia
                        component="img"
                        sx={{ width: 151 }}
                        image={dumbell}
                        alt="Live from space album cover"
                    />
                </Card>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        '&:hover': {
            backgroundColor: alpha(grey[50], 0.3),
            cursor: "pointer"
        },
    },
}));
export default Anleitung