import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const fakeHives = [
  {
    id_hive: 1,nummer: 14, name: 'King of Hives', bemerkung: [{ timestamp: '2022-03-25T21:50:18.000Z', bemerkung: 'sturmschäden beseitigt' }, { timestamp: '2022-01-25T21:50:18.000Z', bemerkung: 'Winterkontrolle, hat alles gepasst' }],
    stockkarten: [
      {id_eintrag: 1, datum: '2022-03-25T21:50:18.000Z', besetztewaben: 14, bebrutetewaben: 8, sanftmut: 4, wabensitz: 3, schwarmneigung: 4, leerwaben: -1, mittelwände: 2, brutaben: -1, bienenkg: 0, honigkg: 0, zuckerkg: 0, anmerkung: 'brutentnahme für ableger'},
    ]
  },
  { id_hive: 2, nummer: 17, name: 'Räuberziel', bemerkung: [{ timestamp: '2022-03-28T15:50:18.000Z', bemerkung: 'zuletzt ausgeraubt' }] }
]
const initialState = {
  hives: fakeHives,
  error: null
};

const hivesSuccess = (state, action) => {
  console.log("hivesSuccess: ", action.hives);
  return updateObject(state, {
    hives: action.hives,
    error: null,
  });
};

const hivesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HIVES_SUCCESS: return hivesSuccess(state, action);
    case actionTypes.HIVES_FAIL: return hivesFail(state, action);
    default: return state;
  }
};


export default reducer;