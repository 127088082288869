import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as auth_actions from './store/actions/index';
import 'typeface-roboto';
import * as actions_accounts from "./store/actions/accounts";
import * as scale_actions from "./store/actions/scales";
import worker from './Static/webworker';
import * as actions_scale from "./store/actions/scales";
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { app } from './firebase'; // NICHT LÖSCHEN WICHTIG
// import webworker from 'workerize-loader!./webworker'; // eslint-disable-line import/no-webpack-loader-syntax
// import webworker from 'worker-loader!./webworker.js'; // eslint-disable-line import/no-webpack-loader-syntax
import ScaleWorker from 'worker-loader!./scale.worker.js'; // eslint-disable-line import/no-webpack-loader-syntax
import { Snackbar } from '@mui/material';

import Layout from './LandingPage/Layout'
import Routing from './Router';
import { theme } from './Theme'
const App = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const userId = useSelector(state => state.auth.userId);
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const scaleWorker = useSelector(state => state.main.scaleWorker);
  const [loadingScales, setLoadingScales] = useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [infoTotal, setInfoTotal] = React.useState(0);
  const updateAvailable = useSelector(state => state.serviceWorker.serviceWorkerUpdated);

  const primary = useSelector(state => state.main.themeColors.primary);
  const secondary = useSelector(state => state.main.themeColors.secondary);
  const primaryText = useSelector(state => state.main.themeColors.primaryText);
  const secondaryText = useSelector(state => state.main.themeColors.secondaryText);
  const highlight = useSelector(state => state.main.themeColors.highlight);
  const appBar = useSelector(state => state.main.themeColors.appBar);
  const sideBar = useSelector(state => state.main.themeColors.sideBar);
  const ertrag = useSelector(state => state.main.themeColors.ertrag);
  const weight = useSelector(state => state.main.themeColors.weight);
  const temperature = useSelector(state => state.main.themeColors.temperature);
  const voltage = useSelector(state => state.main.themeColors.voltage);
  const humidity = useSelector(state => state.main.themeColors.humidity);

  const colors = {
    colors: { ...theme.palette.colors,
      appBar: appBar,
      highlight: highlight,
      sideBar: sideBar,
      ertrag: ertrag,
      weight: weight,
      temperature: temperature,
      voltage: voltage,
      humidity: humidity,
    }
  }
  const primarys = {
    primary: { ...theme.palette.primary,
      main: primary,
      text: primaryText
    }
  }
  const secondarys = {
    secondary: {...theme.palette.primary,
      main: secondary,
      text: secondaryText
    }
  }
  const palette = { ...theme.palette, ...colors, ...primarys, ...secondarys }
  const myTheme = { ...theme, palette: palette }

  console.log("updateAvailable: ", updateAvailable);
  // console.log("token: ", token);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };


  // useEffect(() => {
  //   dispatch(actions_main.fetchState())
  // },[dispatch])

  // const [scaleWorker, setScaleWorker] = useState();
  useEffect(() => {
    // const scaleWorkerInstance = new ScaleWorker(worker); //SearchWorker();
    // const scaleWorkerInstance = new ScaleWorker(ScaleWorker); //SearchWorker();

    const scaleWorkerInstance = new Worker(new URL('./scale.worker.js', import.meta.url));
    
    dispatch(scale_actions.setScaleWorker(scaleWorkerInstance));

    scaleWorkerInstance.onerror = (event) => {
      console.error("scaleWorkerInstance.onerror: ", event);
    }

    scaleWorkerInstance.onmessage = (event) => {
      console.log("scaleWorkerInstance.onmessage: ", event);

      if (event && event.data) {
        //Daten vom Webworker müssen an richtiger Stelle in der indexed DB gespeichert werden!
        //TODO Bearbeitung auslagern          
        if (event.data.scales) {
          // setScalesList(event.data.scales);
          localStorage.setItem("scales", JSON.stringify(event.data.scales));
          if (event.data.new_data === 1) {
            console.log("fetchScalesSuccess.. now dispatch!");
            dispatch(actions_scale.fetchScalesSuccess(event.data.scales));
          }
        } else if (event.data.type) {
          if (event.data.type === "info") {
            if (event.data.nr === 0) {
              console.log("scaledata successfully updated in worker");
              setInfoTotal(0);
            } else if (event.data.nr === 1) {
              console.log("scaledata is beeing downloaded ...", event.data.count, " to go..");
              if (event.data.count > 100) {
                //Nur anzeigen wenn sichs wirklich auszahlt...
                setInfoTotal(event.data.count);
              } else {
                setInfoTotal(0);
              }
            } else {

            }
          } else if (event.data.type === "error") {
            if (event.data.error_type === "scales") {
              //Reload mit neuem TOKEN machen!
              //ACHTUNG - kann endlosschleife werden?!?
              //TODO eventuell delay einbauen
              if (event.data.nr === 401) {
                console.log("scaledata error loading. need new token");
                //get new token and fire after new token is ready!
                dispatch(auth_actions.authRefreshToken());
                setTimeout(function () {
                  console.error("scaledata error loading. setLoadingScales false");
                  setLoadingScales(false);
                }, 3000);
              }
            }
          } else if (event.data.type === "update") {
            if (!infoOpen) {
              setInfoOpen(true);
            }
            // setInfoNr(event.data.item);
            // setInfoTotal(event.data.from);
            // setInfoStep(event.data.step);
            // setInfoStepTotal(event.data.step_from);

            console.log("update worker: ", event.data.data);
            // console.log("new_data fetchScaleDataAll: ", new_data);
            // const insertWeightData = async (new_data) => {

            //   // const db = await openDB("scale_db"+userId, 1, {});
            //   // const weightData = await db.getAll('weight_data');
            //   // let store;              
            //   // const tx = db.transaction('weight_data', 'readwrite');
            //   // store = tx.store; //.index('date');                

            //   // console.log("weightData from db: ", weightData);

            //   // for ( let index3 in new_data ) {
            //   //   console.log("insert "+(index3)+"/"+new_data.length+" to weight_data in db: ");//, JSON.stringify(new_data[index3]));
            //   //   // await db.add('weight_data', new_data[index3]);
            //   // }   
            // }   
            // insertWeightData(event.data.data);
          } else {
            console.log("unknown msg with type scaleWorkerInstance: ", event.data);
          }
        } else {
          console.log("unknown msg scaleWorkerInstance: ", event.data);
        }
      }
    };
    return () => {
      scaleWorkerInstance.terminate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, infoOpen, userId]);



  // eslint-disable-next-line 
  useEffect(() => {
    // onTryAutoSignup();
    if (!loggedIn) {
      console.log("App.js -> not logged in");
      //TODO - automatisch einloggen mit gespeicherten Daten?!?!
    } else {
      console.log("App.js -> logged in!");

    }
  }, [loggedIn]);


  useEffect(() => {
    console.log("scaleWorker loggedIn: ", loggedIn, " scaleW: ", scaleWorker, " loadiung: ", loadingScales);
    if ((loggedIn) && (scaleWorker) && (!loadingScales)) {


      setLoadingScales(true);
      console.log("scaleWorker start");
      try {
        scaleWorker
          .postMessage({
            token: token,
            uid: userId,
            typ: 'scales',
            new_data: 0
            // data: val
          });
      } catch (error) {
        console.log('error com scaleworker ', error)
      }
      //aktuellen User abfragen vom Server
      dispatch(actions_accounts.fetchUserAccount(token))
    } else {
      //TODO User zurücksetzen!
      // dispatch(actions_accounts.fetchUserAccount(token))
      // dispatch(actions_accounts.fetchAccounts(token))
    }
  }, [loggedIn, token, scaleWorker, userId, loadingScales, dispatch]);


  useEffect(() => {
    if (loggedIn) {
      //aktuellen User abfragen vom Server
      dispatch(actions_accounts.fetchUserAccount(token))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, token]);



  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={myTheme}>
        <SnackbarProvider maxSnack={3}>
          <Layout>
            <Routing />
          </Layout>
        </SnackbarProvider>
      </ThemeProvider>

      <Snackbar
        open={infoTotal > 0}
        message={"Datensynchronisierung für Statistik - noch " + infoTotal + " Datensätze"}
        onClose={handleClose} >
      </Snackbar>
    </StyledEngineProvider>
  );
}


export default App;