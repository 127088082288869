
export const MAIN_CHANGE_TITLE = 'MAIN_CHANGE_TITLE';
export const MAIN_CHANGE_TITLE_ONLY = 'MAIN_CHANGE_TITLE_ONLY';

export const MAIN_SET_HELPER_ELEMENT = 'MAIN_SET_HELPER_ELEMENT';
export const MAIN_SET_MENU_OPEN = 'MAIN_SET_MENU_OPEN';
export const MAIN_SELECT_SCALE = 'MAIN_SELECT_SCALE';
export const MAIN_SELECT_MENU= 'MAIN_SELECT_MENU';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CHANGE_MAIL = 'AUTH_CHANGE_MAIL';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_CHANGE_REMEMBER = 'AUTH_CHANGE_REMEMBER';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS';

export const FETCH_SCALES_START = 'FETCH_SCALES_START';
export const FETCH_SCALES_SUCCESS = 'FETCH_SCALES_SUCCESS';
export const FETCH_SCALES_FAIL = 'FETCH_SCALES_FAIL';

export const FETCH_SCALE_DATA_START = 'FETCH_SCALE_DATA_START';
export const FETCH_SCALE_DATA_SUCCESS = 'FETCH_SCALE_DATA_SUCCESS';
export const FETCH_SCALE_DATA_SUCCESS_ALL = 'FETCH_SCALE_DATA_SUCCESS_ALL';
export const FETCH_SCALE_DATA_FAIL = 'FETCH_SCALE_DATA_FAIL';


export const CHANGE_SCALE_DATA = 'CHANGE_SCALE_DATA';
export const UPDATE_ZOOM_STATE = 'UPDATE_ZOOM_STATE';

export const FETCH_ALL_SCALES = 'FETCH_ALL_SCALES';

export const FETCH_ACCOUNTS_START = 'FETCH_ACCOUNTS_START';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_FAIL = 'FETCH_ACCOUNTS_FAIL';
export const FETCH_USER_ACCOUNT_SUCCESS = 'FETCH_USER_ACCOUNT_SUCCESS';


export const FETCH_FIRMWARES_START = 'FETCH_FIRMWARES_START';
export const FETCH_FIRMWARES_SUCCESS = 'FETCH_FIRMWARES_SUCCESS';
export const FETCH_FIRMWARES_FAIL = 'FETCH_FIRMWARES_FAIL';


export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR';
export const SET_SCALE_WORKER = 'SET_SCALE_WORKER';
export const SET_SCALE_DATA = 'SET_SCALE_DATA';

export const SCALES_START = 'SCALES_START';
export const SCALES_SUCCESS = 'SCALES_SUCCESS';
export const SCALES_FAIL = 'SCALES_FAIL';

export const CALIBRATIONS_START = 'CALIBRATIONS_START';
export const CALIBRATIONS_SUCCESS = 'CALIBRATIONS_SUCCESS';
export const CALIBRATIONS_FAIL = 'CALIBRATIONS_FAIL';

export const FETCH_STATE = 'FETCH_STATE';
export const UPDATE_SERVICEWORKER = 'UPDATE_SERVICEWORKER';

export const SET_CART = "SET_CART";

export const STANDORT_SUCCESS = 'STANDORT_SUCCESS';
export const STANDORT_FAIL = 'STANDORT_FAIL';

export const HIVES_SUCCESS = 'HIVES_SUCCESS';
export const HIVES_FAIL = 'HIVES_FAIL';
