import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Scale from "./Scale/Scale";
import * as actions from "../store/actions/scales";
import { Divider, Paper } from '@mui/material';
import * as const_vars from "../Static/const_vars";
import { useTranslation } from 'react-i18next';
import * as mainactions from "../store/actions/main";

const Scales = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(mainactions.setTitleAndMenu(t('main.Waagen'), const_vars.MENU_SCALES));
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []) //

  let { typ } = props;
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  // const loading = useSelector(state => state.main.loading);
  const scaleWorker = useSelector(state => state.main.scaleWorker);
  const userId = useSelector(state => state.auth.userId);
  const scales = useSelector(state => state.scale.scales);
  
  let storedScales = [];
  try {
    storedScales = JSON.parse(localStorage.getItem('scales'));
  } catch (e) {
    storedScales = [];
    // alert(e); // error in the above string (in this case, yes)!
  }

  const returnScale = (scale, index, rowLen) => {

    if (rowLen === index + 1) {
      // last one
      return (
        <div
          key={"div" + scale.id}>
          <Scale
            key={scale.id}
            typ={typ}
            scale={scale} />
        </div>
      );
    } else {
      return (
        <div
          key={"div" + scale.id_scales}>
          <Scale
            key={scale.id_scales}
            typ={typ}
            scale={scale} />
          <Divider
            key={"divider" + scale.id_scales}
            light />
        </div>
      );
    }
  }
  // console.log("render scales scales: ",  storedScales);

  useEffect(() => {
    if (token !== undefined) {
      console.log("lets get scales");

      dispatch(actions.fetchScalesFast(token));

      //Zu Beginn müssen von dieser Waage die aktuellen Daten geholt werden
      // dispatch( actions.fetchScales(token) );
      // dispatch( fetchScaleDataAll(token, scale.id_scales) );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (typ === 2) {
    //Wurde vom Dashboard aufgerufen
  } else {
    //Scale-Menü -> mehr Optionen bei Scales anzeigen
    typ = 1;
  }

  let my_scales = <div />;
  // if ( !loading ) {
  if (scales && scales.length > 0) {

    const rowLen = scales.length;
    my_scales = scales.map((scale, index) => {
      return returnScale(scale, index, rowLen);
    }
    )
  } else if (storedScales && storedScales.length > 0) {
    const rowLen = storedScales.length;
    my_scales = storedScales.map((scale, index) => {
      return returnScale(scale, index, rowLen);
    }
    )


  } else {
    console.log("scales undefined!");
  }
  // }

  return (
    <Paper style={{margin: 16}}>
      {my_scales}
    </Paper>
  );
}


export default Scales;