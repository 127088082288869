import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import {  Typography, IconButton, Tooltip, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../store/actions/hives";


const HivesTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  const { t } = useTranslation();
  const hives = useSelector(state => state.hives.hives);
  const dispatch = useDispatch()


  const addHive = () => {
    const tempA = [...hives]

    tempA.unshift({id_hive: tempA[tempA.length-1].id_hive+1, nummer: tempA[tempA.length-1].nummer+1, name: 'Neuer Bienenstock',  bemerkung: [], stockkarten: []})
    dispatch(actions.fetchHivesSuccess(tempA));
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {t('main.Meine Bienenstöcke')}
        </Typography>
      )}
      <Tooltip title={t('main.Bienenstock hinzufügen')}>
        <IconButton aria-label="filter list" onClick={addHive}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            {/* <DeleteIcon /> */}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(2),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            // backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));

export default HivesTableToolbar