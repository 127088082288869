import React, { useEffect } from 'react';
import Scales from "../Scales/Scales";
import * as const_vars from "../Static/const_vars";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as actions from "../store/actions/main";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setTitleAndMenu(t('main.Dashboard'), const_vars.MENU_DASH));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 


  return (
      <Scales typ="2" />
  );
}



export default Dashboard;