import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import bees from '../../Static/bees.jpg';

const ProductSmall = ()=> {

    return (
        <Card sx={{ display: 'flex' }}>
            <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={bees}
                alt="Live from space album cover"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                        Bienen
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        Die Bienen (Apiformes oder Anthophila) sind eine Insektengruppe, in der mehrere Familien der Hautflügler (Hymenoptera) zusammengefasst werden.
                    </Typography>
                </CardContent>
            </Box>

        </Card>
    );
}

export default ProductSmall;
