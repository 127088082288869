
const initialState = {
  colorHumidity: "#9C27B0", //CC27DD  CC00CC
  colorTemperature: "#1565C0",  //0D47A1  1565C0 1976D2
  colorWeight: "#B71C1C", //C62828  D32F2F E53935
  colorVoltage: "#00796B", //9E9D24 AFB42B C0CA33
  colorPressure: "#00796B", //00695C  004D40
  colorXAxis: "#555",
  strokeWidthLines: 1.8,
  strokeWidthRevLines: 0.2,
  purchased: false
};

// const setMainTitle = (state, action) => {
//   return updateObject(state, {
//     title: action.title,
//     menu: action.menu
//   })
// };

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
      // case actionTypes.MAIN_CHANGE_TITLE: return setMainTitle(state, action);
      default: return state;
    }
};

export default reducer;