import React from 'react';
import 'moment-timezone';
import 'moment/locale/de';
import Moment from "react-moment";
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, IconButton, Collapse,
  Box, FormControl, Select, Button } from '@mui/material';
import AcceptIcon from '@mui/icons-material/Check';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
// import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { alpha } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getRSSIString } from '../store/utility';
import const_vars from "../Static/const_vars";
import axios from '../axios-modbee';
import ScaleStatus from './ScaleStatus.js';
import { lime, teal } from '@mui/material/colors';
import { CreateVersionNumber, ExtractVersionNumber } from "../store/utility";
import * as actions from "../store/actions/firmwares";



const ScaleAdminComp = (props) => {
  const { scale, index } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedFw, setSelectedFw] = React.useState(0);
  const token = useSelector(state => state.auth.token);
  const firmwares = useSelector(state => state.main.firmwares);
  const dispatch = useDispatch();
  
  // const { t } = useTranslation();
  // console.log("getOperator: "+JSON.stringify(account)+" und "+JSON.stringify(operators));
  // const operator = getOperator(account, operators);  

  let rowClass = "";
  if (index % 2 === 0) {
    rowClass = classes.tableRowEven;
  } else {
    rowClass = classes.tableRowOdd;
  }
  // console.log("ClientComp");
  // console.log("ScaleAdminComp  scale: "+scale);

  const handleFirmwareChange = (event) => {
    event.stopPropagation();
    // <option value={row.id_firmware} 
    // key={row.fw_number} > 
    // {"FW"+row.id_firmware+" - Version "+extractedMajor+"."+extractedMinor+"."+extractedPatch} 

    // console.log("handleFirmwareChange value: "+event.target.value);
    // console.log("handleFirmwareChange key: ", event);
    setSelectedFw(event.target.value);
  };
   
  const sendCustomWriteToScale = async (station, res_id, custom_data2) => {
    // console.log("sendCustomWriteToGateway station: "+JSON.stringify(station));
    console.log("sendCustomWriteToGateway firmwares: ", firmwares);
    let customData = 0;
    let customData2 = 0;
    let customData3 = 0;
    if (res_id === const_vars.UPDATE_FIRMWARE) { //UPDATE_FIRMWARE)
      //Prüfen ob Firmware-version eh dabei ist
      if (custom_data2 === 1) {
        console.log("sendCustomWriteToGateway 1 selectedFw: "+selectedFw);
        customData = selectedFw;      
        customData2 = custom_data2;  //1 = fw_charger
        station.update_gw_total_parts = 0;
        station.update_gw_par = 0;

        
        firmwares.forEach(fw => {

          console.log("fw: ", fw);
          if (fw.id_firmware === selectedFw) {
            const { major: extractedMajor, minor: extractedMinor, patch: extractedPatch } = ExtractVersionNumber(fw.fw_number);
            customData3 = CreateVersionNumber(extractedMajor, extractedMinor, extractedPatch);
            console.log("sendCustomWriteToGateway customData3: "+customData3);
          } else {

            console.log(fw.id_firmware+" != "+selectedFw);
          }
        }); 

      } else if (custom_data2 === 2) {
        console.log("sendCustomWriteToGateway 2 selectedFwGw: "+selectedFw);
        customData = selectedFw;      
        customData2 = custom_data2;
        station.update_gw_total_parts = 0;
        station.update_gw_par = 0;
                
        firmwares.forEach(fw => {

          console.log("fw: ", fw);
          if (fw.id_firmware == selectedFw) {
            const { major: extractedMajor, minor: extractedMinor, patch: extractedPatch } = ExtractVersionNumber(fw.fw_number);
            customData3 = CreateVersionNumber(extractedMajor, extractedMinor, extractedPatch);
            console.log("sendCustomWriteToGateway customData3: "+customData3);
          } else {

            console.log(fw.id_firmware+" != "+selectedFw);
          }
        }); 

      } else if (custom_data2 === 4) {
        console.log("Firmwaredownload GW pausieren!");
        customData = 0;      
        customData2 = custom_data2; 
      } else if (custom_data2 === 5) {
        console.log("Firmwaredownload GW fortsetzen!");
        customData = 0;      
        customData2 = custom_data2;
      } else if (custom_data2 === 6) {
        console.log("Firmwaredownload GW abbrechen!");
        customData = 0;      
        customData2 = custom_data2;
      }
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ''+token
    }        
    const config = {
      data: {
        "befehl": res_id,
        "custom_data": customData,
        "custom_data2": customData2,
        "custom_data3": customData3,
      },
      crossdomain: true
    };    
    
    axios.put( '/scale/custom/'+scale.id_scales, config, {
    // axios.put( '/station/custom/'+res_id, config, { //leshan
          headers: headers
        })
        .then( res => {            
            console.log("got sendCustomWriteToGateway answer: ", res);
            dispatch(actions.fetchFirmwares(token));
        })
        .catch( err => {
            console.log("got sendChargerFw error: ", err);
        } );
  }


  const { major: extractedMajor, minor: extractedMinor, patch: extractedPatch } = ExtractVersionNumber(scale.fw_scale);
  // console.log("ScaleAdminComp scale.fw_number: ", scale ," extractedMinor: "+extractedMinor+" extractedPatch: "+extractedPatch);

  return (
    <Grid
      container
      spacing={1}
      direction="column">

      <Grid
        className={classNames(classes.root, classes.tableRow, rowClass)}
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="center">

        <Grid item xs={12} sm={1}>   
          <Typography variant="caption" className={classes.gridtext}>
            {scale.id_scales}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>   
          <Typography variant="caption" className={classes.gridtext}>
            {scale.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} >
            <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
              <Moment locale="de" utc fromNow>{ scale.activate_time }</Moment> 
            </Typography>
        </Grid>    
        <Grid item xs={12} sm={3} >
            <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
            {extractedMajor}.{extractedMinor  }.{extractedPatch} / ({scale.desired_fw})
            </Typography>
        </Grid>       
        <Grid item xs={12} sm={1} >
              
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <AcceptIcon /> : <EditIcon /> }
          </IconButton>  
        </Grid>       
      </Grid>


      { 
// STATUS - Station: nur sichtbar falls bestimmter Zustand vorhanden: Update, Konfigmodus usw
}

      { (scale.status !== 0) &&    
        <Grid 
          container
          className={classNames(classes.root, classes.status_row, rowClass)}>   
          <ScaleStatus 
              scale={scale} 
              sendfunction={sendCustomWriteToScale}
               >
          </ScaleStatus>         
        </Grid>      
      }
{ 
// VERSTECKTE ROW - mit einzelnen Chargern!
}
      <Grid item container xs={12}>
        <Grid item xs={12} container direction="column">

          <Collapse in={open} unmountOnExit>
            <Box margin={1}>    
            
            <Grid item xs>
                <br />
            </Grid>
            <Grid item container xs={12}>
            <Grid item xs={12} sm={6} container direction="column">
              {/* <Grid item xs>
                <Typography variant="caption" color="textSecondary" className={classes.gridtext}>
                  RSSI (received signal strength): { getRSSIString(scale.rssi) } BIT ERROR RATE: { scale.ber }
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption" color="textSecondary" className={classes.gridtext}>
                  CELL-ID: { scale.cell_id } LAC: { scale.tac_lac }
                </Typography>
              </Grid>

              <Grid item xs>
                <Typography variant="caption" color="textSecondary" className={classes.gridtext}>
                  FW-Ladestation: {scale.fw_scale}
                </Typography>
              </Grid> */}

              <Grid item xs>
                <br />
              </Grid>
            </Grid>
            <Grid>


            <Grid item container direction="row" className={classes.buttonRow} >
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  native
                  value={selectedFw} 
                  className={classes.selectEmpty} 
                  onChange={handleFirmwareChange}
                  inputProps={{
                    id_selected_fw: selectedFw
                  }}  >
                  <option value="" />
                  { firmwares !== undefined && 
                    firmwares.map(row => {
                      const { major: extractedMajor, minor: extractedMinor, patch: extractedPatch } = ExtractVersionNumber(row.fw_number);                      
                              /* key={row.filename_fw} >  */
                      return (
                      <option value={row.id_firmware} 
                              key={row.fw_number} > 
                              {"FW"+row.id_firmware+" - Version "+extractedMajor+"."+extractedMinor+"."+extractedPatch} 
                      </option>
                    )})
                  }
                </Select>
              </FormControl>
                  &nbsp;&nbsp;
              <Button 
                variant="contained" 
                size="small"
                color="primary" 
                // className={styles.charger_button}
                onClick={() => { sendCustomWriteToScale(scale, const_vars.UPDATE_FIRMWARE, 2)}}
                >
                Firmware aktualisieren
              </Button>
            </Grid>
         </Grid>

        </Grid>
            </Box>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );  
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 18,
    width: 18,
    padding: theme.spacing(0, 0),
    lineHeight: 1,
  },
  iconMini: {
    verticalAlign: 'center',
    height: 16,
    width: 16,
    padding: theme.spacing(0, 0),
    lineHeight: 1,
  },
  formControl: {
    margin: theme.spacing(2, 0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2, 0),
  },
  details: {
    alignItems: 'center',
  },
  buttonRow: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  infoText: {
    padding: theme.spacing(0, 0),
    margin: theme.spacing(0, 0),
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.text.secondary,
  },
  tableRow: {
    borderTop: `1px solid ${theme.palette.divider}`,
    // borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 4),
    '&:hover': {
      backgroundColor: alpha(teal[50], 0.3),
      cursor: "pointer"
    },
  },
  tableRowLast: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tableRowEven: {
    // backgroundColor: lime[50],
    backgroundColor: alpha(lime[50], 0.3),
  },
  tableRowOdd: {
    // backgroundColor: teal[50],
    // backgroundColor: fade(teal[50], 0.3),
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 4),
  },
  gridtext: {
    padding: theme.spacing(0, 0),
    lineHeight: 1,
  },
  ok_color: {
    color: theme.palette.primary.main,
  },
  error_color: {
    color: theme.palette.warning.main,
  },
  image: {
    height: 52,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));


export default ScaleAdminComp;