import React from 'react';
import { Grid, Typography, IconButton, Collapse, Tooltip, Button, CircularProgress, Card, Divider, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from '../axios-modbee';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "../store/actions/standort";
import EditSomething from './div/EditSomething';
import MapIcon from '@mui/icons-material/Map';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';

const Standortbearbeiten = (props) => {
    const { t } = useTranslation();
    const standorte = useSelector(state => state.standort.standorte);
    const dispatch = useDispatch()
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const rename = (name) => {
        // const config = {
        //     daten: {
        //         "name": name,
        //     },
        //     crossdomain: true
        // };
        // axios.post('/scale/edit/' + row.id_scales, config)
        //     .then(res => {
        const tempA = [...standorte]
        const index = tempA.findIndex(fff => fff.id_standort === props.standort.id_standort)
        tempA[index].ort = name
        dispatch(actions.fetchStandorteSuccess(tempA));
        //console.log("got edit scale answer: ", res);

        // })
        // .catch(err => {
        //     console.log("got edit scale error: ", err);
        // });

    }

    const maps = () => {
        enqueueSnackbar("beda do kosd maps aufmochn ;)", { variant: 'info' });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <EditSomething saveIt={rename} inhalt={props.standort.ort} title={t('main.Ort')} />
            </Grid>
            <Grid item xs={12}>
                <Grid container className={classes.editable} onClick={maps}>
                    <Grid item><Typography>Standort: {props.standort.latlong}</Typography></Grid>
                    <Grid item style={{ maxHeight: 24, paddingLeft: 8 }}><Tooltip title={t('main.Bearbeiten')}><MapIcon size='small' className={classNames("showedit", classes.editIcon)} /></Tooltip></Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}
const useStyles = makeStyles((theme) => ({

    editable: {
        '&:hover': {
            backgroundColor: '#e0e0e0',
            cursor: 'pointer',
            "& .showedit": {
                display: "inline"
            }
        },
    },
    editIcon: {
        color: '#777',
        display: 'none'
    }
}));

export default Standortbearbeiten;