function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function nameComparator(a, b, orderBy) {
    
    if ( a.name_station < b.name_station ){
      return -1;
    }
    if ( a.name_station > b.name_station ){
      return 1;
    }
    return 0;
  }
  function statusComparator(a, b, orderBy) {
    
    if ( a.last_update < b.last_update ){
      return -1;
    }
    if ( a.last_update > b.last_update ){
      return 1;
    }
    return 0;
  }
  
  export function getComparator(order, orderBy) {
    // console.log("getComparator orderBy: "+orderBy);
  
    if (orderBy === 'name') {
      return order === 'desc'
      ? (a, b) => nameComparator(a, b, orderBy)
      : (a, b) => -nameComparator(a, b, orderBy);
  
    } if (orderBy === 'status') {
      return order === 'desc'
      ? (a, b) => statusComparator(a, b, orderBy)
      : (a, b) => -statusComparator(a, b, orderBy);
    } else {
  
      return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
    }
  
  }
  
  export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    // console.log("comparator: "+comparator);
    // console.log("stabilizedThis: "+JSON.stringify(stabilizedThis));
  
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }