import React, {useEffect} from 'react';
import { askForPermissioToReceiveNotifications } from "../components/firebase/firebase";
import { Button, Paper } from '@mui/material';
import * as const_vars from "../Static/const_vars";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as actions from "../store/actions/main";

const Alarms = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setTitleAndMenu(t('main.Alarme'), const_vars.MENU_ALARMS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []) //

  return (
    <Paper>
      <div onClick={askForPermissioToReceiveNotifications} >
        Benachrichtigungen aktivieren!
        <Button variant="contained" color="primary"  >
          Hello World
        </Button>
      </div>
    </Paper>
  );
}


export default Alarms;