
import Dexie from 'dexie';

const db = new Dexie("scale_db");

	// Declare tables, IDs and indexes
db.version(1).stores({
    scales: 'id_scales, uid',
    scaledata: 'id_weight, id_scale, change_time'
  });

db.version(3).stores({
    scales: 'id_scales, uid',
    scaledata: 'id_weight, [id_scale+signal_time]'
  });

// db.version(2).stores({
//     friends: "++id,name,birthdate,sex"
// }).upgrade (trans => {
//     var YEAR = 365 * 24 * 60 * 60 * 1000;
//     return trans.friends.toCollection().modify (friend => {
//         friend.birthdate = new Date(Date.now() - (friend.age * YEAR));
//         delete friend.age;
//     });
// });


export default db;