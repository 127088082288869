import React from 'react';
import { CssBaseline, Grid, List, ListItem, ListItemIcon, ListItemText, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WaagenIcon from '@mui/icons-material/Router';
import GpsIcon from '@mui/icons-material/GpsFixed';
import TimeLineIcon from '@mui/icons-material/ShowChart';
import PublicIcon from '@mui/icons-material/EventNote';
import TimerIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import MemoryIcon from '@mui/icons-material/Memory';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import pkg from '../../../package.json';
import HiveIcon from '@mui/icons-material/Hive';
import ColorSelect from '../../Settings/ColorSelect';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
const DrawerContent = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    let navigate = useNavigate();
    const account = useSelector(state => state.main.account);
    const title = useSelector(state => state.main.title);

    let manage = {
        id: 'disabled',
        children: [],
    };

    if (account.hasOwnProperty('uid')) {
        if (account.user_role === 1) {
            manage = {
                id: t('main.Verwaltung'),
                children: [
                    { id: t('main.Firmware'), icon: <MemoryIcon />, path: '/firmware' },
                    { id: t('main.Wartung'), icon: <WaagenIcon />, path: '/wartung' },
                ],
            };
        }
    }

    const categories = [
        {
            id: t('main.Allgemein'),
            children: [
                { id: t('main.Dashboard'), icon: <DashboardIcon />, path: '/' },
                { id: t('main.Standorte'), icon: <GpsIcon />, path: '/apiaries' },
                { id: t('main.Waagen'), icon: <WaagenIcon />, path: '/scales' },
                { id: t('main.Hives'), icon: <HiveIcon />, path: '/hives' },
                { id: t('main.Statistik'), icon: <TimeLineIcon />, path: '/statistic' },
                { id: t('main.Ereignisse'), icon: <PublicIcon />, path: '/events' },
                { id: t('main.Shop'), icon: <ShoppingCartIcon />, path: '/shop' },
            ],
        },
        {
            id: t('main.Einstellungen'),
            children: [
                { id: t('main.Allgemein'), icon: <SettingsIcon />, path: '/settings' },
                { id: t('main.Alarme'), icon: <TimerIcon />, path: '/alarms' },
            ],
        }, manage
    ];

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container className={classes.rootitem}>
                <List style={{ width: 200 }} disablePadding>
                    {categories.map(({ id, children }) => (
                        id !== "disabled" &&
                        <React.Fragment key={id}>
                            {!props.bigDrawer ? null : <ListItem>
                                <ListItemText className={classes.categoryHeaderPrimary} >
                                    {id}
                                </ListItemText>
                            </ListItem>}
                            {children.map(({ id: childId, icon, path }) => (
                                <ListItem onClick={() => navigate(path)}
                                    button
                                    dense
                                    key={childId}
                                    className={classNames(
                                        !props.bigDrawer ? classes.smallpad : null,
                                        classes.item,
                                        classes.itemActionable,
                                        (childId === title) && classes.itemActiveItem,
                                    )} >
                                    <ListItemIcon>{icon}</ListItemIcon>

                                    {(childId === title) &&
                                        <ListItemText>
                                            {childId}
                                        </ListItemText>
                                    }
                                    {(childId !== title) &&
                                        <ListItemText >
                                            {childId}
                                        </ListItemText>
                                    }
                                </ListItem>
                            ))}
                            <Divider className={classes.divider} />
                        </React.Fragment>
                    ))}
                </List>

                <Typography variant="caption" className={props.bigDrawer ? classes.version_nr : classes.v_small}>
                    v{pkg.version}
                </Typography>

                <ColorSelect color="sideBar" name="Hintergrund">
                </ColorSelect>

                <ColorSelect color="primaryText" name="Schriftfarbe">
                </ColorSelect>

                <ColorSelect color="highlight" name="Aktivfarbe">
                </ColorSelect>

                {/* <ColorPicker
        name='color'
        defaultValue='#000'
        // value={this.state.color} - for controlled component
        onChange={color => console.log(color)}      
      /> */}

            </Grid>
        </React.Fragment >

    );

}

export const useStyles = makeStyles((theme) => ({
    smallpad: {
        paddingLeft: 8
    },
    rootitem: {
        width: 200,
        overflow: 'hidden'
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },

    item: {
        color: theme.palette.primary.text, //'rgba(255, 255, 255, 0.9)',
    },

    itemActionable: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemActiveItem: {
        color: theme.palette.colors.highlight,//'#adea00 !important', //4fc3f7',
    },
    version_nr: {
        fontSize: 10,
        // color: '#adea00', //adea00',
        marginLeft: 16,
        marginTop: 16
    },
    v_small: {
        fontSize: 10,
        // color: '#adea00', //adea00',
        marginLeft: 0,
        marginTop: 16
    },
}));

export default DrawerContent;