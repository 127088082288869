import React, { useEffect } from 'react';
import * as actions from '../store/actions/index';
import { useDispatch, useSelector } from "react-redux";
import beelogo from '../Static/beelogo.png';
import { Button, Checkbox, CssBaseline, FormControlLabel, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
const SignIn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const loading = useSelector(state => state.auth.loading);
  const email = useSelector(state => state.auth.email);
  const remember = useSelector(state => state.auth.remember);
  const password = useSelector(state => state.auth.password);
  const error = useSelector(state => state.auth.error);

  const [localUsername, setLocalUsername] = React.useState(email);
  const [localPassword, setLocalPassword] = React.useState(password);
  const [localRemember, setLocalRemember] = React.useState(remember);
  const [submitted, setSubmitted] = React.useState(false)

  useEffect(() => {
    setSubmitted(false)
  }, [localUsername, localPassword])

  useEffect(() => {
    console.log(error)
    if (error) enqueueSnackbar(error, { variant: 'error' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const submitHandler = (event) => {
    event.preventDefault();
    setSubmitted(true)
    if (localUsername.length === 0) enqueueSnackbar(t('login.Bitte geben Sie eine EMail Adresse an'), { variant: 'error' });
    if (localPassword.length === 0) enqueueSnackbar(t('login.Das Passwort darf nicht leer sein'), { variant: 'error' });
    if (localUsername.length !== 0 && localPassword.length !== 0)
      dispatch(actions.auth(localUsername, localPassword, localRemember, false));
  };


  return (
    <React.Fragment>
      <CssBaseline />
      <form onSubmit={submitHandler} style={{ width: '100%' }}>
        <Grid container style={{ padding: 16, }}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={8}>
              <Grid item>
                <img src={beelogo} alt="" className={classes.logo} />
              </Grid>
              <Grid item>
                <Typography component="h1" variant="h5">
                  modbee.io
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 4 }}>
            {loading && <LinearProgress />}
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={loading}
              required
              fullWidth
              label={t('login.email')}
              margin="normal"
              id="email"
              name="email"
              autoComplete="email"
              onChange={(event) => setLocalUsername(event.target.value)}
              autoFocus
              value={localUsername}
              error={submitted && localUsername.length === 0}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={loading}
              required
              fullWidth
              label={t('login.pw')}
              margin="normal"
              name="password"
              type="password"
              id="password"
              value={localPassword}
              autoComplete="current-password"
              onChange={(event) => setLocalPassword(event.target.value)}
              error={submitted && localPassword.length === 0}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={loading}
                  checked={localRemember}
                  onChange={(event) => setLocalRemember(event.target.checked)}
                  value="checkedB"
                  color="primary"
                />
              }
              label={t('login.Automatisch anmelden')}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={loading}
              onClick={submitHandler}
              type="submit"
              fullWidth
              variant="contained"
              color="primary">
              {t('login.Anmelden')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment >
  );

}
const useStyles = makeStyles(theme => ({
  logo: {
    paddingTop: 3,
    width: 36,
  },

}));

export default SignIn;