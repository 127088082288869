import React from 'react';
import 'moment-timezone';
import 'moment/locale/de';
import Moment from "react-moment";
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, IconButton, Collapse, Tooltip, Button, CircularProgress, Card, Divider, DialogContent } from '@mui/material';
import { alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { deepOrange, lime, teal } from '@mui/material/colors';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DialogUniversal from '../UI/DialogUniversal/DialogUniversal'
import Kalibrierung from './Kalibrierung/Kalibrierung'
import Bat10Icon from '@mui/icons-material/Battery20';
import Bat20Icon from '@mui/icons-material/Battery20';
import Bat50Icon from '@mui/icons-material/Battery50';
import Bat90Icon from '@mui/icons-material/Battery90';
import Zusammenbau from './Zusammenbau/Zusammenbau'
import axios from '../axios-modbee';
import { useSelector, useDispatch } from 'react-redux';
import * as actions_scales from "../store/actions/scales";
import const_vars from "../Static/const_vars";
import EditIcon from '@mui/icons-material/Edit';
import WaagenName from './div/Name';
import Kalibrierungen from './Kalibrierung/Kalibrierungen/Kalibrierungen';
import DialogHeaderX from '../UI/DialogUniversal/DialogHeaderX';

const Waageninhalt = (props) => {
    const { row, index } = props;
    const [showCalib, setShowCalib] = React.useState(false);
    const [showAlteCalib, setShowAlteCalib] = React.useState(null);
    const [showLaufendeKali, setShowLaufendeKali] = React.useState(false);
    const [showZusammenbau, setShowZusammenbau] = React.useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const scales = useSelector(state => state.scale.scales);
    const calibrations = useSelector(state => state.scale.calibrations);
    const dispatch = useDispatch()

    let rowClass = "";
    if (index % 2 === 0) {
        rowClass = classes.tableRowEven;
    } else {
        rowClass = classes.tableRowOdd;
    }
    if (!row.kalibriert) rowClass = classes.warning;

    let chargeClass = "";
    let icon = <Bat90Icon className={classes.charge_icon} />;
    if (Number.parseFloat(row.voltage) < 3.85) {
        chargeClass = classes.critical_charge;
        icon = <Bat10Icon className={classes.charge_icon_critical} />;
    } else if (Number.parseFloat(row.voltage) < 3.9) {
        chargeClass = classes.warning_charge;
        icon = <Bat20Icon className={classes.charge_icon_warn} />;
    } else if (Number.parseFloat(row.voltage) < 4.02) {
        chargeClass = classes.info_charge;
        icon = <Bat50Icon className={classes.charge_icon_info} />;
    }

    const showKalibrierung = (e) => {
        e.stopPropagation();
        // suche letzte kalibrierung
        console.log("showKalibrierung")
        const tempA = calibrations.filter(a => a.id_scale === row.id_scales && a.status === 0)

        let interruptus = false;
        if (tempA.length > 0) interruptus = true
        if (interruptus) setShowLaufendeKali(true)
        else {
            if (row.zusammengebaut === 0) setShowZusammenbau(true)
            else setShowCalib(true)
        }
    }
    const showAlteKalibrierung = (e) => {
        e.stopPropagation();
        // suche letzte kalibrierung
        setShowAlteCalib(calibrations.filter(a => a.id_scale === row.id_scales && a.status === 0)[0])
    }


    const skipZusammenbau = () => {
        const config = {
            daten: {
                "zusammengebaut": 1,
            },
            crossdomain: true
        };
        axios.post('/scale/edit/' + row.id_scales, config)
            .then(res => {
                const tempA = [...scales]
                const index = tempA.findIndex(fff => fff.id_scales === row.id_scales)
                const newScale = { ...tempA[index], ...config.daten }
                tempA[index] = newScale;
                dispatch(actions_scales.fetchScalesSuccess(tempA));
                console.log("got edit scale answer: ", res);
            })
            .catch(err => {
                console.log("got edit scale error: ", err);
            });
        setShowZusammenbau(false)
        setShowCalib(true)
    }

    const fortfahren = () => {
        const tempA = calibrations.filter(a => a.id_scale === row.id_scales && a.status === 0)
        const config = {
            daten: {
                "status": 2,
            }, crossdomain: true
        };

        axios.put('/calibration/' + tempA[0].id_kalibrierung, config)
            .then(res => {
                tempA[0].status = 2
                const tempB = [...calibrations]
                const index = calibrations.findIndex(fff => fff.id_kalibrierung === tempA[0].id_kalibrierung)
                tempB[index] = tempA[0]
                dispatch(actions_scales.fetchCalibrationsSuccess(tempB))
                setShowLaufendeKali(false)
                setShowCalib(true)
            })
            .catch(err => {
                console.log("got sendChargerFw error: ", err);
            });



    }

    const reset = () => {
        const config = {
            daten: {
                "zusammengebaut": 0,
                "kalibriert": 0
            },
            crossdomain: true
        };
        axios.post('/scale/edit/' + row.id_scales, config)
            .then(res => {
                const tempA = [...scales]
                const index = tempA.findIndex(fff => fff.id_scales === row.id_scales)
                const newScale = { ...tempA[index], ...config.daten }
                tempA[index] = newScale;
                dispatch(actions_scales.fetchScalesSuccess(tempA));
                console.log("got edit scale answer: ", res);
            })
            .catch(err => {
                console.log("got edit scale error: ", err);
            });

    }

    return (
        <React.Fragment>
            <Grid
                container
                spacing={1}
                direction="column">

                <Grid
                    className={classNames(classes.tableRow, rowClass)}
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    onClick={() => setOpen(!open)}>
                    <Grid item xs={12} sm={1}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {new Date().getTime() - new Date(row.activate_time).getTime() > 86400000 ? <Tooltip arrow title={t('main.Letzter Kontakt vor über einem Tag')}><WarningAmberIcon className={classes.error} /></Tooltip> :
                                !row.kalibriert ? <Tooltip arrow title={t('main.Neue Waage')}><NewReleasesIcon className={classes.error} /></Tooltip> :
                                    <Tooltip arrow title={t('main.Alles in Ordnung')}><CheckIcon className={classes.ok} /></Tooltip>}
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" className={classes.gridtext}>
                                    {row.name !== "" ? row.name : t('main.Waage') + ' ' + row.id_scales}
                                </Typography>
                            </Grid>
                            {!row.kalibriert ?
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="caption" className={classes.gridtext}>
                                            {row.status >= const_vars.GW_STATUS_SHOULD_START_CALIBRATION && row.status <= const_vars.GW_STATUS_IS_SENDING_VALUES ? t('main.Kalibrierung läuft') : t('main.Waage wurde noch nicht kalibriert')}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {row.status >= const_vars.GW_STATUS_SHOULD_START_CALIBRATION && row.status <= const_vars.GW_STATUS_IS_SENDING_VALUES ?
                                            <Button color="warning" size="small" variant="outlined" onClick={showAlteKalibrierung}>{t('main.Kalibrierung anzeigen')}</Button> :
                                            <Button color="warning" size="small" variant="outlined" onClick={showKalibrierung}>{t('main.Kalibrierung starten')}</Button>}
                                    </Grid>
                                </React.Fragment> : null}
                        </Grid>


                    </Grid>
                    <Grid item xs={12} sm={5} >

                        <Grid container direction="row"
                            alignItems="center"
                            justify="center" >
                            <Typography variant="caption" >
                                {row.status}
                                &nbsp;|&nbsp;
                                {Number.parseFloat(row.last_weight).toFixed(2)}&nbsp;kg
                                &nbsp;|&nbsp;
                                {Number.parseFloat(row.temp1).toFixed(2)}&nbsp;°C
                                &nbsp;|&nbsp;
                                <Moment locale="de" utc fromNow>{row.activate_time}</Moment>
                                &nbsp;|&nbsp;
                                <span className={chargeClass}>
                                    {
                                        Number.parseFloat(row.voltage).toFixed(2)
                                    }
                                    V</span>
                            </Typography>


                            <Grid item>
                                {icon}
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={1} style={{ paddingTop: 4 }}>
                        {row.status >= const_vars.GW_STATUS_SHOULD_START_CALIBRATION && row.status <= const_vars.GW_STATUS_IS_SENDING_VALUES ?
                            <Tooltip title={t('main.Kalibrierung läuft')}><CircularProgress color="secondary" size={24} /></Tooltip> : null}
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Grid>
                </Grid>
                {
                    // VERSTECKTE ROW - mit einzelnen Chargern!
                }
                <Grid item container xs={12}>
                    <Grid item xs={12} container direction="column">
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Grid
                                container>
                                <Grid item xs={1} />
                                <Grid item xs>
                                    <Card style={{ padding: 16, marginBottom: 8, backgroundColor: '#eaeaea' }}>
                                        <Grid
                                            container>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}><Typography>Details</Typography></Grid>
                                                    <Grid item xs={6}>
                                                        <Typography>Funktionen</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} style={{ padding: 4 }}><Divider /></Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                        <Grid container>
                                                            <WaagenName row={row} />

                                                            <Grid item xs={12}>
                                                                <Typography>Status: {row.status}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography>aktuelle Genauigkeit: {row.status}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Button size="small" variant="outlined" onClick={showKalibrierung}>Kalibrierung starten</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Kalibrierungen scale={row} />
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>
                </Grid>
            </Grid>
            <DialogUniversal open={showCalib} close={() => setShowCalib(false)} maxWidth={'sm'} fullWidth>
                <Kalibrierung waage={row} close={() => setShowCalib(false)} />
            </DialogUniversal>
            <DialogUniversal open={showAlteCalib !== null} close={() => setShowAlteCalib(null)} maxWidth={'sm'} fullWidth>
                <Kalibrierung waage={row} kalibrierung={showAlteCalib} close={() => setShowAlteCalib(null)} />
            </DialogUniversal>
            <DialogUniversal open={showZusammenbau} close={() => setShowZusammenbau(false)} maxWidth={'sm'} fullWidth>
                <Zusammenbau waage={row} close={() => setShowZusammenbau(false)} skipZusammenbau={skipZusammenbau} />
            </DialogUniversal>
            <DialogUniversal open={showLaufendeKali} close={() => setShowLaufendeKali(false)} abbrechen={() => setShowLaufendeKali(false)} sonder={fortfahren} sonderTitel={t('main.Fortfahren')} maxWidth={'sm'} fullWidth>
                <DialogHeaderX title={t('main.Laufende Kalibrierung gefunden')} close={() => setShowLaufendeKali(false)} />
                <DialogContent style={{ backgroundColor: '#f0f0f0' }}>
                    <Typography >Achtung, es wurde eine laufende Kalibrierung gefunden, soll diese abgebrochen und eine neue gestartet werden?</Typography>
                </DialogContent>

            </DialogUniversal>
        </React.Fragment >
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        verticalAlign: 'bottom',
        height: 18,
        width: 18,
        padding: theme.spacing(0, 0),
        lineHeight: 1,
    },
    tableRow: {
        borderTop: `1px solid ${theme.palette.divider}`,
        // borderBottom: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2, 4),
        '&:hover': {
            backgroundColor: alpha(teal[50], 0.3),
            cursor: "pointer"
        },
    },
    tableRowEven: {
        backgroundColor: alpha(lime[50], 0.3),
    },
    tableRowOdd: {
        // backgroundColor: teal[50],
        // backgroundColor: fade(teal[50], 0.3),
    },
    gridtext: {
        padding: theme.spacing(0, 0),
        lineHeight: 1,
    },
    ok: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.warning.main,
    },
    charge_icon: {
        /* margin-top: 2px; */
        color: '#777 !important',
        paddingTop: 0,
        height: 14,
        width: 14,
    },

    charge_icon_info: {
        /* margin-top: 2px; */
        color: '#ffd600 !important',
        paddingTop: 0,
        height: 14,
        width: 14,
    },

    charge_icon_warn: {
        color: '#ff6d00 !important',
        paddingTop: 0,
        height: 14,
        width: 14,
    },

    charge_icon_critical: {
        color: '#B71C1C !important',
        paddingTop: 0,
        height: 14,
        width: 14,
    },
    info_charge: {
        color: '#ffd600 !important',
        fontWeight: 900,
    },
    warning_charge: {
        color: '#ff6d00 !important',
        fontWeight: 900,
    },
    critical_charge: {
        color: '#B71C1C !important',
        fontWeight: 900,
    },
    remove_bottom_margin: {
        marginBottom: 0,
        lineHeight: 1.1,
    },
    warning: {
        backgroundColor: alpha(deepOrange[50], 1)
    },
    editable: {
        '&:hover': {
            backgroundColor: '#e0e0e0',
            cursor: 'pointer',
            "& .showedit": {
                display: "inline"
            }
        },
    },
    editIcon: {
        color: '#777',
        display: 'none'
    }
}));


export default Waageninhalt;