
import React from 'react';
import { useDispatch, useSelector} from "react-redux";
import 'moment-timezone';
import 'moment/locale/de';
import makeStyles from '@mui/styles/makeStyles';
import {  Paper, TablePagination, Grid } from '@mui/material';
import { useEffect } from 'react';
import * as actions_hives from "../store/actions/hives";
import * as mainactions from "../store/actions/main";
import { useTranslation } from 'react-i18next';
import { getComparator, stableSort } from '../UI/Table/TableFunctions';
import * as const_vars from "../Static/const_vars";
import HivesTableToolbar from './Table/HivesTableToolbar';
import HivesTableHead from './Table/HivesTableHead';
import Hiveinhalt from './Hiveinhalt';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    margin: 16,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const Hives = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  const hives = useSelector(state => state.hives.hives);
  const { t } = useTranslation();
    console.log(hives)

    useEffect(() => {
        dispatch(mainactions.setTitleAndMenu(t('main.Hives'), const_vars.MENU_HIVES));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) //

    useEffect(() => {
      if (token) {
        console.log("lets get hives");
        dispatch(actions_hives.fetchHives(token));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    console.log("handleRequestSort: "+isAsc+ " event: "+event)
    setOrder(
      isAsc ? 'desc' : 'asc');
      setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  

  return (
    <Paper className={classes.paper}> 
      <HivesTableToolbar numSelected={0} />

      <Grid
        spacing={1}
        container
        direction="column"
        justify="center"
        alignItems="center">   

        <HivesTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}/>
          
          { hives  && 
            stableSort(hives, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (                
                <Hiveinhalt
                  hover
                  index={index}
                  row={row} 
                  key={index}
                />
              );
            })
          }
      </Grid>

      <TablePagination
        rowsPerPageOptions={[3, 5, 10, 25, 50]}
        component="div"
        count={hives.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage} 
      />
    </Paper>
  );  
}


export default Hives;