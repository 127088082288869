import * as actionTypes from './actionTypes';
import axios from '../../axios-modbee';
import {authRefreshToken} from "./auth";
import * as actions from "./main";
import {mapScales} from "../utility";

export const fetchCalibrationsSuccess = ( cali ) => {
  return {
      type: actionTypes.CALIBRATIONS_SUCCESS,
      cali: cali
  };
};

export const fetchCalibrationsFail = ( error ) => {
  return {
      type: actionTypes.CALIBRATIONS_FAIL,
      error: error
  };
};

export const fetchScalesSuccess = ( scales ) => {
  return {
      type: actionTypes.SCALES_SUCCESS,
      scales: scales
  };
};

export const fetchScalesFail = ( error ) => {
  return {
      type: actionTypes.SCALES_FAIL,
      error: error
  };
};

export const fetchScalesStart = () => {
  return {
      type: actionTypes.SCALES_START
  };
};

/*
* deprecated - wird entfernt werden
*/
export const updateZoomState = ( zoomState ) => {
  return {
    type: actionTypes.UPDATE_ZOOM_STATE,
    zoomState: zoomState
  };
};

export const scaleDataChange = ( new_scale, scales ) => {
  return {
    type: actionTypes.CHANGE_SCALE_DATA,
    new_scale: new_scale,
    scales: scales
  };
};

// export const fetchScalesSuccess = ( scales ) => {
//     return {
//         type: actionTypes.FETCH_SCALES_SUCCESS,
//         scales: scales
//     };
// };

// export const fetchScalesFail = ( error ) => {
//     return {
//         type: actionTypes.FETCH_SCALES_FAIL,
//         error: error
//     };
// };

// export const fetchScalesStart = () => {
//     return {
//         type: actionTypes.FETCH_SCALES_START
//     };
// };
export const fetchScaleDataSuccess = ( scaledata ) => {
  return {
    type: actionTypes.FETCH_SCALE_DATA_SUCCESS,
    scaledata: scaledata
  };
};

export const fetchScaleDataSuccessAll = ( scaledata ) => {
  return {
    type: actionTypes.FETCH_SCALE_DATA_SUCCESS_ALL,
    scaledata: scaledata
  };
};

export const fetchScaleDataFail = ( error ) => {
  return {
    type: actionTypes.FETCH_SCALE_DATA_FAIL,
    error: error
  };
};
export const fetchScaleDataStart = () => {
  return {
    type: actionTypes.FETCH_SCALE_DATA_START
  };
};

export const fetchedAllScales = (all_scales) => {
  return {
    type: actionTypes.FETCH_ALL_SCALES,
    all_scales: all_scales
  };
};

export const setScaleWorker = (scaleWorker) => {
  return {
    type: actionTypes.SET_SCALE_WORKER,
    scaleWorker: scaleWorker
  };
};


export const fetchAllScales = (token) => {
  return dispatch => {
    const config = {
      headers: {'Authorization': ''+token}
    };

    // console.log("fetchAllScales: ",token);
    axios.get( '/scale/all', config)
    // axios.get( '/scale', config)
        .then( res => {
            const fetchScales = [];
            for ( let key in res.data ) {
              // fetchScales.push(res.data[key]);
              // console.log("got scale: ", res.data[key]);
              fetchScales.push( {
                  ...res.data[key],
                  id: key
              } );
            }

            console.log("fetchAllScales then: ",fetchScales);

            dispatch(fetchedAllScales(fetchScales));
        } )
        .catch( err => {
          //TODO - falls auth error - automatisch neuen token anfordern!!
          console.error("fetch - error token: ", token);
          console.error("error fetch scales: "+err.toLocaleString()+" und ", err)
          // dispatch(fetchScalesFail(err));
          dispatch(authRefreshToken());
        });
  };
};

export const fetchScalesFast = (token) => {
  return dispatch => {
    // dispatch(fetchScalesStart());
    const config = {
      headers: {'Authorization': ''+token}
    };
    axios.get( '/scale/fast', config)
    // axios.get( '/scale', config)
        .then( res => {
            console.log("fetchScalesFast then: ", res.data);
            // const fetchScales = [];
            // for ( let key in res.data ) {
            //   // fetchScales.push(res.data[key]);
            //   // console.log("got scale: ", res.data[key]);
            //   fetchScales.push( {
            //       ...res.data[key],
            //       id: key
            //   } );
            // }
            const fetchScales = mapScales(res.data);
            dispatch(fetchScalesSuccess(fetchScales));
        } )
        .catch( err => {
          //TODO - falls auth error - automatisch neuen token anfordern!!
          console.error("fetch - error token: ", token);
          console.error("error fetch scales: "+err.toLocaleString()+" und ", err)
          dispatch(fetchScalesFail(err));
          dispatch(authRefreshToken());
        });
  };
};
export const fetchCalibrations = (token) => {
  return dispatch => {

    const config = {
      headers: {'Authorization': ''+token}
    };
    axios.get( '/calibration', config)
        .then( res => {
            console.log("fetchCalibrations: ", res.data);
            dispatch(fetchCalibrationsSuccess(res.data));
        } )
        .catch( err => {
          //TODO - falls auth error - automatisch neuen token anfordern!!
          console.error("fetch - error token: ", token);
          console.error("error fetch scales: "+err.toLocaleString()+" und ", err)
          dispatch(fetchCalibrationsFail(err));
          dispatch(authRefreshToken());
        });
  };
};

export const fetchScales = (token) => {
    return dispatch => {
      // dispatch(fetchScalesStart());
      const config = {
        headers: {'Authorization': ''+token}
      };
      axios.get( '/scale/with', config)
      // axios.get( '/scale', config)
          .then( res => {
              console.log("fetchScales then: ",res);
              const fetchScales = [];
              for ( let key in res.data ) {
                // fetchScales.push(res.data[key]);
                // console.log("got scale: ", res.data[key]);
                fetchScales.push( {
                    ...res.data[key],
                    id: key
                } );
              }
              dispatch(fetchScalesSuccess(fetchScales));
          } )
          .catch( err => {
            //TODO - falls auth error - automatisch neuen token anfordern!!
            console.error("fetch - error token: ", token);
            console.error("error fetch scales: "+err.toLocaleString()+" und ", err)
            dispatch(fetchScalesFail(err));
            dispatch(authRefreshToken());
          });
    };
};



export const fetchScaleData = (token, scale_id) => {
  return dispatch => {
    dispatch(fetchScaleDataStart());

    let last_get = "2018-10-18 05:19:00";

    const config = {
      headers: {
         'Authorization': ''+token,
         'Data': ""+last_get}
    };

    console.log("fetchScaleData scale_id: ", scale_id);

    //TODO nur für diese waage abholen!!!
    axios.get( '/scale/weekWeights/'+scale_id, config)
    // axios.get( '/scale/weights/'+scale_id, config)
    // axios.get( '/weight_data/'+scale_id, config)
      .then( res => {
        // const fetchScales = [];
        // console.log("got weight_data: ", res.data);

        // for ( let key in res.data ) {
        //   // fetchScales.push(res.data[key]);
        //   fetchScales.push( {
        //     ...res.data[key],
        //     id: key
        //   } );
        // }
        dispatch(fetchScaleDataSuccess(res.data));
      } )
      .catch( err => {
        dispatch(fetchScaleDataFail(err));
      } );
  };
};


export const fetchScaleDataAll = (token, scale_id) => {
  return dispatch => {
    // dispatch(fetchScaleDataStart());

    let last_get = "2018-10-18 05:19:00";
    const config = {
      headers: {
        'Authorization': ''+token,
        'Data': ""+last_get}
    };
    console.log("fetchScaleDataAll");
    axios.get( '/scale/all/'+scale_id, config)
    // axios.get( '/weight_data/'+scale_id, config)
      .then( res => {
        console.log("got fetchScaleDataAll: ", res.data);
        dispatch(fetchScaleDataSuccessAll(res.data));
      } )
      .catch( err => {
        console.log("err fetchScaleDataAll: ", err);
        dispatch(fetchScaleDataFail(err));
      } );
  };
};


export const saveScaleName = (token, name, scale_id) => {
  return dispatch => {
    const config = {
      headers: {
        'Authorization': ''+token,
        'Data': ""+name}
    };
    const data = {
      name: name
    };
    console.log("saveScaleName data: ", data);

    axios.post( '/scale/name/'+scale_id, data, config)
      .then( res => {
        // const fetchScales = [];
        // eslint-disable-next-line
        if (res.data == "ok") {
          //Scale name updated correctly!
          console.log("res.data == ok");
        } else {
          console.log("got weight_data: ", res.data);

        }
        // dispatch(fetchScaleDataSuccess(res.data));
        dispatch(actions.setTitle(name));
      } )
      .catch( err => {
        // dispatch(fetchScaleDataFail(err));
      } );
  };
};


export const saveEvent = (token, event) => {
  return dispatch => {
    const config = {
      headers: {
        'Authorization': '' + token
      }
    };
    // const data = {
    //   event: event
    // };
    console.log("saveEvent data: ", event);
    axios.post('/scale/event', event, config)
      .then(res => {
        // const fetchScales = [];
        // eslint-disable-next-line
        if (res.data == "ok") {
          //Scale name updated correctly!
          console.log("res.data == ok");
        } else {
          console.log("got saveEvent: ", res.data);
        }
        // dispatch(fetchScaleDataSuccess(res.data));
        // dispatch(actions.setTitle(name));
      })
      .catch(err => {
        // dispatch(fetchScaleDataFail(err));
      });
  }
  ;
};


