import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {  TableSortLabel, Grid } from '@mui/material';

const StandortTableHead = (props) => {
    const { order, orderBy, onRequestSort } = props;
    const classes = useStyles();

    const createSortHandler = (property) => (event) => {
        console.log("create sort Handler: " + event + " prop: " + property)
        onRequestSort(event, property);
    };

    const headCells = [

        {
            id: 'ort',
            sm: 3,
            numeric: false,
            disablePadding: false,
            label: "Ort"
        },
        {
            id: 'anzahl',
            sm: 1,
            numeric: false,
            disablePadding: false,
            label: "Anzahl"
        },
        {
            id: 'latlong',
            sm: 3,
            numeric: true,
            disablePadding: false,
            label: "Lat./Long."
        },
        {
            id: 'bemerkung',
            numeric: true,
            disablePadding: false,
            label: "Bemerkung"
        },
    ];

    return (
        <Grid
            spacing={1}
            container
            direction="row">
            <Grid
                spacing={0}
                container
                className={classes.tableHeader}
                direction="row"
                justify="center"
                alignItems="center">

                {headCells.map((headCell) => (
                    <Grid
                        item
                        xs={12}
                        sm={headCell.sm ? headCell.sm : true}
                        key={headCell.id} >

                        <TableSortLabel //ist eigentlich ein <span> element
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)} >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </Grid>
                ))}
                <Grid item xs={12} sm={1}>
                </Grid>
            </Grid>
        </Grid>
    );
}




const useStyles = makeStyles((theme) => ({

    tableHeader: {
        padding: theme.spacing(2, 4),
        fontWeight: 500
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default StandortTableHead;