import React from 'react';
import { Grid, Typography, IconButton, Collapse, Tooltip, Button, CircularProgress, Card, Divider, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from '../axios-modbee';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "../store/actions/hives";
import MapIcon from '@mui/icons-material/Map';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import EditSomething from '../Apiaries/div/EditSomething';

const Hivesbearbeiten = (props) => {
    const { t } = useTranslation();
    const hives = useSelector(state => state.hives.hives);
    const dispatch = useDispatch()
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const rename = (name) => {
        // const config = {
        //     daten: {
        //         "name": name,
        //     },
        //     crossdomain: true
        // };
        // axios.post('/scale/edit/' + row.id_scales, config)
        //     .then(res => {
        const tempA = [...hives]
        const index = tempA.findIndex(fff => fff.id_hive === props.hive.id_hive)
        tempA[index].name = name
        dispatch(actions.fetchHivesSuccess(tempA));
        //console.log("got edit scale answer: ", res);

        // })
        // .catch(err => {
        //     console.log("got edit scale error: ", err);
        // });

    }

    const renumber = (nummer) => {
        // const config = {
        //     daten: {
        //         "name": name,
        //     },
        //     crossdomain: true
        // };
        // axios.post('/scale/edit/' + row.id_scales, config)
        //     .then(res => {
        const tempA = [...hives]
        const index = tempA.findIndex(fff => fff.id_hive === props.hive.id_hive)
        tempA[index].nummer = nummer
        dispatch(actions.fetchHivesSuccess(tempA));
        //console.log("got edit scale answer: ", res);

        // })
        // .catch(err => {
        //     console.log("got edit scale error: ", err);
        // });

    }

    const maps = () => {
        enqueueSnackbar("beda do kosd maps aufmochn ;)", { variant: 'info' });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <EditSomething saveIt={rename} inhalt={props.hive.name} title={t('main.Name')} />
            </Grid>
            <Grid item xs={12}>
                <EditSomething saveIt={renumber} inhalt={props.hive.nummer} title={t('main.Bienenstocknummer')} />
            </Grid>
        </Grid>
    )

}
const useStyles = makeStyles((theme) => ({

    editable: {
        '&:hover': {
            backgroundColor: '#e0e0e0',
            cursor: 'pointer',
            "& .showedit": {
                display: "inline"
            }
        },
    },
    editIcon: {
        color: '#777',
        display: 'none'
    }
}));

export default Hivesbearbeiten;