import React from 'react';
import { Dialog, DialogActions, Slide, useMediaQuery, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const DialogUniversal = (props) => {
    const { t } = useTranslation();
    const fullWDialog = useMediaQuery('(max-width:400px)', { noSsr: true });

    return (
        <React.Fragment>
            <Dialog
                fullWidth={props.fullWidth}
                maxWidth={props.maxWidth}
                fullScreen={fullWDialog}
                open={props.open}
                aria-labelledby="form-dialog-title"
                onClose={props.close}
                TransitionComponent={Transition}>
                {props.children}
                {props.abbrechen || props.schliessen || props.speichern || props.sonder ?
                    <React.Fragment>
                        <Divider />
                        <DialogActions style={{ backgroundColor: '#f0f0f0' }}>
                            {props.abbrechen ? <Button variant='contained' onClick={props.abbrechen} color="primary" >
                                {t('main.abbrechen')}
                            </Button> : null}
                            {props.schliessen ? <Button variant='contained' onClick={props.schliessen} color="primary" >
                                {t('main.schließen')}
                            </Button> : null}
                            {props.speichern ? <Button variant='contained' onClick={props.speichern} color="primary" >
                                {t('main.speichern')}
                            </Button> : null}
                            {props.sonder ? <Button variant='contained' onClick={props.sonder} color="primary" >
                                {props.sonderTitel}
                            </Button> : null}
                        </DialogActions>
                    </React.Fragment> : null}

            </Dialog>
        </React.Fragment>
    );
}

export default DialogUniversal;