import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { deDE, enUS } from '@mui/material/locale';
import store from './store/store';

let myLocale = enUS
if (navigator.language === 'de') myLocale = deDE

const appBarStandard = "#807871";
const sideBarStandard = "#698c7e";
const primaryStandard = "#6f95c1"; //4a90e2"; //5faab9
const primaryTextStandard = "#fff"; //5faab9
const secondaryTextStandard = "#ccc"; //5faab9
const secondaryStandard = "#698c7e";
const highlightStandard = "#698c7e";  //5faab9
const weightStandard = "#0097A7"; //B71C1C"; //0097A7";
const temperatureStandard = "#1565C0";
const humidityStandard = "#6A1B9A";
const voltageStandard = "#698c7e";
const ertragStandard = "#7CB342";

let primary = localStorage.getItem('primary') != null ? localStorage.getItem('primary') : primaryStandard;
let secondary = localStorage.getItem('secondary') != null ? localStorage.getItem('secondary') : secondaryStandard;
let primaryText = localStorage.getItem('primaryText') != null ? localStorage.getItem('primaryText') : primaryTextStandard;
let secondaryText = localStorage.getItem('secondaryText') != null ? localStorage.getItem('secondaryText') : secondaryTextStandard;
let highlight = localStorage.getItem('highlight') != null ? localStorage.getItem('highlight') : highlightStandard;
let appBar = localStorage.getItem('appBar') != null ? localStorage.getItem('appBar') : appBarStandard;
let sideBar = localStorage.getItem('sideBar') != null ? localStorage.getItem('sideBar') : sideBarStandard;
let ertrag = localStorage.getItem('ertrag') != null ? localStorage.getItem('ertrag') : ertragStandard;
let weight = localStorage.getItem('weight') != null ? localStorage.getItem('weight') : weightStandard;
let temperature = localStorage.getItem('temperature') != null ? localStorage.getItem('temperature') : temperatureStandard;
let humidity = localStorage.getItem('humidity') != null ? localStorage.getItem('humidity') : humidityStandard;
let voltage = localStorage.getItem('voltage') != null ? localStorage.getItem('voltage') : voltageStandard;


const backColorTop = appBar + ' !important';//'#20B9BA !important';
let backColorSide = sideBar + ' !important';//'#20B9BA !important';


export let theme = createTheme({
    spacing: 4,
    typography: {
        useNextVariants: true,
        marginBottom: 0,
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    palette: {
        primary: {
            main: primary,
            text: primaryText,
        },
        secondary: {
            main: secondary,
            text: secondaryText,
        },
        colors: {
            highlight : highlight,
            appBar : appBar,
            sideBar : sideBar,
            ertrag : ertrag,
            weight : weight,
            temperature : temperature,
            voltage : voltage,
            humidity : humidity,
        }
    },
    shape: {
        borderRadius: 8,
    },
});

theme = responsiveFontSizes(theme);

theme = {
    ...theme,
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    //backgroundColor: backColorSide, //'#252a2e !important',
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                label: {
                    textTransform: 'initial',
                },
            }
            // contained: {
            //   boxShadow: 'none',
            //   '&:active': {
            //     boxShadow: 'none',
            //   },
            // },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginLeft: theme.spacing(1),
                },
                indicator: {
                    height: 3,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    backgroundColor: theme.palette.common.white,
                },
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'initial',
                    margin: '0 16px',
                    minWidth: 0,
                    [theme.breakpoints.up('md')]: {
                        minWidth: 0,
                    },
                },
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(2),
                },
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 4,
                },
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    //backgroundColor: '#252a2e',
                },
            }
        },
        // MuiAppBar: {
        //   styleOverrides: {
        //     root: {
        //       backgroundColor: backColorTop,
        //       color: '#fff !important',
        //     },
        //   }
        // },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontWeight: theme.typography.fontWeightMedium,
                    //color: '#fff'
                },
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    marginRight: 0,
                    '& svg': {
                        fontSize: 20,
                    },
                },
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 32,
                },
            }
        },
    },
    props: {
        MuiTab: {
            disableRipple: false,
        },
    },
    mixins: {
        ...theme.mixins,
        toolbar: {
            minHeight: 48,
        },
    },
};