import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";


const initialState = {
    cartItems: []
};

const setCart = (state, action) => {
    return updateObject(state, {
        cartItems: action.cartItems,
    })
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CART: return setCart(state, action);
        default: return state;
    }
};

export default reducer;