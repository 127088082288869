import * as actionTypes from './actionTypes';
import axios from '../../axios-modbee';
import {authRefreshToken} from "./auth";

export const fetchHives = (token) => {
  return dispatch => {

    const config = {
      headers: {'Authorization': ''+token}
    };
    axios.get( '/hives', config)
        .then( res => {
            console.log("fetchHives: ", res.data);
            dispatch(fetchHivesSuccess(res.data));
        } )
        .catch( err => {
          //TODO - falls auth error - automatisch neuen token anfordern!!
          console.error("fetch - error token: ", token);
          console.error("error fetchHives: "+err.toLocaleString()+" und ", err)
          dispatch(fetchHivesFail(err));
          dispatch(authRefreshToken());
        });
  };
};

export const fetchHivesSuccess = ( hives ) => {
  return {
      type: actionTypes.HIVES_SUCCESS,
      hives: hives
  };
};

export const fetchHivesFail = ( error ) => {
  return {
      type: actionTypes.HIVES_FAIL,
      error: error
  };
};